const Horchata: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M258.459 34.64L264.1 21.64L259.589 19.64L260.729 17L272.781 22.24L271.631 24.87L267.12 22.87L261.479 35.87L258.459 34.64Z" />
      <path d="M278.913 44.9L279.843 40.15L274.512 37.15L270.952 40.43L267.951 38.74L281.353 26.74L285.144 28.86L281.973 46.57L278.913 44.9ZM276.703 35.15L280.463 37.21L281.783 30.5L276.703 35.15Z" />
      <path d="M296.636 56.9499L307.547 43.8799L316.219 51.1199L314.378 53.3199L308.228 48.1999L305.797 51.1099L311.398 55.7799L309.558 57.9899L303.957 53.3199L301.006 56.8499L307.187 62.0099L305.347 64.2199L296.636 56.9499Z" />
      <path d="M328.04 62.6799L330.34 65.0299L318.178 76.9299L315.888 74.5999L319.168 61.2199L310.807 69.4299L308.467 67.0599L320.669 55.1499L323.019 57.5499L319.689 70.8499L328.04 62.6799Z" />
      <path d="M330.2 91.9101L344.472 82.5701L346.283 85.3201L340.782 88.9301L344.472 94.6001L349.973 90.9901L351.784 93.7401L337.551 103.08L335.741 100.33L342.072 96.1801L338.351 90.5001L332.02 94.6601L330.2 91.9101Z" />
      <path d="M347.254 102.59C351.884 100.23 356.555 101.49 358.715 105.71C360.876 109.93 359.075 114.33 354.445 116.71C349.814 119.09 345.003 117.93 342.893 113.79C340.783 109.65 342.523 105 347.254 102.59ZM353.034 113.66C356.205 112.05 357.495 109.3 356.295 106.94C355.095 104.58 352.064 103.94 348.884 105.53C345.483 107.26 344.063 110.1 345.303 112.53C346.543 114.96 349.634 115.43 353.034 113.66V113.66Z" />
      <path d="M348.344 124.36L364.246 118.27L366.637 124.52C367.867 127.74 366.737 130.32 363.796 131.44C362.981 131.824 362.069 131.955 361.178 131.818C360.287 131.681 359.458 131.281 358.795 130.67C358.435 134.09 354.025 134.67 353.105 136.45H352.994L351.824 133.45C353.235 130.89 358.195 131.16 356.605 127.02L355.835 125.02L349.524 127.43L348.344 124.36ZM358.525 123.98L359.716 127.09C359.805 127.375 359.954 127.638 360.155 127.86C360.355 128.081 360.601 128.257 360.875 128.375C361.15 128.493 361.447 128.55 361.746 128.542C362.044 128.534 362.338 128.462 362.606 128.33C362.897 128.242 363.165 128.091 363.393 127.89C363.62 127.688 363.802 127.44 363.924 127.161C364.047 126.883 364.108 126.582 364.103 126.278C364.098 125.974 364.028 125.674 363.896 125.4L362.736 122.4L358.525 123.98Z" />
      <path d="M362.286 137.19C367.286 135.84 371.607 138.01 372.837 142.57C373.334 144.204 373.199 145.966 372.458 147.505C371.717 149.044 370.424 150.249 368.837 150.88L367.967 147.66C368.826 147.336 369.531 146.7 369.941 145.878C370.351 145.057 370.435 144.111 370.177 143.23C369.507 140.62 366.716 139.47 363.246 140.4C359.565 141.4 357.575 143.83 358.245 146.4C358.506 147.344 359.113 148.155 359.945 148.672C360.778 149.189 361.774 149.374 362.736 149.19L363.606 152.41C361.874 152.7 360.096 152.328 358.625 151.369C357.154 150.409 356.097 148.932 355.665 147.23C354.474 142.65 357.155 138.57 362.286 137.19Z" />
      <path d="M358.665 157.86L375.478 155.22L375.988 158.47L369.487 159.47L370.537 166.18L377.038 165.18L377.548 168.43L360.725 171.06L360.225 167.81L367.696 166.64L366.646 159.94L359.165 161.11L358.665 157.86Z" />
      <path d="M361.756 186.83L366.277 185.12L365.976 179.03L361.306 177.74L361.136 174.27L378.428 179.27L378.638 183.61L361.926 190.28L361.756 186.83ZM368.877 179.83L369.077 184.08L375.478 181.65L368.877 179.83Z" />
      <path d="M361.606 197.34L375.728 198.06L375.978 193.14L378.848 193.29L378.178 206.43L375.308 206.29L375.558 201.37L361.476 200.63L361.606 197.34Z" />
      <path d="M359.175 220.09L363.946 219.3L364.856 213.3L360.536 211.11L361.056 207.68L377.008 216.02L376.358 220.32L358.655 223.55L359.175 220.09ZM367.537 214.6L366.907 218.81L373.658 217.69L367.537 214.6Z" />
      <path d="M365.767 258.82L364.136 263.24L348.134 257.34L349.274 254.26L360.716 258.48L350.844 250.01L351.514 248.22H364.516L353.045 243.98L354.185 240.89L370.187 246.79L368.557 251.21L356.645 251.13L365.767 258.82Z" />
      <path d="M346.223 261.43L361.476 269.01L356.425 279.11L353.855 277.82L357.435 270.66L354.035 268.97L350.774 275.49L348.204 274.2L351.464 267.68L347.354 265.62L343.753 272.82L341.183 271.54L346.223 261.43Z" />
      <path d="M339.082 275.29L353.475 284.43L350.474 289.15C347.784 293.41 343.173 294.42 338.832 291.69C334.492 288.96 333.382 284.33 336.072 280.07L339.082 275.29ZM339.763 279.61L338.532 281.56C336.922 284.1 337.792 286.99 340.673 288.81C343.553 290.63 346.464 290.12 348.074 287.57L349.294 285.62L339.763 279.61Z" />
      <path d="M314.928 314.43C312.708 312.35 312.848 309.61 315.308 307L320.119 301.86L332.541 313.43L328.01 318.26C325.84 320.57 323.469 320.91 321.609 319.16C321.016 318.701 320.577 318.073 320.35 317.358C320.123 316.643 320.119 315.877 320.339 315.16C319.494 315.689 318.49 315.906 317.502 315.772C316.513 315.639 315.603 315.164 314.928 314.43V314.43ZM317.669 308.68C316.488 309.94 316.448 311.29 317.589 312.36C318.729 313.43 320.159 313.25 321.399 311.93L323.579 309.61L319.969 306.23L317.669 308.68ZM328.19 313.94L325.37 311.29L323.309 313.49C323.086 313.671 322.906 313.899 322.782 314.159C322.658 314.418 322.594 314.702 322.594 314.99C322.594 315.277 322.658 315.561 322.782 315.821C322.906 316.08 323.086 316.309 323.309 316.49C324.15 317.28 325.19 317.15 326.17 316.11L328.19 313.94Z" />
      <path d="M308.517 313.8L319.809 326.54L317.348 328.72L307.967 318.13L302.336 323.13L300.466 320.97L308.517 313.8Z" />
      <path d="M288.464 329.97L292.385 332.81L297.306 329.23L295.836 324.61L298.636 322.61L303.907 339.82L300.396 342.38L285.664 332.05L288.464 329.97ZM298.246 332.07L294.805 334.57L300.336 338.57L298.246 332.07Z" />
      <path d="M282.183 353.75L279.333 355.43L270.852 340.67L273.682 339.05L285.814 345.57L279.983 335.43L282.833 333.8L291.315 348.55L288.404 350.22L276.402 343.67L282.183 353.75Z" />
      <path d="M257.59 346.72L265.181 351.66L265.991 348.94L263.801 343.94L266.801 342.61L273.692 358.17L270.692 359.5L267.831 353.04L265.291 361.89L261.69 363.48L264.241 354.86L253.829 348.43L257.59 346.72Z" />
      <path d="M132.25 348.72L126.909 361.82L131.47 363.67L130.38 366.33L118.218 361.43L119.308 358.76L123.859 360.62L129.199 347.53L132.25 348.72Z" />
      <path d="M111.436 339.18L110.686 343.96L116.117 346.72L119.558 343.31L122.648 344.89L109.646 357.4L105.775 355.4L108.296 337.58L111.436 339.18ZM113.997 348.85L110.206 346.92L109.136 353.68L113.997 348.85Z" />
      <path d="M93.2639 327.83L82.8523 341.29L73.9209 334.43L75.6712 332.15L82.0122 337.05L84.3325 334.05L78.5616 329.6L80.3219 327.32L86.0928 331.78L88.9032 328.14L82.5322 323.22L84.2925 320.94L93.2639 327.83Z" />
      <path d="M61.6392 323.27L59.2588 321L70.9606 308.65L73.331 310.89L70.5605 324.43L78.6118 315.93L81.0022 318.19L69.2904 330.51L66.85 328.2L69.6804 314.78L61.6392 323.27Z" />
      <path d="M58.4285 294.15L44.5564 304.02L42.6261 301.33L47.9869 297.52L44.0563 291.99L38.6955 295.8L36.7852 293.12L50.6673 283.26L52.5676 285.94L46.4267 290.32L50.3673 295.85L56.4982 291.43L58.4285 294.15Z" />
      <path d="M40.945 284.1C36.4243 286.63 31.6936 285.54 29.4232 281.43C27.1529 277.32 28.7331 272.83 33.2839 270.3C37.8346 267.77 42.6753 268.73 44.9357 272.78C47.196 276.83 45.5958 281.51 40.945 284.1ZM34.7641 273.24C31.6536 274.98 30.4634 277.77 31.7636 280.08C33.0638 282.39 36.1043 282.96 39.2248 281.22C42.5553 279.36 43.8755 276.47 42.5353 274.08C41.1951 271.69 38.1046 271.43 34.7641 273.24Z" />
      <path d="M39.065 262.43L23.4226 269.09L20.8022 262.94C19.442 259.76 20.4821 257.15 23.3726 255.94C24.1711 255.535 25.071 255.374 25.9603 255.478C26.8495 255.581 27.6888 255.943 28.3734 256.52C28.6034 253.1 32.9941 252.35 33.8442 250.52L33.9442 250.47L35.2244 253.47C33.9142 256.08 28.9535 255.99 30.7037 260.07L31.5339 262.07L37.7748 259.43L39.065 262.43ZM28.9135 263.19L27.6033 260.12C27.5037 259.839 27.3442 259.583 27.1359 259.369C26.9276 259.156 26.6754 258.99 26.3967 258.883C26.1181 258.777 25.8196 258.732 25.5219 258.752C25.2243 258.772 24.9346 258.857 24.6728 259C24.3821 259.098 24.1163 259.259 23.8941 259.471C23.6719 259.683 23.4985 259.94 23.3862 260.226C23.2738 260.512 23.2251 260.818 23.2435 261.125C23.2619 261.431 23.3469 261.73 23.4926 262L24.7628 265L28.9135 263.19Z" />
      <path d="M24.6525 250.08C19.6517 251.62 15.3111 249.6 13.9208 245.08C13.3605 243.468 13.4269 241.704 14.1069 240.139C14.7869 238.573 16.0307 237.321 17.5914 236.63L18.5916 239.81C17.7558 240.177 17.0872 240.843 16.7172 241.677C16.3471 242.512 16.3022 243.454 16.5913 244.32C17.3614 246.9 20.1918 247.95 23.5924 246.89C27.2429 245.76 29.1332 243.25 28.3631 240.75C28.0685 239.815 27.4326 239.025 26.5819 238.538C25.7312 238.05 24.7281 237.901 23.7724 238.12L22.7722 234.94C24.4914 234.585 26.2814 234.888 27.7875 235.79C29.2935 236.692 30.4057 238.127 30.9035 239.81C32.2637 244.34 29.7233 248.51 24.6525 250.08Z" />
      <path d="M27.4935 229.29L10.7909 232.55L10.1608 229.32L16.6118 228.06L15.3116 221.43L8.86057 222.69L8.23047 219.43L24.9331 216.17L25.5632 219.4L18.142 220.85L19.4422 227.51L26.8634 226.06L27.4935 229.29Z" />
      <path d="M23.3229 200.43L18.8722 202.31L19.4223 208.43L24.1331 209.55L24.4331 213.01L6.95037 208.59L6.57031 204.26L23.0229 196.97L23.3229 200.43ZM16.4819 207.71L16.1118 203.48L9.81082 206.15L16.4819 207.71Z" />
      <path d="M23.0827 189.94L8.93046 189.76L8.87045 194.68H6L6.16002 181.55H9.04048L8.98047 186.43L23.1327 186.61L23.0827 189.94Z" />
      <path d="M24.663 167.12L19.9223 168.12L19.2421 174.17L23.6428 176.17L23.2528 179.62L6.99023 171.88L7.48031 167.56L25.0531 163.67L24.663 167.12ZM16.5117 172.91L16.9818 168.68L10.2807 170.06L16.5117 172.91Z" />
      <path d="M16.642 128.65L18.1122 124.18L34.2747 129.49L33.2746 132.61L21.6928 128.81L31.8544 136.92L31.2643 138.73L18.2622 139.21L29.8241 143.03L28.8239 146.16L12.6514 140.85L14.1216 136.37L26.0235 136.02L16.642 128.65Z" />
      <path d="M36.0647 125.43L20.5723 118.43L25.243 108.15L27.8534 109.34L24.5429 116.63L28.0034 118.2L31.0039 111.56L33.6243 112.75L30.6238 119.38L34.8045 121.29L38.135 113.96L40.7554 115.15L36.0647 125.43Z" />
      <path d="M42.6862 111.21L27.9639 102.68L30.7643 97.8499C33.2847 93.4899 37.8554 92.3099 42.2861 94.8499C46.7168 97.3899 48.007 101.99 45.4866 106.35L42.6862 111.21ZM41.846 106.93L42.9962 104.93C44.5064 102.32 43.5263 99.4699 40.5858 97.7699C37.6454 96.0699 34.7649 96.6699 33.2447 99.2799L32.0945 101.28L41.846 106.93Z" />
      <path d="M65.3489 71.2599C67.6392 73.2599 67.6092 75.9899 65.2489 78.6999L60.6281 84.0099L47.7861 72.8399L52.1268 67.8399C54.2171 65.4499 56.5675 65.0199 58.4978 66.6999C59.1135 67.1426 59.5788 67.7634 59.831 68.4786C60.0831 69.1937 60.11 69.969 59.908 70.6999C60.7365 70.14 61.7355 69.8894 62.7302 69.9917C63.7249 70.0941 64.6519 70.543 65.3489 71.2599V71.2599ZM52.1168 72.2599L55.0273 74.7899L57.0276 72.5199C57.2452 72.3305 57.4174 72.0944 57.5313 71.8293C57.6451 71.5642 57.6978 71.2768 57.6853 70.9886C57.6728 70.7003 57.5955 70.4186 57.4591 70.1643C57.3227 69.9101 57.1308 69.6898 56.8976 69.5199C56.0374 68.7699 54.9973 68.9399 54.0671 70.0199L52.1168 72.2599ZM62.8285 77.1099C63.9587 75.8099 63.9387 74.4499 62.7585 73.4299C61.5783 72.4099 60.1581 72.6299 58.9679 73.9999L56.8876 76.4299L60.6181 79.6699L62.8285 77.1099Z" />
      <path d="M71.7804 71.59L60.0186 59.28L62.4289 57L72.1705 67.23L77.6113 62.04L79.6116 64.12L71.7804 71.59Z" />
      <path d="M91.2631 54.67L87.2625 51.98L82.4318 55.74L84.082 60.3L81.3716 62.43L75.4307 45.43L78.8412 42.75L94.0036 52.53L91.2631 54.67ZM81.4316 52.93L84.7821 50.3L79.0912 46.49L81.4316 52.93Z" />
      <path d="M96.6145 30.6799L99.435 28.9399L108.436 43.3699L105.666 45.0999L93.294 39.0299L99.495 48.9499L96.7045 50.6999L87.7031 36.2699L90.5536 34.4899L102.825 40.5899L96.6145 30.6799Z" />
      <path d="M121.438 36.7799L113.666 32.1299L112.956 34.8799L115.337 39.7599L112.336 41.1999L104.875 25.8999L107.875 24.4599L110.966 30.7999L113.186 21.8699L116.727 20.1399L114.496 28.8599L125.138 34.9799L121.438 36.7799Z" />
    </svg>
  );
};

export default Horchata;
