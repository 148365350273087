import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Image as ValImageData } from '@valbuild/next';
import Image from 'next/image';

const getBase64FromUrl: (url: string) => Promise<string> = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};

const corruptImage = (imageData, maxErrors = 50, minErrors = 1) => {
  const [format, base64data] = imageData.split(',');
  const corrupted = Buffer.from(base64data, 'base64');

  const errors =
    minErrors + Math.round(Math.random() * (maxErrors - minErrors));

  // Swap random bytes in the image to create glitches
  for (let i = 0; i < errors; i++) {
    let l = 500 + Math.round(Math.random() * (corrupted.length - 503));
    if (
      corrupted[l] === 255 ||
      corrupted[l] === 0 ||
      corrupted[l + 1] === 255 ||
      corrupted[l + 1] === 0
    ) {
      // Skip if any of the bytes to swap are 0 or 255 to prevent corrupting the image.
      continue;
    }

    [corrupted[l], corrupted[l + 1]] = [corrupted[l + 1], corrupted[l]];
  }

  return format + ',' + corrupted.toString('base64');
};

interface GlitchBlankImageProps {
  alt: string;
  className?: string;
  height?: number;
  priority?: boolean;
  removeEffect?: boolean;
  src: ValImageData;
  width: number;
}

const GlitchBlankImage = ({
  alt,
  className,
  height,
  priority = false,
  src,
  width,
  removeEffect = false,
}: GlitchBlankImageProps) => {
  const shouldCover = height !== undefined;
  const imageDimensions = src.metadata;

  const [original, setOriginal] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const animationFrame = useRef<number>();

  useEffect(() => {
    getBase64FromUrl(src.url).then((base64) => {
      const filetypeMatch = base64.match(/image\/([a-z]+)/);
      filetypeMatch && setFileType(filetypeMatch[1]);
      setOriginal(base64);
      setCurrentImage(base64);
    });
  }, [src]);

  useEffect(
    () => () => {
      cancelAnimationFrame(animationFrame.current);
    },
    []
  );

  const startGlitch = () => {
    if (fileType !== 'jpeg') {
      return;
    }
    setCurrentImage(corruptImage(original, 10, 10));
    doGlitchFrame();
  };

  const stopGlitch = () => {
    cancelAnimationFrame(animationFrame.current);
    setCurrentImage(original);
  };

  const doGlitchFrame = () => {
    animationFrame.current = requestAnimationFrame(() => {
      const selector = Math.random();

      if (selector < 0.025) {
        setCurrentImage(corruptImage(original, 50, 20));
      } else if (selector < 0.1) {
        setCurrentImage(original);
      }
      doGlitchFrame();
    });
  };

  const getImageProps = useCallback(() => {
    let ratio = width / imageDimensions.width;
    var smartHeight = imageDimensions.height * ratio;

    var newWidth = width;
    if (smartHeight > width) {
      newWidth = width / 1.5;
      smartHeight = smartHeight / 1.5;
    }

    return { width: newWidth, height: smartHeight };
  }, [imageDimensions, width]);

  const effectClass = classNames({
    'opacity-[0.85] mix-blend-multiply': removeEffect === false,
  });

  return (
    <div
      className={`relative ${
        !removeEffect && `bg-[#FFFCB6]`
      } leading-[0] ${className}`}
      onMouseEnter={startGlitch}
      onMouseLeave={stopGlitch}
    >
      {currentImage && (
        <Image
          src={currentImage}
          alt={alt}
          width={getImageProps().width}
          height={shouldCover ? height : getImageProps().height}
          objectFit={shouldCover ? 'cover' : 'contain'}
          className={`${effectClass}`}
          priority={priority}
        />
      )}
    </div>
  );
};

export default GlitchBlankImage;
