interface SvgIllustrationProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const SvgIllustration: React.FC<SvgIllustrationProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`svg-path:transition-50ms ${className}`}>{children}</div>
  );
};

export default SvgIllustration;
