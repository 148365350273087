const SnakkMedKnutMagnus: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <div className={className}>
      <svg
        viewBox="0 0 313 313"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6122_5365)">
          <g clipPath="url(#clip1_6122_5365)">
            <path d="M157.792 8.56504L160.212 8.6475C160.202 10.0492 161.159 10.8429 162.508 10.8841C163.682 10.9253 164.392 10.2863 164.423 9.36898C164.474 7.99816 163.229 7.64773 161.859 7.20453C160.16 6.64796 158.245 5.92647 158.338 3.18484C158.389 1.14407 160.119 -0.0824503 162.662 4.86918e-06C165.113 0.0824601 166.709 1.58727 166.698 3.82387L164.361 3.74141C164.361 2.5355 163.62 1.88617 162.549 1.84494C161.468 1.80371 160.747 2.36029 160.737 3.23637C160.716 4.42167 161.602 4.83394 162.714 5.20499C164.505 5.8131 166.915 6.30783 166.812 9.2453C166.74 11.451 165.02 12.8321 162.487 12.7393C159.553 12.6363 157.7 10.9872 157.782 8.56504H157.792Z" />
            <path d="M184.583 2.56621L186.91 2.93725L184.985 14.986L182.678 14.615L178.869 5.50367L177.54 13.7904L175.213 13.4194L177.139 1.37061L179.517 1.75196L183.265 10.8323L184.593 2.56621H184.583Z" />
            <path d="M201.458 19.305L201.376 15.8316L197.196 14.5845L195.209 17.4292L192.82 16.718L200.336 6.22559L203.322 7.11198L203.847 20.0059L201.458 19.2947V19.305ZM198.411 12.8117L201.335 13.6878L201.211 8.7817L198.411 12.8117Z" />
            <path d="M217.943 25.798L216.913 19.3871L215.08 20.2632L213.505 23.8191L211.353 22.8606L216.306 11.7085L218.458 12.667L216.398 17.2948L222.319 14.3883L224.893 15.5324L219.086 18.3255L220.63 26.9833L217.932 25.7877L217.943 25.798Z" />
            <path d="M233.892 34.5075L233.675 28.0141L231.75 28.6532L229.732 31.9823L227.714 30.7661L234.025 20.3252L236.044 21.5414L233.428 25.8703L239.668 23.7368L242.077 25.2004L235.971 27.2411L236.414 36.0329L233.892 34.5075Z" />
            <path d="M274.634 53.4617L276.909 55.956L267.9 64.1706L266.314 62.4287L272.77 56.5435L264.132 60.0272L263.215 59.0172L267.509 50.7098L261.032 56.6156L259.447 54.8738L268.456 46.6592L270.732 49.1534L266.757 56.7084L274.654 53.4617H274.634Z" />
            <path d="M273.316 71.2515L283.334 64.3047L287.937 70.9629L286.248 72.1379L282.984 67.4174L280.75 68.9634L283.725 73.2614L282.037 74.4364L279.061 70.1384L276.353 72.0143L279.638 76.7554L277.949 77.9304L273.326 71.2515H273.316Z" />
            <path d="M282.541 85.4339L293.342 79.7651L295.206 83.3107C296.884 86.5059 295.854 89.7319 292.601 91.4429C289.327 93.1538 286.083 92.185 284.405 88.9795L282.541 85.4236V85.4339ZM285.455 86.5677L286.217 88.0313C287.216 89.9484 289.327 90.4431 291.478 89.3093C293.61 88.1962 294.361 86.1966 293.363 84.2796L292.601 82.816L285.445 86.5677H285.455Z" />
            <path d="M298.737 129.96L303.35 125.394L301.569 124.414L297.759 125.198L297.286 122.889L309.229 120.436L309.703 122.745L304.75 123.765L310.557 126.898L311.124 129.661L305.481 126.569L299.345 132.866L298.748 129.97L298.737 129.96Z" />
            <path d="M313.111 147.45L313.286 149.8L301.126 150.697L300.951 148.357L308.931 142.544L300.57 143.163L300.395 140.813L312.555 139.916L312.73 142.318L304.761 148.069L313.111 147.45Z" />
            <path d="M306.645 159.849L313.368 160.2L313.245 162.54L306.532 162.189C304.03 162.055 302.753 162.787 302.67 164.364C302.588 165.931 303.782 166.786 306.284 166.92L312.998 167.27L312.874 169.631L306.151 169.28C302.403 169.084 300.549 167.332 300.735 164.271C300.93 161.21 302.969 159.664 306.645 159.86V159.849Z" />
            <path d="M299.201 180.453L309.178 182.205L309.785 178.732L311.813 179.093L310.187 188.369L308.158 188.008L308.766 184.535L298.789 182.783L299.201 180.463V180.453Z" />
            <path d="M294.763 217.104C298.109 218.733 299.407 221.949 297.924 225C296.472 227.999 293.157 228.937 289.81 227.308C286.392 225.639 285.033 222.433 286.485 219.444C287.967 216.393 291.334 215.445 294.763 217.104ZM290.933 225.195C293.229 226.309 295.35 225.855 296.184 224.154C297.038 222.392 296.091 220.423 293.795 219.31C291.334 218.115 289.1 218.527 288.245 220.3C287.422 222 288.482 224.01 290.943 225.206L290.933 225.195Z" />
            <path d="M284.961 246.521L283.066 249.314L272.976 242.47L274.294 240.522L281.522 245.428L276.116 237.832L276.878 236.698L285.949 238.924L278.691 234.008L280.008 232.06L290.099 238.904L288.204 241.697L279.936 239.594L284.94 246.51L284.961 246.521Z" />
            <path d="M252.178 265.001L259.91 274.432L256.821 276.978C254.031 279.266 250.674 278.915 248.348 276.071C246.01 273.216 246.299 269.835 249.089 267.547L252.178 265.001ZM251.663 268.093L250.386 269.134C248.718 270.505 248.656 272.659 250.201 274.545C251.725 276.4 253.835 276.741 255.503 275.37L256.78 274.329L251.663 268.083V268.093Z" />
            <path d="M237.382 276.318L244.106 286.501L242.139 287.8L235.415 277.617L237.382 276.318Z" />
            <path d="M225.356 283.584L230.092 292.551L233.212 290.902L234.17 292.726L225.85 297.127L224.893 295.303L228.012 293.654L223.276 284.687L225.356 283.584Z" />
            <path d="M210.241 290.634L213.907 300.096L217.191 298.818L217.932 300.745L209.16 304.146L208.419 302.219L211.703 300.941L208.038 291.479L210.231 290.624L210.241 290.634Z" />
            <path d="M175.687 299.622L176.892 311.763L172.125 312.237C169.664 312.485 168.099 311.217 167.883 308.97C167.656 306.723 168.943 305.167 171.404 304.93L173.834 304.682L173.35 299.859L175.697 299.622H175.687ZM174.029 306.733L171.651 306.97C170.683 307.063 170.158 307.743 170.251 308.733C170.343 309.722 171.033 310.279 172.032 310.176L174.338 309.949L174.019 306.733H174.029Z" />
            <path d="M158.564 300.807L158.317 313L153.53 312.907C151.058 312.856 149.658 311.413 149.699 309.156C149.73 307.651 150.389 306.579 151.553 306.043C149.39 304.878 150.183 301.797 149.257 300.704V300.622L151.594 300.673C152.912 302.302 151.398 305.517 154.58 305.579L156.114 305.61L156.207 300.766L158.564 300.817V300.807ZM156.073 307.671L153.684 307.62C152.716 307.599 152.109 308.218 152.088 309.207C152.068 310.197 152.685 310.836 153.684 310.856L156.001 310.898L156.062 307.671H156.073Z" />
            <path d="M140.453 306.105C139.908 309.795 137.231 311.99 133.874 311.495C130.579 311.001 128.695 308.125 129.241 304.435C129.797 300.663 132.453 298.416 135.738 298.9C139.094 299.395 141.009 302.332 140.453 306.105ZM131.599 304.868C131.228 307.393 132.288 309.29 134.162 309.568C136.098 309.857 137.684 308.362 138.065 305.827C138.466 303.116 137.395 301.106 135.46 300.817C133.586 300.539 132 302.157 131.599 304.858V304.868Z" />
            <path d="M121.127 300.147L118.8 299.488C119.15 298.127 118.409 297.128 117.112 296.756C115.989 296.437 115.135 296.88 114.888 297.777C114.517 299.096 115.629 299.735 116.854 300.498C118.368 301.446 120.057 302.611 119.315 305.239C118.769 307.208 116.803 307.991 114.352 307.29C111.995 306.62 110.8 304.775 111.346 302.611L113.601 303.25C113.323 304.425 113.879 305.229 114.908 305.528C115.959 305.827 116.782 305.455 116.999 304.61C117.307 303.466 116.535 302.848 115.547 302.229C113.951 301.209 111.737 300.158 112.53 297.334C113.127 295.21 115.125 294.283 117.565 294.973C120.386 295.777 121.797 297.818 121.138 300.147H121.127Z" />
            <path d="M104.53 295.283L102.368 294.386C102.954 292.964 102.81 292.015 101.863 291.624C100.885 291.222 100.082 291.85 99.4538 293.376L96.4267 300.704L94.2645 299.807L97.3224 292.386C98.3727 289.841 100.288 288.872 102.604 289.82C104.89 290.768 105.559 292.768 104.53 295.272V295.283Z" />
            <path d="M90.8873 284.697L84.9773 295.365L77.9037 291.438L78.9025 289.634L83.9167 292.417L85.2347 290.036L80.6631 287.501L81.6619 285.697L86.2334 288.232L87.8293 285.346L82.7842 282.553L83.7829 280.75L90.8873 284.687V284.697Z" />
            <path d="M70.5419 271.824L70.2125 278.317L72.1893 277.833L74.4751 274.679L76.3799 276.06L69.2137 285.944L67.3089 284.563L70.2845 280.461L63.8906 282.069L61.6048 280.41L67.8649 278.884L68.1532 270.082L70.5419 271.814V271.824Z" />
            <path d="M60.0501 263.084L53.1619 270.525L55.7463 272.927L54.346 274.442L47.4475 268.042L48.8478 266.526L51.4321 268.928L58.3203 261.486L60.0501 263.094V263.084Z" />
            <path d="M46.5105 256.374L40.549 261.929L38.9943 260.177L45.1823 254.89L46.5105 256.384V256.374ZM50.2378 253.23L48.1785 255.065L46.665 253.364L48.7242 251.53L50.2378 253.23Z" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_6122_5365">
            <rect width="313" height="313" fill="white" />
          </clipPath>
          <clipPath id="clip1_6122_5365">
            <rect
              width="274.383"
              height="313"
              fill="white"
              transform="translate(39)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SnakkMedKnutMagnus;
