const BubbleTea: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M258.42 37.63L264.061 24.63L259.54 22.63L260.69 20L272.692 25.23L271.542 27.86L267.031 25.86L261.4 38.86L258.42 37.63Z" />
      <path d="M278.852 47.88L279.772 43.14L274.452 40.14L270.891 43.42L267.891 41.74L281.293 29.74L285.093 31.85L281.923 49.56L278.852 47.88ZM276.642 38.13L280.363 40.2L281.673 33.48L276.642 38.13Z" />
      <path d="M296.575 59.9301L307.477 46.8601L316.158 54.1001L314.308 56.3101L308.167 51.1801L305.737 54.0901L311.327 58.7801L309.487 60.9901L303.886 56.3201L300.946 59.8501L307.127 65.0101L305.277 67.2201L296.575 59.9301Z" />
      <path d="M327.971 65.6599L330.271 68.0099L318.109 79.9099L315.829 77.5799L319.109 64.1999L310.738 72.3899L308.428 70.0399L320.58 58.1399L322.93 60.5299L319.599 73.8299L327.971 65.6599Z" />
      <path d="M341.143 102.87C338.612 104.54 335.982 103.77 334.002 100.78L330.121 94.91L344.313 85.52L347.974 91.05C349.724 93.69 349.504 96.05 347.374 97.48C346.786 97.9532 346.068 98.2356 345.315 98.2892C344.563 98.3428 343.812 98.1652 343.163 97.78C343.474 98.7308 343.443 99.7605 343.074 100.69C342.705 101.62 342.021 102.391 341.143 102.87V102.87ZM336.142 98.87C337.092 100.31 338.402 100.66 339.703 99.79C341.003 98.92 341.173 97.5 340.163 95.99L338.432 93.34L334.312 96.07L336.142 98.87ZM343.693 89.87L340.473 92L342.133 94.51C342.258 94.7666 342.438 94.9921 342.661 95.1702C342.884 95.3483 343.144 95.4745 343.421 95.5396C343.699 95.6047 343.988 95.6071 344.267 95.5467C344.546 95.4862 344.808 95.3644 345.033 95.19C346.034 94.55 346.104 93.51 345.313 92.33L343.693 89.87Z" />
      <path d="M346.583 104.5C351.193 102.11 355.874 103.33 358.055 107.5C360.235 111.67 358.475 116.11 353.864 118.5C349.253 120.89 344.432 119.79 342.292 115.66C340.152 111.53 341.862 106.95 346.583 104.5ZM352.434 115.5C355.594 113.86 356.864 111.11 355.644 108.76C354.424 106.41 351.393 105.76 348.223 107.4C344.842 109.15 343.442 112 344.702 114.4C345.963 116.8 349.043 117.29 352.434 115.53V115.5Z" />
      <path d="M356.915 136.31C354.085 137.42 351.674 136.11 350.374 132.77L347.804 126.22L363.656 120.02L366.077 126.19C367.227 129.19 366.517 131.42 364.136 132.35C363.462 132.688 362.701 132.813 361.954 132.707C361.207 132.602 360.51 132.272 359.956 131.76C360.062 132.754 359.816 133.754 359.26 134.586C358.705 135.417 357.875 136.028 356.915 136.31V136.31ZM352.915 131.31C353.545 132.92 354.745 133.53 356.205 132.96C357.665 132.39 358.115 131.03 357.455 129.34L356.295 126.34L351.694 128.14L352.915 131.31ZM362.186 124.08L358.585 125.48L359.686 128.29C359.754 128.568 359.883 128.827 360.065 129.048C360.246 129.269 360.474 129.447 360.733 129.569C360.992 129.69 361.275 129.753 361.561 129.751C361.847 129.75 362.129 129.684 362.386 129.56C363.456 129.14 363.776 128.15 363.256 126.82L362.186 124.08Z" />
      <path d="M353.654 141.88L370.007 137.16L370.917 140.32L357.325 144.24L359.415 151.47L356.645 152.26L353.654 141.88Z" />
      <path d="M357.505 155.88L374.207 152.63L376.368 163.71L373.547 164.26L372.017 156.4L368.297 157.13L369.687 164.28L366.866 164.83L365.436 157.68L360.925 158.56L362.466 166.45L359.645 167L357.505 155.88Z" />
      <path d="M365.906 170.28L368.626 169.97L369.476 177.54L366.746 177.84L365.906 170.28Z" />
      <path d="M361.525 186.4L375.668 185.94L375.508 181.03L378.378 180.93L378.798 194.06L375.928 194.15L375.768 189.23L361.635 189.69L361.525 186.4Z" />
      <path d="M361.705 197.99L378.708 198.99L378.028 210.27L375.157 210.09L375.637 202.09L371.847 201.87L371.417 209.14L368.546 208.97L368.976 201.69L364.386 201.42L363.906 209.42L361.035 209.24L361.705 197.99Z" />
      <path d="M371.497 244.78L370.287 249.34L353.824 244.99L354.664 241.81L366.436 244.95L355.805 237.45L356.295 235.6L369.297 234.35L357.435 231.22L358.275 228.04L374.737 232.39L373.527 236.95L361.665 238L371.497 244.78Z" />
      <path d="M352.214 249.16L368.217 255.09L364.286 265.67L361.586 264.67L364.376 257.17L360.815 255.85L358.275 262.68L355.585 261.68L358.115 254.85L353.804 253.25L351.004 260.79L348.313 259.79L352.214 249.16Z" />
      <path d="M346.493 263.78L361.706 271.43L359.195 276.43C356.925 280.93 352.434 282.38 347.854 280.07C343.273 277.76 341.723 273.28 343.983 268.77L346.493 263.78ZM347.584 268.01L346.583 270.07C345.233 272.76 346.373 275.55 349.414 277.07C352.454 278.59 355.305 277.82 356.655 275.12L357.655 273.07L347.584 268.01Z" />
      <path d="M326.24 304.99C323.83 303.13 323.71 300.39 325.89 297.55L330.191 291.97L343.683 302.35L339.682 307.6C337.742 310.12 335.421 310.68 333.401 309.12C332.763 308.718 332.262 308.132 331.965 307.439C331.669 306.746 331.59 305.979 331.741 305.24C330.943 305.856 329.952 306.169 328.945 306.123C327.937 306.077 326.979 305.676 326.24 304.99V304.99ZM328.41 298.99C327.36 300.36 327.41 301.71 328.69 302.66C329.971 303.61 331.331 303.3 332.441 301.86L334.381 299.35L330.461 296.35L328.41 298.99ZM339.412 303.2L336.352 300.85L334.511 303.24C334.303 303.442 334.143 303.689 334.044 303.962C333.945 304.235 333.909 304.526 333.938 304.815C333.968 305.104 334.063 305.382 334.216 305.629C334.369 305.876 334.576 306.085 334.821 306.24C335.731 306.94 336.752 306.71 337.622 305.58L339.412 303.2Z" />
      <path d="M319.799 305.03L332.261 316.62L330.02 319.03L319.669 309.4L314.548 314.91L312.438 312.91L319.799 305.03Z" />
      <path d="M301.356 323.08L305.527 325.53L310.087 321.48L308.167 317.03L310.768 314.73L317.679 331.35L314.428 334.24L298.766 325.38L301.356 323.08ZM311.288 324.23L308.107 327.05L314.008 330.51L311.288 324.23Z" />
      <path d="M297.426 347.33L294.745 349.23L284.894 335.36L287.554 333.47L300.256 338.8L293.475 329.26L296.155 327.36L306.017 341.23L303.276 343.17L290.674 337.78L297.426 347.33Z" />
      <path d="M272.291 342.7L280.293 346.89L280.833 344.1L278.172 339.37L281.043 337.76L289.424 352.59L286.554 354.2L283.093 348.05L281.423 357.1L277.992 359.03L279.713 350.2L268.711 344.71L272.291 342.7Z" />
      <path d="M120.188 346.58L114.047 359.32L118.478 361.46L117.227 364.05L105.396 358.35L106.646 355.76L111.076 357.9L117.217 345.15L120.188 346.58Z" />
      <path d="M100.175 335.54L99.0544 340.24L104.265 343.42L107.956 340.28L110.956 342.09L97.0541 353.57L93.3936 351.3L97.2142 333.78L100.175 335.54ZM101.995 345.37L98.3943 343.16L96.7841 349.78L101.995 345.37Z" />
      <path d="M82.9415 322.78L71.5298 335.42L63.1484 327.85L65.0787 325.72L71.0197 331.09L73.5601 328.27L68.1492 323.38L70.0795 321.25L75.4904 326.14L78.5709 322.72L72.5699 317.32L74.5002 315.19L82.9415 322.78Z" />
      <path d="M51.7768 315.78L49.5664 313.34L62.1984 301.92L64.3887 304.38L60.5781 317.62L69.2595 309.78L71.4698 312.22L58.8479 323.63L56.5875 321.14L60.4481 307.98L51.7768 315.78Z" />
      <path d="M40.0957 278.13C42.6961 276.55 45.2965 277.43 47.1568 280.49L50.8074 286.49L36.2451 295.32L32.8146 289.65C31.1643 286.94 31.4744 284.57 33.6647 283.25C34.27 282.799 34.9987 282.544 35.7531 282.519C36.5075 282.494 37.2515 282.7 37.8854 283.11C37.614 282.152 37.6864 281.13 38.0902 280.221C38.494 279.311 39.2035 278.571 40.0957 278.13V278.13ZM36.9952 291.04L40.3057 289.04L38.7455 286.46C38.6295 286.2 38.4572 285.969 38.241 285.784C38.0248 285.599 37.7701 285.464 37.4953 285.389C37.2206 285.315 36.9327 285.302 36.6525 285.352C36.3722 285.402 36.1067 285.514 35.8751 285.68C34.8749 286.27 34.7349 287.31 35.475 288.53L36.9952 291.04ZM44.8965 282.32C44.0063 280.84 42.7161 280.44 41.3759 281.25C40.0357 282.06 39.8257 283.49 40.7658 285.04L42.3861 287.78L46.6167 285.21L44.8965 282.32Z" />
      <path d="M34.7347 276.29C30.0339 278.5 25.4032 277.09 23.3929 272.8C21.3826 268.51 23.3029 264.18 28.0136 261.97C32.7244 259.76 37.4951 261.05 39.4654 265.26C41.4357 269.47 39.5454 274.04 34.7347 276.29ZM29.3238 265.04C26.1033 266.55 24.7231 269.26 25.8533 271.65C26.9835 274.04 29.9839 274.82 33.2044 273.3C36.665 271.68 38.2052 268.89 37.015 266.41C35.8249 263.93 32.7844 263.41 29.3238 265.04Z" />
      <path d="M25.6529 244.08C28.5233 243.08 30.8737 244.48 32.0539 247.87L34.3542 254.52L18.2717 260.09L16.1114 253.83C15.1112 250.83 15.8713 248.58 18.2817 247.75C18.9688 247.435 19.7359 247.338 20.4797 247.472C21.2235 247.607 21.9084 247.965 22.4424 248.5C22.3706 247.503 22.6534 246.513 23.2408 245.704C23.8283 244.895 24.6825 244.32 25.6529 244.08ZM19.912 256.08L23.5625 254.78L22.5624 251.93C22.5041 251.651 22.3846 251.389 22.2125 251.162C22.0404 250.936 21.82 250.75 21.5672 250.619C21.3145 250.488 21.0358 250.414 20.7512 250.404C20.4667 250.394 20.1834 250.447 19.922 250.56C18.8318 250.93 18.4617 251.91 18.9218 253.26L19.912 256.08ZM29.4635 249.22C28.9034 247.59 27.7232 246.93 26.243 247.44C24.7627 247.95 24.2426 249.3 24.8527 251.01L25.8529 254.01L30.5236 252.39L29.4635 249.22Z" />
      <path d="M29.134 238.66L12.6114 242.78L11.8213 239.59L25.5534 236.21L23.7532 228.9L26.5536 228.21L29.134 238.66Z" />
      <path d="M25.8329 224.51L9.01031 227.1L7.29004 215.94L10.1305 215.5L11.3807 223.41L15.1313 222.83L14.0211 215.63L16.8615 215.19L17.9717 222.4L22.5224 221.7L21.2922 213.7L24.1427 213.27L25.8329 224.51Z" />
      <path d="M18.0114 209.78L15.2814 209.98L14.7314 202.39L17.4614 202.19L18.0114 209.78Z" />
      <path d="M23.0227 193.86L8.87045 193.78V198.7H6L6.09001 185.56H8.97046V190.49L23.1227 190.59L23.0227 193.86Z" />
      <path d="M23.3125 182.27L6.37988 180.59L7.50006 169.35L10.3605 169.63L9.57038 177.63L13.341 178L14.0611 170.75L16.9315 171.03L16.2014 178.29L20.7821 178.74L21.5823 170.74L24.4427 171.03L23.3125 182.27Z" />
      <path d="M15.3816 135.12L16.7619 130.61L33.0344 135.61L32.0343 138.76L20.3824 135.17L30.714 143.08L30.154 144.9L17.1519 145.65L28.8538 149.24L27.8536 152.38L11.5811 147.38L12.9713 142.87L24.8631 142.29L15.3816 135.12Z" />
      <path d="M34.7543 131.44L19.1318 124.71L23.6025 114.34L26.2429 115.48L23.0725 122.78L26.553 124.29L29.4434 117.59L32.0839 118.78L29.1934 125.48L33.3841 127.29L36.5746 119.9L39.215 121.03L34.7543 131.44Z" />
      <path d="M41.0859 117.18L26.1836 108.94L28.894 104.05C31.3344 99.64 35.8951 98.37 40.3658 100.85C44.8365 103.33 46.2267 107.85 43.7863 112.29L41.0859 117.18ZM40.1558 112.91L41.276 110.91C42.7362 108.27 41.696 105.44 38.7156 103.79C35.7351 102.14 32.8746 102.79 31.4144 105.45L30.2942 107.45L40.1558 112.91Z" />
      <path d="M62.9485 76.7799C65.2788 78.7199 65.2989 81.4599 62.9485 84.2199L58.4478 89.6199L45.3857 78.7099L49.6264 73.6199C51.6267 71.1799 54.0071 70.7099 55.9674 72.3499C56.5866 72.7804 57.0611 73.3879 57.3288 74.0928C57.5966 74.7977 57.6449 75.567 57.4676 76.2999C58.2898 75.7173 59.2923 75.4458 60.2962 75.5337C61.3001 75.6216 62.2401 76.0633 62.9485 76.7799V76.7799ZM49.7364 78.0299L52.7369 80.4999L54.6672 78.1899C54.8816 77.9962 55.0495 77.7565 55.1584 77.4889C55.2673 77.2212 55.3144 76.9324 55.2961 76.6441C55.2778 76.3557 55.1947 76.0751 55.0529 75.8234C54.9111 75.5716 54.7143 75.355 54.4772 75.1899C53.597 74.4499 52.5669 74.6399 51.6567 75.7299L49.7364 78.0299ZM60.5481 82.6699C61.6483 81.3399 61.6083 79.9899 60.4081 78.9899C59.2079 77.9899 57.7977 78.2399 56.6275 79.6399L54.6272 82.0799L58.3878 85.2499L60.5481 82.6699Z" />
      <path d="M69.3896 76.9701L57.3877 64.9001L59.7181 62.5801L69.7196 72.5801L75.0505 67.2801L77.0508 69.2801L69.3896 76.9701Z" />
      <path d="M88.5528 59.66L84.4822 57.05L79.7614 60.91L81.5017 65.43L78.8113 67.63L72.5703 50.78L75.9408 48.03L91.2432 57.5L88.5528 59.66ZM78.6713 58.12L81.9618 55.42L76.2009 51.72L78.6713 58.12Z" />
      <path d="M93.3932 35.58L96.1636 33.78L105.475 48.03L102.745 49.78L90.2527 43.99L96.6537 53.79L93.9033 55.59L84.5918 41.34L87.3922 39.5L99.7942 45.36L93.3932 35.58Z" />
      <path d="M118.397 41.2101L110.536 36.7001L109.886 39.4701L112.356 44.3001L109.396 45.7801L101.635 30.6301L104.555 29.1301L107.776 35.4101L109.776 26.4101L113.277 24.6101L111.216 33.3601L121.978 39.2801L118.397 41.2101Z" />
    </svg>
  );
};

export default BubbleTea;
