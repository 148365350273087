const SnakkMedJAJ: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <>
      <div className={className}>
        <svg
          viewBox="0 0 313 313"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#cli…p0_6122_5297)">
            <path d="M156.013 8.73858L158.423 8.82267C158.413 10.2522 159.366 11.0615 160.71 11.1035C161.879 11.1456 162.587 10.4939 162.618 9.55844C162.669 8.16048 161.428 7.80311 160.064 7.35114C158.372 6.78355 156.464 6.04778 156.556 3.25187C156.608 1.1707 158.331 -0.080099 160.864 0.00398855C163.305 0.0880761 164.894 1.62267 164.884 3.90355L162.556 3.81946C162.556 2.58968 161.818 1.92749 160.751 1.88545C159.674 1.8434 158.956 2.41099 158.946 3.30442C158.925 4.51318 159.807 4.93362 160.915 5.31201C162.7 5.93216 165.099 6.43669 164.997 9.4323C164.925 11.6816 163.212 13.0901 160.689 12.9955C157.767 12.8904 155.921 11.2087 156.003 8.73858H156.013Z" />
            <path d="M184.414 2.22937L187 2.6109L184.86 15L182.297 14.6185L178.063 5.24981L176.586 13.7706L174 13.3891L176.14 1L178.783 1.39213L182.949 10.729L184.425 2.22937H184.414Z" />
            <path d="M200.617 19.288L200.535 15.7592L196.365 14.4921L194.383 17.3822L192 16.6597L199.498 6L202.476 6.90052L203 20L200.617 19.2775V19.288ZM197.577 12.6911L200.494 13.5812L200.371 8.59686L197.577 12.6911Z" />
            <path d="M216.814 26.6808L215.749 19.5459L213.854 20.5209L212.225 24.4784L210 23.4116L215.121 11L217.346 12.0668L215.217 17.2173L221.338 13.9825L224 15.2557L217.995 18.3644L219.592 28L216.803 26.6694L216.814 26.6808Z" />
            <path d="M233.452 34.4462L233.226 27.832L231.215 28.4829L229.108 31.874L227 30.6352L233.591 20L235.699 21.2388L232.968 25.6483L239.484 23.4751L242 24.9659L235.624 27.0446L236.086 36L233.452 34.4462Z" />
            <path d="M274.654 52.9923L277 55.5562L267.713 64L266.079 62.2095L272.733 56.1601L263.829 59.741L262.884 58.7028L267.31 50.1636L260.634 56.2343L259 54.4438L268.287 46L270.632 48.5639L266.535 56.3296L274.676 52.9923H274.654Z" />
            <path d="M273 71.1377L283.278 64L288 70.8411L286.268 72.0484L282.919 67.1982L280.627 68.7867L283.68 73.2027L281.947 74.41L278.894 69.994L276.116 71.9213L279.486 76.7927L277.754 78L273.011 71.1377H273Z" />
            <path d="M282 85.8899L292.432 80L294.232 83.6839C295.853 87.0036 294.859 90.3555 291.716 92.1332C288.554 93.9108 285.421 92.9042 283.8 89.5737L282 85.8792V85.8899ZM284.814 87.0679L285.55 88.5885C286.515 90.5804 288.554 91.0944 290.632 89.9164C292.691 88.7599 293.417 86.6823 292.452 84.6905L291.716 83.1698L284.804 87.0679H284.814Z" />
            <path d="M300.506 121L300.987 123.367C299.501 123.698 298.793 124.381 299.009 125.415C299.224 126.481 300.198 126.801 301.808 126.449L309.518 124.732L310 127.1L302.187 128.838C299.511 129.435 297.635 128.369 297.112 125.831C296.599 123.325 297.871 121.587 300.495 121H300.506Z" />
            <path d="M299.89 146.626L303.37 145.31L302.942 141.136L299.248 140.384L299 138L312.696 140.958L313 143.935L300.138 149L299.89 146.616V146.626ZM305.228 141.591L305.533 144.509L310.466 142.649L305.24 141.591H305.228Z" />
            <path d="M300.353 158L313 158.442L312.914 160.795L308.029 160.621L307.847 165.472L312.733 165.646L312.647 168L300 167.558L300.086 165.205L305.709 165.4L305.891 160.549L300.267 160.354L300.353 158Z" />
            <path d="M310.723 185.825L310.323 188L298 186.123L298.401 183.968L307.759 180.465L299.287 179.175L299.688 177L312 178.877L311.599 181.09L302.273 184.535L310.723 185.825Z" />
            <path d="M285.06 222.888L288.463 223.395L290.338 219.092L287.862 216.456L288.932 214L298 223.925L296.665 227L284 225.344L285.07 222.888H285.06ZM291.876 220.714L290.572 223.722L295.381 224.443L291.876 220.714Z" />
            <path d="M279.966 232L290 238.308L287.446 242.138C286.134 244.107 284.196 244.518 282.338 243.353C281.107 242.579 280.552 241.502 280.703 240.297C278.614 241.462 276.464 239.278 275.071 239.484L275 239.444L276.252 237.574C278.291 237.319 280.188 240.16 281.874 237.623L282.692 236.398L278.705 233.891L279.956 232.01L279.966 232ZM284.388 237.446L283.116 239.356C282.601 240.13 282.793 240.933 283.61 241.443C284.428 241.952 285.276 241.776 285.811 240.973L287.053 239.121L284.398 237.446H284.388Z" />
            <path d="M274.041 260.249L272.499 262L263 254.365L264.531 252.624L274.481 254.005L267.949 248.751L269.501 247L279 254.635L277.417 256.426L267.519 255.005L274.041 260.249Z" />
            <path d="M257.439 260.02L265 268.675L259.59 274L258.312 272.534L262.145 268.755L260.463 266.821L256.969 270.261L255.691 268.795L259.186 265.355L257.138 263.011L253.277 266.811L252 265.345L257.439 260V260.02Z" />
            <path d="M229.146 288.908C230.86 292.323 229.94 295.77 226.868 297.35C223.857 298.899 220.596 297.615 218.893 294.189C217.147 290.689 218.015 287.221 221.025 285.662C224.098 284.082 227.401 285.397 229.156 288.897L229.146 288.908ZM221.088 293.161C222.258 295.505 224.255 296.459 225.969 295.568C227.745 294.656 228.174 292.45 227.003 290.106C225.749 287.592 223.68 286.542 221.903 287.455C220.189 288.335 219.823 290.636 221.077 293.15L221.088 293.161Z" />
            <path d="M204.204 306.89L200.966 308L197 296.153L199.259 295.371L202.101 303.857L202.372 294.304L203.683 293.849L209.638 301.3L206.785 292.782L209.044 292L213 303.847L209.763 304.956L204.381 298.119L204.193 306.88L204.204 306.89Z" />
            <path d="M168 304.277L165.29 304.384C165.206 302.797 164.597 301.976 163.403 302.019C162.185 302.072 161.636 303.01 161.732 304.714L162.149 312.894L159.439 313L159.009 304.714C158.866 301.87 160.394 300.123 163.296 300.006C166.161 299.889 167.845 301.476 167.988 304.266L168 304.277Z" />
            <path d="M149.975 307.005C149.705 310.803 147.272 313.244 143.991 312.981C140.779 312.728 138.755 309.93 139.024 306.132C139.303 302.249 141.707 299.756 144.918 300.019C148.199 300.282 150.254 303.133 149.975 307.005ZM141.328 306.395C141.138 308.993 142.305 310.845 144.14 310.992C146.035 311.139 147.461 309.498 147.651 306.9C147.85 304.112 146.674 302.144 144.779 301.997C142.953 301.85 141.527 303.617 141.328 306.395Z" />
            <path d="M120.569 300.869C121.049 298.593 122.914 297.592 125.65 298.153L131 299.242L128.308 312L123.271 310.966C120.859 310.471 119.675 309.063 120.077 307.15C120.356 305.808 121.127 304.95 122.233 304.719C120.803 303.982 120.189 302.607 120.557 300.869H120.569ZM126.276 309.327L126.89 306.435L124.6 305.962C123.551 305.753 122.836 306.193 122.635 307.161C122.456 308.019 122.959 308.645 124.042 308.865L126.287 309.327H126.276ZM125.494 300.364C124.187 300.1 123.271 300.605 123.026 301.782C122.78 302.959 123.472 303.817 124.857 304.103L127.27 304.598L128.052 300.891L125.494 300.375V300.364Z" />
            <path d="M104.328 295.369C105.023 293.235 106.843 292.488 109.256 293.352L114 295.038L110.094 307L105.637 305.41C103.5 304.652 102.59 303.159 103.173 301.355C103.582 300.096 104.379 299.36 105.412 299.264C104.195 298.378 103.796 297.002 104.338 295.358L104.328 295.369ZM108.54 304.194L109.43 301.483L107.405 300.758C106.475 300.427 105.78 300.768 105.483 301.675C105.217 302.486 105.606 303.148 106.567 303.489L108.551 304.194H108.54ZM108.857 295.465C107.692 295.049 106.812 295.444 106.455 296.543C106.097 297.642 106.628 298.549 107.855 298.986L109.992 299.744L111.127 296.265L108.868 295.465H108.857Z" />
            <path d="M78 278.356L71.044 289L69 287.644L75.956 277L78 278.356Z" />
            <path d="M43.9527 252.9C45.627 251.498 47.6554 251.745 49.4584 253.561L53 257.134L43.6093 265L40.2823 261.634C38.6832 260.026 38.5759 258.387 39.9818 257.203C40.9692 256.374 42.0531 256.157 43.0512 256.552C42.3644 255.269 42.6756 253.966 43.9527 252.89V252.9ZM43.5449 262L45.6806 260.213L44.1674 258.683C43.4698 257.983 42.6649 257.933 41.9458 258.535C41.3126 259.068 41.3448 259.779 42.0639 260.509L43.5449 262ZM48.0739 255.081C47.2046 254.203 46.2065 254.104 45.3372 254.834C44.4786 255.555 44.5215 256.542 45.4338 257.47L47.0329 259.078L49.7589 256.789L48.0739 255.081Z" />
            <path d="M40.9891 244.575L30.4562 252L29 249.998L37.7591 243.832L34.4197 239.259L36.2044 238L41 244.575H40.9891Z" />
            <path d="M28.4206 224.118L25.9188 226.588L28.1378 230.339L31.7382 229.724L33 231.862L19.5772 234L18 231.328L27.1588 222L28.4206 224.138V224.118ZM25.9079 230.733L24.3633 228.121L20.8173 231.62L25.9079 230.743V230.733Z" />
            <path d="M9.85803 210.783L9 208.533L20.4025 204L21.2502 206.24L15.2957 214.334L23.142 211.217L24 213.467L12.5975 218L11.7188 215.697L17.6733 207.666L9.84769 210.783H9.85803Z" />
            <path d="M16.1483 189.703L11.4368 194.144L13.3762 194.994L17.3974 194.125L18 196.268L5.39956 199L4.79693 196.857L10.0234 195.718L3.72316 192.995L3 190.427L9.13587 193.121L15.4032 187L16.1592 189.684L16.1483 189.703Z" />
            <path d="M8.86048 179.045L0.349182 180L0 178.308L8.56586 177.602L8.86048 179.045ZM14 178.647L11.1193 178.925L10.781 177.278L13.6617 177L14 178.647Z" />
          </g>
          <defs>
            <clipPath id="clip0_6122_5297">
              <rect width="313" height="313" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default SnakkMedJAJ;
