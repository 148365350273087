const OolongTea: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M258.51 34.64L264.141 21.64L259.63 19.64L260.78 17L272.782 22.23L271.632 24.87L267.121 22.87L261.48 35.87L258.51 34.64Z" />
      <path d="M278.923 44.9L279.853 40.15L274.532 37.15L270.961 40.43L267.961 38.74L281.363 26.74L285.164 28.85L281.983 46.56L278.923 44.9ZM276.722 35.13L280.433 37.2L281.753 30.48L276.722 35.13Z" />
      <path d="M296.646 56.9599L307.517 43.8899L316.229 51.1299L314.378 53.3399L308.237 48.2099L305.807 51.1299L311.398 55.8099L309.518 57.9999L303.917 53.3299L300.976 56.8599L307.157 62.0199L305.307 64.2299L296.646 56.9599Z" />
      <path d="M328.041 62.6999L330.341 65.0499L318.179 76.9499L315.889 74.6099L319.179 61.2299L310.808 69.4199L308.518 67.0699L320.669 55.1299L323.02 57.5299L319.689 70.8199L328.041 62.6999Z" />
      <path d="M337.152 86.8001C341.513 83.9701 346.293 84.7301 348.874 88.7101C351.454 92.6901 350.134 97.2401 345.773 100.07C341.412 102.9 336.512 102.28 333.981 98.3801C331.451 94.4801 332.701 89.6901 337.152 86.8001ZM344.053 97.2101C347.053 95.2701 348.054 92.4001 346.593 90.2101C345.133 88.0201 342.073 87.6301 339.082 89.5701C335.882 91.6501 334.761 94.5701 336.252 96.9201C337.742 99.2701 340.852 99.2901 344.053 97.2101Z" />
      <path d="M348.013 103.98C352.664 101.67 357.324 102.98 359.435 107.21C361.545 111.44 359.725 115.83 355.074 118.15C350.423 120.47 345.613 119.28 343.542 115.15C341.472 111.02 343.262 106.35 348.013 103.98ZM353.684 115.13C356.874 113.54 358.185 110.81 357.014 108.44C355.844 106.07 352.804 105.37 349.613 106.96C346.203 108.66 344.753 111.49 345.973 113.96C347.193 116.43 350.273 116.81 353.684 115.13Z" />
      <path d="M348.874 125.77L364.797 119.77L365.957 122.85L352.735 127.85L355.395 134.85L352.715 135.85L348.874 125.77Z" />
      <path d="M362.116 136.66C367.116 135.31 371.447 137.51 372.677 142.08C373.908 146.65 371.247 150.59 366.226 151.94C361.206 153.29 356.735 151.19 355.525 146.7C354.314 142.21 356.975 138.04 362.116 136.66ZM365.476 148.66C368.917 147.74 370.737 145.31 370.057 142.76C369.377 140.21 366.536 138.92 363.056 139.85C359.365 140.85 357.395 143.32 358.105 145.97C358.815 148.62 361.786 149.69 365.526 148.69L365.476 148.66Z" />
      <path d="M377.118 165.96L377.618 169.21L360.796 171.79L360.296 168.57L370.767 159.57L359.195 161.34L358.695 158.09L375.528 155.55L376.038 158.87L365.586 167.73L377.118 165.96Z" />
      <path d="M369.817 175.41C375.007 175.19 378.728 178.25 378.928 182.96C379.052 184.607 378.58 186.243 377.598 187.57C376.616 188.898 375.188 189.828 373.577 190.19L373.427 186.85C374.272 186.652 375.018 186.158 375.53 185.457C376.042 184.756 376.286 183.895 376.218 183.03C376.128 180.34 373.657 178.6 370.077 178.75C366.256 178.92 363.786 180.86 363.896 183.48C363.932 184.516 364.369 185.498 365.116 186.218C365.862 186.939 366.859 187.341 367.896 187.34L367.776 184.47L370.397 184.36L370.657 190.47L361.825 190.84L361.735 188.54L364.326 188.43C363.379 188.034 362.571 187.365 362.007 186.509C361.442 185.652 361.146 184.646 361.155 183.62C360.985 178.95 364.526 175.63 369.817 175.41Z" />
      <path d="M367.427 195.04L370.157 195.17L369.797 202.77L367.057 202.64L367.427 195.04Z" />
      <path d="M360.526 210.24L374.528 212.04L375.158 207.16L378.008 207.53L376.338 220.53L373.488 220.17L374.108 215.29L360.105 213.48L360.526 210.24Z" />
      <path d="M358.865 221.72L375.528 225.43L373.057 236.43L370.257 235.8L371.997 227.99L368.296 227.16L366.706 234.28L363.896 233.65L365.486 226.54L361.005 225.54L359.245 233.4L356.435 232.77L358.865 221.72Z" />
      <path d="M361.146 269.45L359.246 273.77L343.663 266.9L344.993 263.9L356.145 268.82L346.824 259.75L347.594 258.01L360.596 258.8L349.404 253.86L350.734 250.86L366.307 257.73L364.406 262.04L352.524 261.21L361.146 269.45Z" />
      <path d="M341.462 270.83L356.184 279.38L350.524 289.13L348.033 287.69L352.034 280.77L348.753 278.86L345.093 285.17L342.612 283.72L346.273 277.42L342.272 275.11L338.272 282.11L335.781 280.66L341.462 270.83Z" />
      <path d="M333.521 284.26L347.314 294.26L344.043 298.79C341.043 302.87 336.432 303.59 332.271 300.58C328.111 297.57 327.27 292.9 330.271 288.82L333.521 284.26ZM333.921 288.61L332.521 290.47C330.751 292.91 331.441 295.85 334.191 297.84C336.942 299.83 339.882 299.51 341.653 297.07L343.003 295.21L333.921 288.61Z" />
      <path d="M306.927 321.73C304.847 319.52 305.157 316.79 307.777 314.34L312.898 309.51L324.57 321.91L319.739 326.45C317.429 328.62 315.048 328.81 313.298 326.95C312.732 326.451 312.333 325.79 312.154 325.057C311.975 324.324 312.025 323.554 312.298 322.85C311.414 323.324 310.391 323.471 309.409 323.266C308.427 323.062 307.548 322.518 306.927 321.73V321.73ZM310.028 316.16C308.777 317.34 308.647 318.69 309.727 319.83C310.808 320.97 312.228 320.88 313.548 319.64L315.868 317.46L312.478 313.86L310.028 316.16ZM320.199 322.07L317.519 319.26L315.328 321.32C315.091 321.486 314.894 321.703 314.753 321.956C314.612 322.208 314.53 322.49 314.514 322.779C314.497 323.068 314.547 323.357 314.659 323.624C314.77 323.891 314.941 324.129 315.158 324.32C315.938 325.16 316.989 325.1 318.029 324.12L320.199 322.07Z" />
      <path d="M300.516 320.77L310.977 334.19L308.387 336.19L299.686 325.04L293.755 329.66L291.984 327.4L300.516 320.77Z" />
      <path d="M279.512 335.65L283.243 338.73L288.384 335.47L287.203 330.77L290.134 328.91L294.305 346.42L290.634 348.75L276.632 337.51L279.512 335.65ZM289.134 338.37L285.513 340.65L290.794 345.01L289.134 338.37Z" />
      <path d="M271.782 358.99L268.831 360.45L261.29 345.2L264.211 343.75L275.922 351.02L270.732 340.53L273.682 339.07L281.223 354.32L278.223 355.81L266.611 348.52L271.782 358.99Z" />
      <path d="M247.687 350.46L254.959 355.86L255.959 353.2L254.048 348.13L257.129 346.98L263.04 362.98L259.959 364.12L257.509 357.45L254.419 366.13L250.728 367.5L253.798 359.05L243.797 351.9L247.687 350.46Z" />
      <path d="M136.03 350.06L131.18 363.35L135.81 365.04L134.81 367.74L122.488 363.24L123.488 360.54L128.109 362.23L132.96 348.94L136.03 350.06Z" />
      <path d="M114.997 341.07L114.357 345.86L119.848 348.5L123.208 345L126.339 346.5L113.697 359.32L109.776 357.44L111.857 339.57L114.997 341.07ZM117.788 350.67L113.947 348.83L113.047 355.62L117.788 350.67Z" />
      <path d="M96.5741 330.13L86.4825 343.87L77.3711 337.19L79.0714 334.87L85.5324 339.61L87.7727 336.55L81.9018 332.23L83.6021 329.91L89.483 334.23L92.2134 330.52L85.7224 325.76L87.4227 323.44L96.5741 330.13Z" />
      <path d="M64.8388 326.34L62.3984 324.13L73.8102 311.5L76.2306 313.69L73.7802 327.26L81.6214 318.57L84.0718 320.77L72.66 333.4L70.1697 331.13L72.67 317.65L64.8388 326.34Z" />
      <path d="M54.2779 302.88C50.1072 305.98 45.2765 305.51 42.466 301.7C39.6556 297.89 40.6757 293.27 44.8564 290.13C49.037 286.99 53.9678 287.36 56.7282 291.13C59.4887 294.9 58.5485 299.72 54.2779 302.88ZM46.7467 292.88C43.8862 295 43.0761 297.88 44.6464 300.05C46.2166 302.22 49.3271 302.34 52.1875 300.21C55.248 297.94 56.1882 294.9 54.5479 292.71C52.9077 290.52 49.8172 290.65 46.7467 292.92V292.88Z" />
      <path d="M42.376 286.4C37.8753 289 33.1445 287.99 30.7742 283.89C28.4038 279.79 29.954 275.3 34.4547 272.7C38.9554 270.1 43.8162 270.98 46.1466 275C48.4769 279.02 46.9767 283.74 42.376 286.4ZM36.025 275.64C32.9345 277.43 31.7943 280.24 33.1245 282.53C34.4547 284.82 37.5052 285.34 40.5957 283.53C43.8962 281.62 45.1664 278.71 43.7962 276.34C42.426 273.97 39.3255 273.73 36.025 275.64Z" />
      <path d="M40.1751 264.7L24.6627 271.7L23.3125 268.7L36.1945 262.88L33.104 256.03L35.7244 254.84L40.1751 264.7Z" />
      <path d="M26.3031 254.65C21.3723 256.31 16.9417 254.39 15.4214 249.9C13.9012 245.41 16.3216 241.32 21.2523 239.66C26.1831 238 30.7738 239.83 32.254 244.23C33.7343 248.63 31.3339 252.96 26.3031 254.65ZM22.2025 242.86C18.8219 244 17.1517 246.52 18.0018 249.03C18.852 251.54 21.7424 252.65 25.1229 251.51C28.7335 250.29 30.5538 247.69 29.6836 245.09C28.8135 242.49 25.823 241.64 22.2025 242.86V242.86Z" />
      <path d="M9.47065 226.34L8.81055 223.13L25.4732 219.52L26.1733 222.71L16.2717 232.29L27.7135 229.81L28.4136 233.02L11.751 236.63L11.0409 233.35L20.9324 223.86L9.47065 226.34Z" />
      <path d="M16.211 216.44C11.0402 216.98 7.13961 214.15 6.64954 209.44C6.42366 207.804 6.79469 206.142 7.69469 204.757C8.5947 203.372 9.96345 202.358 11.5503 201.9L11.9004 205.22C11.0672 205.472 10.3519 206.014 9.88397 206.748C9.41609 207.481 9.22664 208.359 9.34996 209.22C9.61 211.9 12.1804 213.49 15.741 213.11C19.5416 212.72 21.8919 210.62 21.6219 208.01C21.5202 206.973 21.0182 206.017 20.2226 205.345C19.4269 204.673 18.4004 204.337 17.3612 204.41L17.6613 207.27L15.0508 207.54L14.4107 201.46L23.2021 200.54L23.4722 202.83L20.8818 203.1C21.8537 203.435 22.7034 204.053 23.3217 204.875C23.9401 205.696 24.2989 206.683 24.3523 207.71C24.8024 212.35 21.4719 215.89 16.211 216.44Z" />
      <path d="M17.3812 196.72H14.6412L14.5312 189.13H17.2712L17.3812 196.72Z" />
      <path d="M23.3127 181.13L9.1905 180.13L8.87045 185.04L6 184.85L6.86013 171.75L9.73058 171.94L9.41053 176.85L23.5227 177.78L23.3127 181.13Z" />
      <path d="M24.2633 169.54L7.4707 166.86L9.25098 155.71L12.0914 156.16L10.8312 164.07L14.5818 164.66L15.722 157.46L18.5624 157.91L17.4023 165.13L21.943 165.86L23.2032 157.86L26.0436 158.32L24.2633 169.54Z" />
      <path d="M19.0523 122.04L20.6925 117.61L36.695 123.52L35.4748 126.6L24.073 122.37L33.9346 130.85L33.2745 132.64H20.2724L31.7442 136.88L30.6041 139.97L14.6016 134.06L16.2318 129.64L28.1437 129.73L19.0523 122.04Z" />
      <path d="M38.6052 119.47L23.3828 111.85L28.4736 101.75L31.044 103.04L27.4735 110.2L30.874 111.9L34.1345 105.37L36.7049 106.66L33.4444 113.18L37.475 115.24L41.0756 108.04L43.646 109.33L38.6052 119.47Z" />
      <path d="M45.7458 105.57L31.3535 96.48L34.354 91.76C37.0444 87.5 41.6551 86.49 45.9958 89.23C50.3365 91.97 51.4367 96.59 48.7462 100.85L45.7458 105.57ZM45.0757 101.26L46.2958 99.31C47.9061 96.76 47.036 93.88 44.1555 92.06C41.2751 90.24 38.3746 90.75 36.7644 93.29L35.5342 95.29L45.0757 101.26Z" />
      <path d="M69.9204 66.4999C72.1407 68.5799 72.0007 71.32 69.5403 73.93L64.7296 79.07L52.3076 67.4199L56.8383 62.5899C59.0187 60.2699 61.379 59.9299 63.2493 61.6799C63.8373 62.1427 64.2721 62.7719 64.4969 63.4855C64.7217 64.1991 64.7261 64.9638 64.5095 65.6799C65.3642 65.1596 66.3747 64.9566 67.364 65.1065C68.3534 65.2565 69.2583 65.7497 69.9204 66.4999ZM56.6583 66.9899L59.4787 69.6299L61.5391 67.4299C61.7659 67.2512 61.9492 67.0233 62.0752 66.7635C62.2013 66.5037 62.2668 66.2187 62.2668 65.9299C62.2668 65.6412 62.2013 65.3562 62.0752 65.0964C61.9492 64.8366 61.7659 64.6087 61.5391 64.4299C60.6989 63.6399 59.6588 63.7699 58.6786 64.8099L56.6583 66.9899ZM67.1799 72.2499C68.3601 70.9899 68.4001 69.6399 67.26 68.5699C66.1198 67.4999 64.6896 67.6699 63.4494 68.9999L61.269 71.3199L64.8796 74.6999L67.1799 72.2499Z" />
      <path d="M76.3511 67.0599L65.0693 54.3199L67.5297 52.1299L76.9112 62.7299L82.5421 57.7299L84.4824 59.8999L76.3511 67.0599Z" />
      <path d="M96.4839 50.9101L92.5733 48.0601L87.6325 51.6401L89.1127 56.2601L86.3023 58.2601L81.0215 41.1301L84.542 38.5801L99.2743 48.8701L96.4839 50.9101ZM86.6824 48.8001L90.1329 46.3001L84.592 42.3001L86.6824 48.8001Z" />
      <path d="M102.695 27.13L105.556 25.49L114.027 40.26L111.196 41.89L99.0546 35.36L104.885 45.51L102.035 47.13L93.5537 32.38L96.4842 30.71L108.486 37.28L102.695 27.13Z" />
      <path d="M127.269 34.1799L119.688 29.2399L118.878 31.9599L121.068 36.9599L118.068 38.2799L111.187 22.6899L114.187 21.3599L117.047 27.8199L119.598 18.9699L123.188 17.3799L120.638 25.9999L131.04 32.5199L127.269 34.1799Z" />
    </svg>
  );
};

export default OolongTea;
