'use client';
import { createContext, FC, useContext, useEffect, useState } from 'react';

const DarksideContext = createContext(null);

export const DarksideProvider: FC<{
  children?: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const [darksideMode, setDarksideMode] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    let path = window.location.pathname;
    let locationId =
      path === '/' ? 'frontPage' : path.substring(path.lastIndexOf('/') + 1);
    const page = document.body;
    if (locationId !== '') {
      window.scrollTo(0, 0);
      const locationIdElement = document.getElementById(locationId);
      if (locationIdElement == undefined) {
        return () => {};
      }
      if (darksideMode) {
        page.style.backgroundColor = '#0000FF';
        locationIdElement.style.display = 'none';
      } else {
        page.style.backgroundColor = 'var(--light)';
        if (locationIdElement) {
          locationIdElement.style.display = 'block';
        }
      }
    }
    return () => {};
  }, [darksideMode]);

  return (
    <DarksideContext.Provider value={{ darksideMode, setDarksideMode }}>
      {children}
    </DarksideContext.Provider>
  );
};

export const useDarkSideContext = () => {
  return useContext(DarksideContext);
};
