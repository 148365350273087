const Ramlosa: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M269.692 41.8618L276.332 29.5342L272.047 27.2289L273.393 24.7258L284.832 30.8896L283.486 33.3631L279.202 31.0479L272.562 43.3755L269.692 41.8618Z" />
      <path d="M289.007 53.6749L290.324 49.0743L285.316 45.7104L281.527 48.6785L278.677 46.7591L292.896 36.0244L296.459 38.4286L291.857 55.614L289.007 53.6749ZM287.642 43.8702L291.125 46.2249L292.966 39.7148L287.642 43.8702Z" />
      <path d="M305.474 67.0017L317.348 55.0303L325.264 62.8859L323.285 64.8647L317.655 59.2945L314.983 62.0548L320.119 67.1303L318.14 69.1091L312.984 64.0336L309.788 67.2985L315.448 72.9379L313.469 74.9167L305.474 67.0017Z" />
      <path d="M335.941 75.2925L338.019 77.8055L325.036 88.5204L322.978 86.0371L327.322 73.1159L318.416 80.4867L316.348 77.9836L329.331 67.2588L331.438 69.8213L327.055 82.6831L335.941 75.2925Z" />
      <path d="M335.615 104.291L350.359 96.218L353.535 102.026C355.178 104.994 354.406 107.695 351.675 109.189C350.924 109.67 350.048 109.917 349.156 109.9C348.265 109.882 347.399 109.6 346.668 109.09C346.767 112.493 342.522 113.641 341.859 115.511L341.76 115.57L340.206 112.731C341.245 110.04 346.143 109.644 344.036 105.805L343.046 103.945L337.178 107.151L335.615 104.291ZM345.51 102.57L347.083 105.469C347.209 105.732 347.39 105.966 347.613 106.154C347.837 106.341 348.099 106.479 348.38 106.557C348.662 106.635 348.957 106.651 349.245 106.605C349.534 106.559 349.809 106.451 350.052 106.29C350.326 106.165 350.571 105.982 350.768 105.754C350.965 105.526 351.11 105.258 351.194 104.969C351.278 104.679 351.298 104.375 351.252 104.077C351.207 103.779 351.098 103.494 350.933 103.243L349.389 100.433L345.51 102.57Z" />
      <path d="M347.657 129.085L351.279 125.959L348.933 120.428L344.164 120.814L342.828 117.648L360.64 116.451L362.312 120.408L349.032 132.281L347.657 129.085ZM351.892 120.181L353.525 124.059L358.651 119.636L351.892 120.181Z" />
      <path d="M369.813 142.808L371.109 147.289L354.92 151.959L354.02 148.833L365.607 145.489L352.744 144.499L352.219 142.689L362.609 135.021L350.972 138.375L350.072 135.249L366.26 130.579L367.557 135.061L358.008 141.986L369.813 142.808Z" />
      <path d="M355.722 156.322L372.306 153.414L372.87 156.609L359.086 159.013L360.372 166.335L357.572 166.829L355.722 156.322Z" />
      <path d="M366.557 169.362C371.683 168.986 375.463 171.925 375.809 176.595C376.155 181.265 372.84 184.599 367.705 184.975C362.569 185.351 358.621 182.472 358.284 177.891C357.948 173.31 361.312 169.748 366.557 169.362ZM367.546 181.68C371.069 181.413 373.286 179.405 373.098 176.793C372.91 174.181 370.396 172.41 366.883 172.667C363.113 172.944 360.728 174.992 360.946 177.693C361.164 180.394 363.806 181.957 367.576 181.68H367.546ZM379.421 172.4C380.598 172.311 381.489 172.904 381.558 173.884C381.627 174.863 380.836 175.586 379.658 175.665C378.481 175.744 377.59 175.16 377.521 174.181C377.452 173.201 378.283 172.489 379.49 172.4H379.421ZM379.747 176.911C380.935 176.832 381.815 177.406 381.894 178.405C381.974 179.405 381.172 180.097 379.995 180.186C378.817 180.275 377.926 179.672 377.857 178.702C377.788 177.732 378.619 177.01 379.816 176.911H379.747Z" />
      <path d="M364.38 189.249L364.271 192.593C363.854 192.577 363.438 192.648 363.051 192.804C362.663 192.959 362.313 193.194 362.023 193.494C361.732 193.793 361.508 194.151 361.366 194.543C361.223 194.935 361.164 195.353 361.194 195.769C361.144 197.391 362.025 198.371 363.291 198.411C365.181 198.47 365.666 196.758 366.26 194.859C367.012 192.514 368.002 189.912 371.782 189.981C374.602 190.04 376.314 192.415 376.195 195.917C376.096 199.301 374.018 201.517 370.94 201.517L371.049 198.282C371.39 198.309 371.733 198.263 372.055 198.148C372.377 198.032 372.671 197.849 372.917 197.612C373.163 197.374 373.356 197.087 373.482 196.769C373.609 196.452 373.667 196.111 373.652 195.769C373.701 194.265 372.929 193.286 371.722 193.276C370.09 193.276 369.525 194.483 369.021 196.006C368.19 198.49 367.517 201.814 363.47 201.695C360.422 201.596 358.522 199.232 358.621 195.759C358.769 191.712 361.035 189.14 364.38 189.249Z" />
      <path d="M357.038 216.595L361.748 215.734L362.539 209.798L358.275 207.671L358.73 204.267L374.651 212.242L374.077 216.506L356.622 219.998L357.038 216.595ZM365.221 211.035L364.667 215.21L371.317 213.983L365.221 211.035Z" />
      <path d="M364.212 254.815L362.668 259.208L346.776 253.618L347.865 250.551L359.235 254.508L349.339 246.267L349.973 244.486L362.836 244.278L351.417 240.261L352.496 237.194L368.388 242.784L366.834 247.187L355.049 247.286L364.212 254.815Z" />
      <path d="M344.847 257.615L360.076 264.778L355.306 274.889L352.733 273.682L356.098 266.519L352.723 264.877L349.656 271.406L347.073 270.199L350.151 263.669L346.034 261.691L342.64 268.893L340.067 267.686L344.847 257.615Z" />
      <path d="M338.029 271.515L352.417 280.261L349.537 284.991C346.945 289.245 342.413 290.313 338.069 287.682C333.725 285.05 332.567 280.489 335.159 276.235L338.029 271.515ZM338.771 275.77L337.584 277.748C336.03 280.291 336.941 283.131 339.82 284.882C342.7 286.633 345.559 286.089 347.113 283.536L348.29 281.558L338.771 275.77Z" />
      <path d="M314.795 310.566C312.568 308.587 312.667 305.837 315.052 303.205L319.722 298.05L332.2 309.398L327.797 314.256C325.689 316.581 323.364 316.947 321.484 315.246C320.885 314.8 320.438 314.182 320.203 313.474C319.967 312.766 319.955 312.003 320.168 311.288C319.328 311.813 318.332 312.028 317.351 311.896C316.37 311.764 315.465 311.294 314.795 310.566V310.566ZM317.417 304.828C316.269 306.094 316.259 307.43 317.417 308.468C318.575 309.507 319.97 309.309 321.187 307.974L323.285 305.649L319.683 302.364L317.417 304.828ZM327.906 309.854L325.076 307.281L323.097 309.487C322.877 309.67 322.702 309.899 322.584 310.159C322.465 310.418 322.407 310.701 322.412 310.986C322.418 311.271 322.488 311.551 322.617 311.806C322.745 312.06 322.93 312.282 323.156 312.456C323.997 313.217 325.026 313.069 325.976 312.03L327.906 309.854Z" />
      <path d="M308.442 310.111L319.821 322.527L317.417 324.734L307.957 314.405L302.475 319.441L300.545 317.313L308.442 310.111Z" />
      <path d="M288.81 326.445L292.768 329.196L297.587 325.575L296.053 321.034L298.804 318.966L304.286 335.914L300.852 338.496L286.039 328.513L288.81 326.445ZM298.517 328.375L295.153 330.908L300.704 334.796L298.517 328.375Z" />
      <path d="M283.071 350.062L280.27 351.734L271.651 337.269L274.422 335.607L286.534 341.88L280.597 331.927L283.387 330.264L291.976 344.729L289.117 346.431L277.133 340.138L283.071 350.062Z" />
      <path d="M258.59 343.502L266.179 348.271L266.931 345.56L264.685 340.692L267.654 339.327L274.699 354.613L271.731 355.978L268.762 349.636L266.377 358.422L262.845 360.054L265.229 351.486L254.839 345.204L258.59 343.502Z" />
      <path d="M121.164 341.92L115.227 354.584L119.63 356.651L118.423 359.224L106.657 353.693L107.874 351.121L112.278 353.198L118.215 340.534L121.164 341.92Z" />
      <path d="M101.314 331.164L100.235 335.834L105.401 338.941L109.032 335.815L112.001 337.576L98.3256 348.973L94.6445 346.757L98.3652 329.344L101.314 331.164ZM103.164 340.89L99.5625 338.713L98.0287 345.263L103.164 340.89Z" />
      <path d="M84.1753 318.63L72.9639 331.165L64.6123 323.735L66.5122 321.608L72.4494 326.871L74.9529 324.071L69.5303 319.292L71.4302 317.165L76.8132 321.964L79.8412 318.57L73.904 313.267L75.794 311.15L84.1753 318.63Z" />
      <path d="M53.272 311.931L51.0752 309.527L63.4938 298.149L65.6806 300.494L61.9996 313.623L70.5392 305.797L72.736 308.192L60.3174 319.619L58.0712 317.165L61.8017 304.115L53.272 311.931Z" />
      <path d="M52.1242 282.952L37.7859 291.768L34.3127 286.138C32.5216 283.229 33.1549 280.519 35.8069 278.886C36.5304 278.364 37.393 278.069 38.2847 278.039C39.1765 278.009 40.0569 278.246 40.8139 278.718C40.5467 275.334 44.772 273.959 45.2866 272.059L45.3855 272L47.0776 274.77C46.1771 277.521 41.3086 278.164 43.6142 281.904L44.7225 283.675L50.4222 280.172L52.1242 282.952ZM42.2982 285.178L40.5665 282.369C40.4363 282.1 40.2472 281.865 40.0132 281.68C39.7791 281.495 39.5063 281.365 39.2151 281.301C38.924 281.236 38.6219 281.238 38.3316 281.306C38.0412 281.375 37.7701 281.508 37.5385 281.696C37.2712 281.835 37.037 282.03 36.8521 282.267C36.6672 282.505 36.5359 282.78 36.4673 283.073C36.3987 283.366 36.3943 283.671 36.4546 283.966C36.5148 284.261 36.6381 284.54 36.8162 284.783L38.4984 287.513L42.2982 285.178Z" />
      <path d="M38.835 258.802L35.3716 262.116L37.9939 267.548L42.7535 266.925L44.2477 270.022L26.545 272.109L24.6748 268.241L37.3408 255.705L38.835 258.802ZM35.055 267.954L33.2243 264.164L28.3361 268.834L35.055 267.954Z" />
      <path d="M16.0267 246.267L14.5127 241.864L30.4243 236.373L31.4831 239.45L20.0837 243.407L32.9476 243.714L33.5611 245.495L23.5867 253.677L35.0256 249.719L36.0844 252.796L20.1728 258.287L18.6489 253.885L27.8219 246.484L16.0267 246.267Z" />
      <path d="M29.3946 232.02L12.9783 235.769L12.2461 232.603L25.8917 229.487L24.2392 222.225L27.0099 221.592L29.3946 232.02Z" />
      <path d="M4.2707 212.697C3.09316 212.845 2.1828 212.321 2.05416 211.322C1.92552 210.322 2.68746 209.59 3.865 209.442C5.04254 209.294 5.9529 209.848 6.07165 210.817C6.19039 211.787 5.46803 212.549 4.2707 212.697ZM4.83474 217.189C4.5992 217.253 4.3529 217.268 4.11129 217.233C3.86969 217.197 3.638 217.112 3.43079 216.983C3.22357 216.854 3.0453 216.684 2.90716 216.482C2.76902 216.281 2.67401 216.053 2.62809 215.814C2.49945 214.824 3.25149 214.082 4.42903 213.934C5.60657 213.785 6.51694 214.339 6.64557 215.309C6.77421 216.278 6.00238 217.04 4.83474 217.189V217.189ZM17.8767 219.553C12.7807 220.196 8.85222 217.456 8.2684 212.806C7.68458 208.156 10.8214 204.663 15.9274 204.03C21.0333 203.397 25.13 206.058 25.7039 210.619C26.2778 215.18 23.1212 218.9 17.8767 219.553ZM16.2242 207.315C12.7213 207.75 10.6037 209.877 10.9302 212.479C11.2568 215.081 13.8988 216.714 17.3523 216.279C21.1026 215.804 23.3785 213.637 23.0322 210.956C22.6858 208.274 20.0042 206.84 16.2539 207.315H16.2242Z" />
      <path d="M19.0645 199.578L19.0051 196.234C19.4237 196.229 19.8365 196.136 20.2164 195.96C20.5963 195.784 20.9347 195.53 21.2095 195.214C21.4842 194.898 21.689 194.528 21.8105 194.127C21.932 193.727 21.9674 193.305 21.9143 192.89C21.9143 191.277 20.9248 190.337 19.678 190.367C17.788 190.367 17.3922 192.138 16.8875 194.067C16.2542 196.442 15.3933 199.143 11.6133 199.222C8.79315 199.301 6.97241 197.016 6.90315 193.484C6.83388 189.951 8.79315 187.785 11.8508 187.636L11.9201 190.862C11.5779 190.852 11.2376 190.915 10.9217 191.046C10.6057 191.178 10.3213 191.375 10.0874 191.625C9.85345 191.875 9.6753 192.171 9.56477 192.495C9.45423 192.819 9.41383 193.163 9.44624 193.503C9.44624 195.007 10.2873 195.947 11.4946 195.898C13.1372 195.848 13.6319 194.582 14.0574 193.028C14.76 190.506 15.2548 187.152 19.3118 187.092C22.3596 187.033 24.3881 189.289 24.4574 192.791C24.5464 196.828 22.4091 199.509 19.0645 199.578Z" />
      <path d="M24.981 171.875L20.3203 172.984L19.8552 179.039L24.2685 180.899L23.9914 184.322L7.6543 177.179L7.98084 172.865L25.2382 168.462L24.981 171.875ZM17.0647 177.861L17.4012 173.666L10.8109 175.229L17.0647 177.861Z" />
      <path d="M15.898 134.101L17.224 129.629L33.3731 134.398L32.4529 137.514L20.8457 134.101L31.1467 141.818L30.6123 143.629L17.7485 144.499L29.3556 147.933L28.4354 151.099L12.2764 146.33L13.6023 141.858L25.3579 141.155L15.898 134.101Z" />
      <path d="M35.015 130.322L19.4893 123.802L23.8135 113.502L26.4358 114.601L23.3682 121.902L26.8316 123.357L29.6319 116.708L32.2542 117.806L29.4538 124.386L33.6494 126.157L36.7368 118.805L39.359 119.914L35.015 130.322Z" />
      <path d="M41.1506 116.104L26.3076 108.1L28.9398 103.242C31.3146 98.8593 35.7873 97.5533 40.26 99.9674C44.7326 102.381 46.1279 106.893 43.753 111.246L41.1506 116.104ZM40.161 111.899L41.2396 109.921C42.6645 107.299 41.6057 104.509 38.647 102.906C35.6884 101.303 32.8484 101.996 31.4334 104.617L30.3548 106.596L40.161 111.899Z" />
      <path d="M62.4057 75.9063C64.7311 77.8059 64.7805 80.5168 62.5343 83.2673L58.1309 88.6594L45.0493 77.9939L49.1954 72.9382C51.1745 70.5043 53.49 69.9701 55.4493 71.6025C56.0684 72.019 56.5459 72.6142 56.8181 73.3089C57.0904 74.0035 57.1445 74.7646 56.9731 75.4908C57.7821 74.908 58.7712 74.6301 59.7653 74.7062C60.7595 74.7822 61.6947 75.2073 62.4057 75.9063ZM49.3439 77.2914L52.3125 79.7055L54.1926 77.4003C54.4032 77.2065 54.5672 76.9676 54.6725 76.7016C54.7778 76.4355 54.8217 76.149 54.8007 75.8636C54.7798 75.5782 54.6947 75.3013 54.5517 75.0534C54.4086 74.8055 54.2115 74.5932 53.9749 74.4322C53.0942 73.7099 52.0849 73.9078 51.1943 75.006L49.3439 77.2914ZM60.0506 81.7436C61.1292 80.4278 61.0401 79.0822 59.8725 78.1126C58.7048 77.143 57.2799 77.4003 56.1419 78.7953L54.1629 81.2292L57.9528 84.316L60.0506 81.7436Z" />
      <path d="M68.7383 76.0149L56.7354 64.2017L59.0113 61.8767L68.9857 71.7012L74.2104 66.3981L76.1895 68.3769L68.7383 76.0149Z" />
      <path d="M87.47 58.7111L83.4228 56.1189L78.7918 60.0764L80.5631 64.5286L77.921 66.7349L71.5781 50.0541L74.8733 47.2937L90.112 56.5048L87.47 58.7111ZM77.6835 57.2864L80.9094 54.5854L75.1701 50.9841L77.6835 57.2864Z" />
      <path d="M92.0915 34.8171L94.7929 33.0066L104.144 47.0062L101.452 48.797L89.0141 43.1675L95.446 52.7941L92.7446 54.5947L83.3936 40.605L86.1543 38.7549L98.474 44.424L92.0915 34.8171Z" />
      <path d="M116.771 40.1303L108.914 35.7573L108.29 38.488L110.833 43.2568L107.924 44.7606L100.107 29.8507L102.977 28.337L106.222 34.5206L108.201 25.6162L111.645 23.8057L109.666 32.4825L120.363 38.2208L116.771 40.1303Z" />
    </svg>
  );
};

export default Ramlosa;
