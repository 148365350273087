const SnakkMedLIG: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <>
      <div className="">
        <svg
          width="313"
          height="313"
          viewBox="0 0 313 313"
          fill={color}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m152.34,8.31l2.35.08c-.01,1.36.92,2.13,2.23,2.17,1.14.04,1.83-.58,1.86-1.47.05-1.33-1.16-1.67-2.49-2.1-1.65-.54-3.51-1.24-3.42-3.9.05-1.98,1.73-3.17,4.2-3.09,2.38.08,3.93,1.54,3.92,3.71l-2.27-.08c0-1.17-.72-1.8-1.76-1.84-1.05-.04-1.75.5-1.76,1.35-.02,1.15.84,1.55,1.92,1.91,1.74.59,4.08,1.07,3.98,3.92-.07,2.14-1.74,3.48-4.2,3.39-2.85-.1-4.65-1.7-4.57-4.05Z" />
          <path d="m178.36,2.49l2.26.36-1.87,11.69-2.24-.36-3.7-8.84-1.29,8.04-2.26-.36,1.87-11.69,2.31.37,3.64,8.81,1.29-8.02Z" />
          <path d="m194.75,18.73l-.08-3.37-4.06-1.21-1.93,2.76-2.32-.69,7.3-10.18,2.9.86.51,12.51-2.32-.69Zm-2.96-6.3l2.84.85-.12-4.76-2.72,3.91Z" />
          <path d="m210.77,25.03l-1-6.22-1.78.85-1.53,3.45-2.09-.93,4.81-10.82,2.09.93-2,4.49,5.75-2.82,2.5,1.11-5.64,2.71,1.5,8.4-2.62-1.16Z" />
          <path d="m226.26,33.48l-.21-6.3-1.87.62-1.96,3.23-1.96-1.18,6.13-10.13,1.96,1.18-2.54,4.2,6.06-2.07,2.34,1.42-5.93,1.98.43,8.53-2.45-1.48Z" />
          <path d="m265.83,51.87l2.21,2.42-8.75,7.97-1.54-1.69,6.27-5.71-8.39,3.38-.89-.98,4.17-8.06-6.29,5.73-1.54-1.69,8.75-7.97,2.21,2.42-3.86,7.33,7.67-3.15Z" />
          <path d="m264.55,69.13l9.73-6.74,4.47,6.46-1.64,1.14-3.17-4.58-2.17,1.5,2.89,4.17-1.64,1.14-2.89-4.17-2.63,1.82,3.19,4.6-1.64,1.14-4.49-6.48Z" />
          <path d="m273.5,82.89l10.49-5.5,1.81,3.44c1.63,3.1.63,6.23-2.53,7.89-3.18,1.66-6.33.72-7.96-2.39l-1.81-3.45Zm2.83,1.1l.74,1.42c.97,1.86,3.02,2.34,5.11,1.24,2.07-1.08,2.8-3.02,1.83-4.88l-.74-1.42-6.95,3.64Z" />
          <path d="m287.8,119.25l11.58-2.46.48,2.24-9.63,2.05,1.09,5.12-1.96.42-1.56-7.36Z" />
          <path d="m291.31,142.45l3.09-1.32-.38-4.22-3.28-.77-.21-2.41,12.15,3.01.27,3.01-11.43,5.1-.21-2.41Zm4.75-5.08l.26,2.95,4.38-1.87-4.64-1.08Z" />
          <path d="m291.65,152.67l11.83.42-.16,4.65c-.08,2.4-1.51,3.74-3.7,3.66-1.46-.05-2.49-.71-2.99-1.85-1.16,2.09-4.14,1.26-5.22,2.15h-.08s.08-2.27.08-2.27c1.6-1.26,4.7.26,4.81-2.82l.05-1.49-4.7-.17.08-2.29Zm6.62,2.52l-.08,2.32c-.03.94.55,1.54,1.51,1.57.96.03,1.59-.55,1.62-1.53l.08-2.25-3.13-.11Z" />
          <path d="m294.17,169.92l-.36,2.32c-1.35-.18-2.22.66-2.43,1.95-.18,1.12.35,1.89,1.24,2.02,1.31.21,1.8-.95,2.39-2.22.73-1.57,1.66-3.34,4.28-2.93,1.96.29,2.94,2.1,2.56,4.55-.37,2.36-2.01,3.72-4.15,3.45l.35-2.24c1.16.13,1.88-.5,2.04-1.53.16-1.04-.29-1.79-1.13-1.91-1.14-.16-1.64.65-2.12,1.68-.79,1.66-1.55,3.92-4.37,3.48-2.12-.33-3.24-2.14-2.86-4.58.44-2.81,2.24-4.41,4.57-4.05Z" />
          <path d="m291.32,186.92l1.84.5-1.38,5.11-1.84-.5,1.38-5.11Z" />
          <path d="m283.44,199.08l11.11,4.09-.79,2.15-11.11-4.09.79-2.15Z" />
          <path d="m278.08,211.98l12.31,1.78-1.06,2.16-9.83-1.55,7.26,6.79-1.05,2.16-8.96-8.63,1.33-2.72Z" />
          <path d="m271.12,224.89l9.92,6.46-4.29,6.59-1.68-1.09,3.04-4.67-2.21-1.44-2.77,4.25-1.68-1.09,2.77-4.25-2.68-1.75-3.05,4.7-1.68-1.09,4.3-6.61Z" />
          <path d="m251.69,258.26c2.38,2.72,2.32,6.09-.17,8.25-2.43,2.12-5.75,1.7-8.13-1.02-2.43-2.78-2.42-6.16.01-8.29,2.48-2.17,5.85-1.73,8.28,1.05Zm-6.49,5.77c1.63,1.87,3.7,2.27,5.09,1.06,1.43-1.25,1.33-3.37-.3-5.24-1.75-2-3.9-2.48-5.34-1.23-1.39,1.21-1.2,3.4.55,5.4Z" />
          <path d="m231.79,280.83l-2.75,1.79-6.45-9.94,1.92-1.25,4.62,7.12-1.97-8.83,1.12-.72,7.28,5.43-4.64-7.14,1.92-1.25,6.45,9.94-2.75,1.79-6.61-5.01,1.87,8.08Z" />
          <path d="m197.31,288.44l-2.18.66c-.43-1.42-1.12-2.05-2.07-1.77-.98.3-1.21,1.27-.74,2.8l2.22,7.37-2.18.66-2.25-7.46c-.77-2.56.07-4.47,2.4-5.17,2.3-.69,4.03.4,4.79,2.92Z" />
          <path d="m181.93,294.75c.61,3.57-1.19,6.41-4.44,6.96-3.19.55-5.8-1.54-6.41-5.11-.62-3.64,1.14-6.53,4.33-7.07,3.25-.56,5.9,1.57,6.53,5.22Zm-8.55,1.54c.42,2.45,1.98,3.87,3.79,3.56,1.88-.32,2.89-2.18,2.47-4.63-.45-2.62-2.04-4.15-3.92-3.83-1.81.31-2.79,2.28-2.34,4.9Z" />
          <path d="m153.07,295.2c-.09-2.11,1.32-3.4,3.81-3.5l4.89-.2.49,11.83-4.61.19c-2.21.09-3.54-.9-3.62-2.67-.05-1.25.42-2.16,1.33-2.62-1.41-.34-2.24-1.41-2.3-3.03Zm4.17-1.52c-1.2.05-1.88.7-1.83,1.79.04,1.09.84,1.7,2.1,1.64l2.21-.09-.14-3.44-2.33.1Zm2.66,7.74l-.11-2.69-2.09.09c-.96.04-1.48.59-1.44,1.49.03.8.61,1.24,1.6,1.2l2.05-.08Z" />
          <path d="m135.53,294.31c.17-2.11,1.72-3.22,4.2-3.02l4.88.39-.94,11.8-4.59-.36c-2.2-.17-3.41-1.31-3.27-3.08.1-1.24.68-2.1,1.64-2.44-1.36-.51-2.05-1.67-1.92-3.28Zm4.32-1c-1.2-.1-1.95.47-2.03,1.55-.09,1.08.63,1.78,1.89,1.88l2.2.17.27-3.43-2.33-.18Zm1.71,8l.21-2.68-2.09-.17c-.96-.08-1.54.41-1.61,1.3-.06.8.46,1.3,1.45,1.38l2.04.16Z" />
          <path d="m106.21,288.14l-2.21-.81c.44-1.29-.21-2.31-1.44-2.76-1.07-.39-1.92-.02-2.23.82-.46,1.25.58,1.95,1.71,2.78,1.4,1.02,2.95,2.28,2.03,4.77-.67,1.87-2.63,2.48-4.96,1.62-2.24-.82-3.25-2.7-2.57-4.75l2.13.78c-.36,1.11.12,1.94,1.1,2.3.99.36,1.82.07,2.09-.73.38-1.09-.32-1.74-1.23-2.41-1.47-1.1-3.54-2.29-2.55-4.96.74-2.01,2.74-2.76,5.05-1.91,2.67.98,3.88,3.07,3.07,5.27Z" />
          <path d="m89.14,283.83c-1.67,3.2-4.83,4.37-7.75,2.85-2.86-1.5-3.68-4.74-2.01-7.94,1.71-3.27,4.86-4.5,7.72-3,2.92,1.52,3.74,4.82,2.03,8.1Zm-7.73-3.95c-1.15,2.2-.77,4.27.86,5.12,1.69.88,3.62.01,4.77-2.18,1.23-2.35.89-4.53-.8-5.41-1.63-.85-3.6.12-4.83,2.48Z" />
          <path d="m60.87,273.49l-2.66-1.91,6.91-9.61,1.86,1.34-4.95,6.88,7.51-5.04,1.08.77-2.41,8.75,4.97-6.91,1.86,1.34-6.91,9.61-2.66-1.91,2.26-7.97-6.85,4.67Z" />
          <path d="m30,242.58l-1.4-1.81,9.37-7.24,1.39,1.8-3.36,8.98,6.45-4.98,1.4,1.81-9.37,7.24-1.43-1.85,3.38-8.92-6.43,4.97Z" />
          <path d="m31.63,223.74l-3.79,2.23-4.47,7.07-1.24-2.11,3.24-4.84-5.8.5-1.23-2.1,8.35-.49,3.79-2.23,1.16,1.97Z" />
          <path d="m20.17,213.63l-6.01,2.6-.9-2.09,6-2.59c2.23-.96,3.03-2.15,2.42-3.56-.6-1.4-2.01-1.62-4.24-.66l-6,2.59-.91-2.1,6.01-2.6c3.35-1.45,5.72-.73,6.87,2.02,1.15,2.75.04,4.97-3.24,6.39Z" />
          <path d="m17.78,192.51l-9.44,2.78.97,3.29-1.92.56-2.58-8.77,1.92-.56.97,3.29,9.44-2.78.65,2.2Z" />
          <path d="m14.42,179.37l-11.69,1.92-.63-3.84c-.57-3.46,1.36-6.11,4.89-6.69,3.54-.58,6.23,1.31,6.8,4.77l.63,3.84Zm-2.35-1.93l-.26-1.58c-.34-2.07-2.13-3.17-4.46-2.79-2.31.38-3.61,1.99-3.28,4.06l.26,1.58,7.74-1.27Z" />
          <path d="m11.78,153.62l-3.16,1.15.15,4.24,3.24.95.08,2.42L.1,158.69l-.1-3.02,11.69-4.47.08,2.42Zm-5.02,4.82l-.1-2.96-4.47,1.63,4.58,1.33Z" />
          <path d="m.87,135l.21-2.28,11.79,1.11-.21,2.26-8.58,4.27,8.11.76-.21,2.28-11.79-1.11.22-2.33,8.56-4.21-8.09-.76Z" />
          <path d="m4.34,115.5l.51-2.23,11.54,2.64-.51,2.21-9.07,3.11,7.94,1.82-.51,2.23-11.54-2.64.52-2.28,9.04-3.05-7.92-1.81Z" />
          <path d="m18.91,107.6l-11.12-4.05,2.69-7.38,1.88.68-1.91,5.23,2.48.9,1.74-4.76,1.88.69-1.74,4.76,3.01,1.1,1.92-5.26,1.88.68-2.7,7.41Z" />
          <path d="m26.18,90.04l-8.78-4.43-1.54,3.06-1.79-.9,4.11-8.16,1.79.9-1.54,3.06,8.78,4.43-1.03,2.04Z" />
          <path d="m28.79,75.61l-6.78-4.07,1.21-1.92,6.6,4.36-1.03,1.64Zm3.94,2.64l-2.26-1.43,1.18-1.87,2.26,1.43-1.18,1.87Z" />
        </svg>
      </div>
    </>
  );
};

export default SnakkMedLIG;
