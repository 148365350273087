import classNames from 'classnames';
import { Image, ValImage } from '@valbuild/next';

interface BlankImageProps {
  alt: string;
  className?: string;
  height?: number;
  priority?: boolean;
  removeEffect?: boolean;
  src: Image;
  width: number;
}

const BlankImage = ({
  alt,
  className,
  height,
  priority = false,
  src,
  width,
  removeEffect = false,
}: BlankImageProps) => {
  const shouldCover = height !== undefined;

  const effectClass = classNames({
    'opacity-[0.85] mix-blend-multiply': removeEffect === false,
  });
  return (
    <div
      style={{
        width: width,
        aspectRatio: src?.metadata
          ? src?.metadata?.width / src?.metadata?.height
          : undefined,
        height: '100%',
      }}
      className={`relative ${
        !removeEffect && `bg-[#FFFCB6]`
      } leading-[0] ${className} max-w-full`}
    >
      {src && (
        <ValImage
          src={src}
          alt={alt}
          className={`${
            shouldCover ? 'object-cover' : 'object-contain'
          } ${effectClass}`}
          priority={priority}
          fill
          sizes="1200px"
        />
      )}
    </div>
  );
};

export default BlankImage;
