import { useDarkSideContext } from '../../Context/DarksideContext';
import { Switch } from '../SVGs';

interface DarksideToggleProps {
  forceLight?: boolean;
}

const DarksideToggle = ({ forceLight }: DarksideToggleProps) => {
  const { darksideMode, setDarksideMode } = useDarkSideContext();

  const switchClasses = () => {
    if (darksideMode === undefined) {
      return '';
    } else {
      return darksideMode
        ? 'animate-spinShift rotate-180 translate-x-[20px]'
        : 'animate-reverseSpinShift';
    }
  };

  const lineClasses = !forceLight ? 'bg-blankBlack' : 'bg-[#FFFCB6]';

  return (
    <div
      className="relative flex items-center h-[32px] w-[48px] cursor-pointer pointer-events-auto"
      onClick={() => setDarksideMode(!darksideMode)}
    >
      <div className={`z-10 absolute origin-center ${switchClasses()}`}>
        <Switch className="overflow-visible" />
      </div>
      <div
        className={`h-[1px] w-[46px] translate-y-[-30%] mx-auto ${lineClasses}`}
      />
    </div>
  );
};

export default DarksideToggle;
