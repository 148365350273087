const Switch: React.FC<{
  borderColor?: string;
  className?: string;
}> = ({ className = '', borderColor = 'var(--dark)' }) => {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3230_11877)">
        <path
          d="M0 15L14.4246 0.575378L28.8492 15L14.4246 29.4246L0 15Z"
          fill="#FFFCB6"
        />
        <path
          opacity="0.9"
          d="M23.7236 15H5.33886L14.5312 5.80761L23.7236 15Z"
          fill="#272D2A"
        />
      </g>
      <path
        d="M0 15L-0.707107 14.2929L-1.41421 15L-0.707107 15.7071L0 15ZM14.4246 0.575378L15.1317 -0.131729L14.4246 -0.838835L13.7175 -0.131729L14.4246 0.575378ZM28.8492 15L29.5564 15.7071L30.2635 15L29.5564 14.2929L28.8492 15ZM14.4246 29.4246L13.7175 30.1317L14.4246 30.8388L15.1317 30.1317L14.4246 29.4246ZM0.707107 15.7071L15.1317 1.28249L13.7175 -0.131729L-0.707107 14.2929L0.707107 15.7071ZM13.7175 1.28249L28.1421 15.7071L29.5564 14.2929L15.1317 -0.131729L13.7175 1.28249ZM28.1421 14.2929L13.7175 28.7175L15.1317 30.1317L29.5564 15.7071L28.1421 14.2929ZM15.1317 28.7175L0.707107 14.2929L-0.707107 15.7071L13.7175 30.1317L15.1317 28.7175Z"
        fill={borderColor}
      />
      <defs>
        <clipPath id="clip0_3230_11877">
          <path
            d="M0 15L14.4246 0.575378L28.8492 15L14.4246 29.4246L0 15Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Switch;
