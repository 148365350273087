const FlatWhite: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M253.469 33.0499L258.68 19.8999L254.109 18.0899L255.139 15.3799L267.341 20.2199L266.281 22.8999L261.71 21.0799L256.469 34.2599L253.469 33.0499Z" />
      <path d="M274.202 42.6399L274.982 37.8599L269.562 35.0699L266.111 38.4699L263.021 36.8799L276.023 24.4299L279.893 26.4299L277.293 44.2399L274.202 42.6399ZM271.682 32.9599L275.472 34.9599L276.563 28.1999L271.682 32.9599Z" />
      <path d="M292.314 54.1099L302.786 40.6899L311.688 47.6899L309.917 49.9499L303.606 45.0299L301.276 48.0299L307.027 52.5199L305.256 54.7799L299.476 50.2399L296.645 53.8699L302.986 58.8199L301.216 61.0899L292.314 54.1099Z" />
      <path d="M323.88 58.82L326.25 61.1L314.478 73.38L312.118 71.13L314.968 57.65L306.877 66.1L304.477 63.83L316.248 51.54L318.669 53.86L315.778 67.26L323.88 58.82Z" />
      <path d="M326.95 88.0201L340.782 78.1001L347.263 87.1001L344.933 88.7801L340.362 82.4201L337.232 84.6801L341.372 90.4501L339.052 92.1101L334.911 86.3801L328.91 90.7101L326.95 88.0201Z" />
      <path d="M335.481 100.19L350.124 91.5L351.754 94.38L339.582 101.55L343.423 108.02L340.952 109.49L335.481 100.19Z" />
      <path d="M347.533 122.96L351.064 119.65L348.464 114.13L343.653 114.72L342.183 111.57L360.085 109.63L361.936 113.56L349.014 126.1L347.533 122.96ZM351.444 113.75L353.254 117.6L358.255 112.91L351.444 113.75Z" />
      <path d="M351.424 132.75L364.756 128.01L363.106 123.38L365.806 122.38L370.217 134.75L367.506 135.75L365.856 131.11L352.484 135.85L351.424 132.75Z" />
      <path d="M359.226 161L375.488 154.55L375.968 157.97L363.886 162.44L376.748 163.53L377.228 167.01L365.167 171.76L378.019 172.76L378.499 176.2L361.096 174.38L360.556 170.53L371.778 166.01L359.776 164.88L359.226 161Z" />
      <path d="M361.676 182.02L378.678 181.79V185.08L372.097 185.17L372.187 191.95L378.768 191.87V195.16L361.766 195.38V192.09L369.337 191.99L369.247 185.21L361.676 185.31V182.02Z" />
      <path d="M361.535 199.97L378.538 201.06L378.328 204.34L361.325 203.26L361.535 199.97Z" />
      <path d="M360.486 211.63L374.488 213.51L375.148 208.63L377.998 209.01L376.248 222.01L373.398 221.63L374.058 216.75L360.056 214.87L360.486 211.63Z" />
      <path d="M358.765 223.09L375.358 226.89L372.837 237.89L370.027 237.25L371.817 229.45L368.116 228.6L366.486 235.72L363.686 235.08L365.316 227.97L360.835 226.97L359.035 234.82L356.225 234.17L358.765 223.09Z" />
      <path d="M360.796 270.85L358.865 275.15L343.333 268.2L344.673 265.2L355.805 270.2L346.483 261.07L347.264 259.33L360.266 260.18L349.094 255.18L350.444 252.18L365.977 259.13L364.046 263.43L352.174 262.55L360.796 270.85Z" />
      <path d="M341.123 272.11L355.805 280.73L350.084 290.47L347.604 289.01L351.664 282.11L348.384 280.19L344.693 286.48L342.213 285.02L345.913 278.73L341.913 276.4L337.862 283.38L335.382 281.93L341.123 272.11Z" />
      <path d="M333.081 285.47L346.823 295.47L343.523 299.98C340.522 304.04 335.882 304.73 331.741 301.71C327.6 298.69 326.81 294 329.741 289.94L333.081 285.47ZM333.461 289.82L332.101 291.67C330.331 294.1 331.001 297.04 333.741 299.05C336.482 301.06 339.422 300.75 341.202 298.32L342.563 296.46L333.461 289.82Z" />
      <path d="M306.297 322.83C304.226 320.6 304.556 317.88 307.187 315.44L312.338 310.65L323.929 323.1L319.079 327.61C316.758 329.77 314.378 329.95 312.638 328.08C312.073 327.58 311.674 326.919 311.496 326.186C311.317 325.454 311.366 324.684 311.637 323.98C310.753 324.442 309.735 324.581 308.759 324.37C307.783 324.16 306.912 323.615 306.297 322.83V322.83ZM309.477 317.28C308.217 318.45 308.087 319.8 309.147 320.94C310.207 322.08 311.647 322.01 312.978 320.77L315.308 318.61L311.937 314.99L309.477 317.28ZM319.609 323.28L316.928 320.38L314.728 322.43C314.491 322.595 314.294 322.812 314.152 323.063C314.01 323.315 313.927 323.596 313.909 323.884C313.891 324.172 313.938 324.461 314.047 324.729C314.156 324.997 314.323 325.236 314.538 325.43C315.318 326.27 316.358 326.21 317.408 325.24L319.609 323.28Z" />
      <path d="M299.946 321.81L310.347 335.28L307.737 337.28L299.096 326.09L293.145 330.68L291.395 328.38L299.946 321.81Z" />
      <path d="M278.772 336.6L282.493 339.7L287.654 336.46L286.493 331.76L289.434 329.91L293.525 347.44L289.844 349.76L275.842 338.45L278.772 336.6ZM288.394 339.37L284.793 341.63L290.044 346.01L288.394 339.37Z" />
      <path d="M270.981 359.89L268.031 361.38L260.56 346.09L263.5 344.66L275.162 351.98L270.021 341.38L273.022 339.94L280.473 355.26L277.472 356.74L265.86 349.38L270.981 359.89Z" />
      <path d="M246.928 351.24L254.179 356.68L255.179 354.01L253.319 348.91L256.409 347.79L262.25 363.79L259.16 364.92L256.739 358.28L253.599 366.94L249.908 368.29L253.029 359.85L243.077 352.66L246.928 351.24Z" />
      <path d="M135.27 350.25L130.35 363.51L134.97 365.22L133.97 367.92L121.658 363.38L122.658 360.68L127.279 362.39L132.19 349.13L135.27 350.25Z" />
      <path d="M114.267 341.14L113.607 345.94L119.087 348.6L122.468 345.12L125.588 346.64L112.876 359.38L108.966 357.48L111.146 339.62L114.267 341.14ZM117.017 350.76L113.186 348.9L112.246 355.68L117.017 350.76Z" />
      <path d="M95.9142 330.13L85.7626 343.79L76.7012 337.06L78.4414 334.75L84.8724 339.52L87.1328 336.52L81.2719 332.17L82.9922 329.86L88.8431 334.21L91.5935 330.52L85.1225 325.72L86.8128 323.38L95.9142 330.13Z" />
      <path d="M64.2087 326.16L61.7783 323.94L73.2501 311.38L75.6605 313.58L73.1401 327.11L81.0313 318.47L83.4417 320.68L71.9899 333.26L69.5095 330.99L72.0799 317.52L64.2087 326.16Z" />
      <path d="M60.4389 297.05L46.8668 307.3L40.1758 298.43L42.4361 296.7L47.1469 302.94L50.2373 300.62L45.9567 294.94L48.227 293.23L52.5077 298.9L58.4386 294.43L60.4389 297.05Z" />
      <path d="M51.6969 285.1L37.2647 294.1L35.5244 291.38L47.5263 283.87L43.5257 277.5L45.966 275.97L51.6969 285.1Z" />
      <path d="M39.0353 262.6L35.5947 266L38.3151 271.45L43.1059 270.75L44.6661 273.85L26.8133 276.22L24.873 272.38L37.485 259.55L39.0353 262.6ZM35.3547 271.9L33.4544 268.1L28.5736 272.9L35.3547 271.9Z" />
      <path d="M34.9345 252.93L21.7224 257.99L23.4827 262.58L20.8023 263.58L16.1016 251.38L18.792 250.38L20.5523 254.98L33.7543 249.92L34.9345 252.93Z" />
      <path d="M26.4329 224.84L10.3104 231.69L9.75027 228.28L21.7521 223.52L8.87013 222.74L8.30004 219.27L20.2419 214.27L7.35989 213.63L6.7998 210.2L24.2825 211.56L24.9126 215.39L13.8009 220.18L25.8028 221.03L26.4329 224.84Z" />
      <path d="M23.5027 203.9L6.50008 204.54L6.38006 201.25L12.9511 201L12.701 194.22L6.12002 194.47L6 191.18L23.0027 190.55L23.1227 193.83L15.5615 194.12L15.8115 200.9L23.3827 200.61L23.5027 203.9Z" />
      <path d="M23.2029 185.95L6.2002 185.28L6.33022 181.99L23.3329 182.66L23.2029 185.95Z" />
      <path d="M23.9527 174.28L9.89053 172.73L9.35045 177.63L6.5 177.31L7.93022 164.26L10.7907 164.57L10.2506 169.46L24.3128 171.01L23.9527 174.28Z" />
      <path d="M25.4328 162.77L8.74023 159.38L11.0006 148.31L13.821 148.89L12.2208 156.73L15.9414 157.49L17.4316 150.38L20.252 150.96L18.7918 158.1L23.3025 159.02L24.9128 151.13L27.7332 151.71L25.4328 162.77Z" />
      <path d="M22.2321 115.11L24.0623 110.76L39.7648 117.38L38.4946 120.38L27.2428 115.67L36.7443 124.56L36.0042 126.32L23.0022 125.77L34.2939 130.5L33.0237 133.5L17.3213 126.93L19.1416 122.58L31.0334 123.17L22.2321 115.11Z" />
      <path d="M41.8853 113.38L27.0029 105.1L32.4938 95.23L35.0042 96.63L31.1136 103.63L34.4341 105.47L37.9746 99.1L40.485 100.5L36.9445 106.87L40.9451 109.1L44.8557 102.1L47.4361 103.47L41.8853 113.38Z" />
      <path d="M49.6164 99.7999L35.6143 90.0999L38.8048 85.5099C41.6752 81.3799 46.3259 80.5699 50.5366 83.5099C54.7472 86.4499 55.6574 91.0999 52.7769 95.2399L49.6164 99.7999ZM49.1264 95.4599L50.4366 93.5799C52.1568 91.0999 51.4367 88.1799 48.6263 86.2399C45.8158 84.2999 42.9054 84.6799 41.1851 87.1499L39.8749 89.0399L49.1264 95.4599Z" />
      <path d="M75.4406 61.8301C77.561 64.0001 77.3009 66.7301 74.7305 69.2401L69.7297 74.1501L57.8379 61.9701L62.5786 57.3801C64.849 55.1601 67.2294 54.9301 69.0096 56.7501C69.5859 57.2378 69.9992 57.89 70.1941 58.6193C70.3889 59.3486 70.356 60.1201 70.0998 60.8301C70.9673 60.3491 71.9754 60.1859 72.9503 60.3684C73.9253 60.551 74.806 61.0679 75.4406 61.8301V61.8301ZM62.1586 61.7301L64.859 64.5001L67.0593 62.3801C67.2929 62.2098 67.4848 61.9887 67.6205 61.7335C67.7562 61.4783 67.8322 61.1957 67.8428 60.9068C67.8534 60.618 67.7983 60.3305 67.6816 60.066C67.565 59.8016 67.3898 59.5671 67.1693 59.3801C66.3692 58.5501 65.3291 58.6401 64.3089 59.6401L62.1586 61.7301ZM72.4402 67.4501C73.6704 66.2501 73.7704 64.9001 72.6802 63.7801C71.59 62.6601 70.1498 62.7801 68.8496 64.0401L66.5793 66.2601L70.0398 69.8001L72.4402 67.4501Z" />
      <path d="M81.8621 62.6799L71.1504 49.4599L73.7008 47.3799L82.6122 58.3799L88.4431 53.6399L90.2534 55.8699L81.8621 62.6799Z" />
      <path d="M102.655 47.38L98.8641 44.38L93.7833 47.75L95.0635 52.38L92.163 54.38L87.6523 36.92L91.2729 34.52L105.545 45.52L102.655 47.38ZM92.9732 44.86L96.5237 42.51L91.1629 38.25L92.9732 44.86Z" />
      <path d="M109.937 23.9099L112.857 22.3799L120.688 37.4999L117.788 38.9999L105.946 31.9999L111.327 42.3799L108.446 43.8699L100.615 28.7499L103.616 27.2099L115.358 34.2799L109.937 23.9099Z" />
      <path d="M134.18 31.99L126.809 26.73L125.879 29.38L127.879 34.44L124.818 35.64L118.607 19.79L121.678 18.59L124.248 25.16L127.179 16.43L130.839 15L127.929 23.5L138.03 30.5L134.18 31.99Z" />
    </svg>
  );
};

export default FlatWhite;
