const SnakkMedClara: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <>
      <div className="">
        <svg
          width="313"
          height="313"
          viewBox="0 0 313 313"
          fill={color}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m152.42,8.31l2.35.08c-.01,1.36.92,2.13,2.23,2.17,1.14.04,1.83-.58,1.86-1.47.05-1.33-1.16-1.67-2.49-2.1-1.65-.54-3.51-1.24-3.42-3.9.05-1.98,1.73-3.17,4.2-3.09,2.38.08,3.93,1.54,3.92,3.71l-2.27-.08c0-1.17-.72-1.8-1.76-1.84-1.05-.04-1.75.5-1.76,1.35-.02,1.15.84,1.55,1.92,1.91,1.74.59,4.08,1.07,3.98,3.92-.07,2.14-1.74,3.48-4.2,3.39-2.85-.1-4.65-1.7-4.57-4.05Z" />
          <path d="m178.44,2.49l2.26.36-1.87,11.69-2.24-.36-3.7-8.84-1.29,8.04-2.26-.36,1.87-11.69,2.31.37,3.64,8.81,1.29-8.02Z" />
          <path d="m194.83,18.73l-.08-3.37-4.06-1.21-1.93,2.76-2.32-.69,7.3-10.18,2.9.86.51,12.51-2.32-.69Zm-2.96-6.3l2.84.85-.12-4.76-2.72,3.91Z" />
          <path d="m210.84,25.03l-1-6.22-1.78.85-1.53,3.45-2.09-.93,4.81-10.82,2.09.93-2,4.49,5.75-2.82,2.5,1.11-5.64,2.71,1.5,8.4-2.62-1.16Z" />
          <path d="m226.33,33.48l-.21-6.3-1.87.62-1.96,3.23-1.96-1.18,6.13-10.13,1.96,1.18-2.54,4.2,6.06-2.07,2.34,1.42-5.93,1.98.43,8.53-2.45-1.48Z" />
          <path d="m265.91,51.87l2.21,2.42-8.75,7.97-1.54-1.69,6.27-5.71-8.39,3.38-.89-.98,4.17-8.06-6.29,5.73-1.54-1.69,8.75-7.97,2.21,2.42-3.86,7.33,7.67-3.15Z" />
          <path d="m264.62,69.13l9.73-6.74,4.47,6.46-1.64,1.14-3.17-4.58-2.17,1.5,2.89,4.17-1.64,1.14-2.89-4.17-2.63,1.82,3.19,4.6-1.64,1.14-4.49-6.48Z" />
          <path d="m273.58,82.89l10.49-5.5,1.81,3.44c1.63,3.1.63,6.23-2.53,7.89-3.18,1.66-6.33.72-7.96-2.39l-1.81-3.45Zm2.83,1.1l.74,1.42c.97,1.86,3.02,2.34,5.11,1.24,2.07-1.08,2.8-3.02,1.83-4.88l-.74-1.42-6.95,3.64Z" />
          <path d="m293.72,117.72c3.54-.72,6.44.98,7.09,4.2.5,2.49-.74,4.75-3.13,5.6l-.46-2.27c1.34-.47,1.98-1.67,1.73-2.98-.36-1.84-2.24-2.76-4.69-2.27-2.6.53-4.09,2.13-3.73,3.92.27,1.35,1.43,2.23,3,2.14l.46,2.27c-2.66.16-4.78-1.45-5.29-3.94-.65-3.22,1.4-5.93,5.02-6.67Z" />
          <path d="m290.97,137.41l11.8-.93.18,2.28-9.81.77.41,5.21-1.99.16-.59-7.49Z" />
          <path d="m291.42,160.89l3.24-.91.17-4.24-3.16-1.19.1-2.42,11.67,4.56-.12,3.02-12,3.58.1-2.42Zm5.37-4.42l-.12,2.96,4.59-1.29-4.46-1.67Z" />
          <path d="m290.42,171.07l11.67,1.95-.77,4.59c-.4,2.37-1.98,3.51-4.14,3.15-1.44-.24-2.37-1.03-2.73-2.22-1.42,1.92-4.27.71-5.45,1.46h-.08s.38-2.25.38-2.25c1.75-1.04,4.62.87,5.13-2.17l.25-1.47-4.64-.78.38-2.26Zm6.23,3.36l-.38,2.29c-.16.93.35,1.6,1.29,1.76.95.16,1.65-.34,1.81-1.3l.37-2.22-3.09-.52Z" />
          <path d="m284.83,195.34l3.36-.07,1.22-4.06-2.76-1.93.7-2.31,10.16,7.32-.87,2.9-12.51.48.7-2.31Zm6.3-2.95l-.85,2.83,4.76-.11-3.91-2.73Z" />
          <path d="m277.07,226.84c3.03,1.98,3.88,5.23,2.08,7.99-1.77,2.71-5.07,3.21-8.1,1.23-3.09-2.02-4.01-5.27-2.24-7.98,1.8-2.76,5.16-3.26,8.26-1.24Zm-4.68,7.32c2.08,1.35,4.18,1.18,5.18-.36,1.04-1.59.36-3.6-1.71-4.96-2.22-1.45-4.43-1.32-5.47.27-1.01,1.54-.23,3.6,2,5.05Z" />
          <path d="m264.08,253.97l-2.16,2.47-8.91-7.8,1.51-1.72,6.38,5.59-4.3-7.96.88-1,8.48,3.24-6.4-5.61,1.51-1.72,8.91,7.8-2.16,2.47-7.72-3.02,4,7.27Z" />
          <path d="m233.01,270.72l-1.91,1.23c-.81-1.25-1.64-1.66-2.48-1.13-.86.55-.81,1.55.06,2.9l4.17,6.47-1.91,1.23-4.22-6.55c-1.45-2.25-1.16-4.32.88-5.63,2.02-1.3,3.99-.72,5.41,1.48Z" />
          <path d="m219.96,281.01c1.57,3.26.62,6.49-2.35,7.92-2.91,1.4-6,.12-7.57-3.14-1.6-3.33-.7-6.59,2.21-7.99,2.97-1.43,6.11-.12,7.71,3.21Zm-7.79,3.84c1.08,2.24,2.97,3.17,4.62,2.37,1.72-.83,2.18-2.9,1.1-5.13-1.15-2.39-3.11-3.42-4.82-2.6-1.66.8-2.06,2.96-.9,5.35Z" />
          <path d="m192.3,289.4c-.66-2.01.33-3.63,2.7-4.42l4.65-1.54,3.71,11.25-4.38,1.45c-2.1.69-3.66.11-4.21-1.58-.39-1.18-.19-2.2.56-2.88-1.45.06-2.54-.75-3.05-2.28Zm3.59-2.6c-1.14.38-1.61,1.19-1.27,2.22.34,1.03,1.27,1.4,2.47,1l2.1-.69-1.08-3.27-2.22.73Zm4.68,6.71l-.84-2.55-1.99.66c-.91.3-1.26.97-.98,1.82.25.76.93,1.03,1.87.71l1.95-.64Z" />
          <path d="m175.22,293.31c-.41-2.07.78-3.57,3.23-4.05l4.8-.95,2.3,11.62-4.52.9c-2.17.43-3.64-.34-3.99-2.08-.24-1.22.09-2.2.92-2.79-1.44-.12-2.43-1.05-2.74-2.64Zm3.88-2.14c-1.18.23-1.75.98-1.54,2.05.21,1.07,1.09,1.55,2.33,1.3l2.17-.43-.67-3.38-2.29.46Zm3.82,7.24l-.52-2.64-2.06.41c-.94.19-1.37.81-1.2,1.69.16.79.79,1.13,1.77.94l2.01-.4Z" />
          <path d="m145.28,295.35l-2.35-.17c.07-1.36-.83-2.16-2.14-2.26-1.13-.08-1.85.5-1.92,1.4-.1,1.32,1.09,1.72,2.4,2.2,1.62.6,3.46,1.38,3.26,4.03-.13,1.98-1.85,3.1-4.33,2.92-2.38-.18-3.87-1.7-3.77-3.86l2.27.17c-.04,1.17.65,1.83,1.69,1.91,1.05.08,1.77-.43,1.81-1.28.07-1.15-.78-1.58-1.84-1.98-1.72-.66-4.03-1.23-3.82-4.07.16-2.14,1.88-3.41,4.33-3.22,2.84.21,4.58,1.88,4.4,4.23Z" />
          <path d="m127.72,295.91c-.73,3.54-3.44,5.53-6.67,4.87-3.17-.65-4.84-3.54-4.11-7.08.74-3.62,3.43-5.66,6.6-5.01,3.23.66,4.93,3.61,4.18,7.23Zm-8.53-1.67c-.5,2.43.44,4.32,2.24,4.69,1.87.38,3.49-.98,3.98-3.41.53-2.6-.39-4.6-2.26-4.99-1.8-.37-3.43,1.11-3.96,3.71Z" />
          <path d="m97.71,293.75l-3.09-1.11,4.01-11.14,2.15.77-2.87,7.98,5.84-6.92,1.25.45.08,9.08,2.88-8.01,2.15.78-4.01,11.14-3.09-1.11v-8.29s-5.32,6.38-5.32,6.38Z" />
          <path d="m59.55,272.43l-1.84-1.36,7.05-9.52,1.83,1.35-.78,9.56,4.85-6.55,1.84,1.36-7.05,9.52-1.88-1.39.82-9.5-4.84,6.53Z" />
          <path d="m55.98,253.86l-3.04,3.18-2.37,8.02-1.77-1.69,1.8-5.54-5.44,2.06-1.76-1.68,7.89-2.75,3.04-3.18,1.65,1.58Z" />
          <path d="m42.19,247.25l-5.07,4.14-1.44-1.76,5.06-4.13c1.88-1.54,2.33-2.89,1.36-4.08-.96-1.18-2.38-1.01-4.26.52l-5.05,4.13-1.45-1.77,5.07-4.14c2.82-2.31,5.3-2.26,7.16.06,1.86,2.33,1.39,4.77-1.37,7.02Z" />
          <path d="m34.11,227.6l-8.31,5.26,1.83,2.89-1.69,1.07-4.89-7.72,1.69-1.07,1.83,2.89,8.31-5.26,1.22,1.93Z" />
          <path d="m27.24,215.87l-10.7,5.06-1.66-3.52c-1.5-3.17-.37-6.25,2.86-7.77,3.24-1.53,6.35-.45,7.85,2.71l1.66,3.52Zm-2.79-1.21l-.68-1.45c-.9-1.89-2.92-2.46-5.06-1.45-2.11,1-2.93,2.91-2.03,4.8l.68,1.45,7.09-3.35Z" />
          <path d="m17.64,191.87l-2.73,1.97,1.3,4.04,3.37.02.74,2.3-12.52-.25-.93-2.88,10.02-7.5.74,2.3Zm-3.51,6.01l-.91-2.82-3.86,2.79,4.77.03Z" />
          <path d="m2.07,176.91l-.41-2.25,11.64-2.13.41,2.23-7.1,6.44,8.01-1.47.41,2.25-11.64,2.13-.42-2.3,7.09-6.37-7.99,1.46Z" />
          <path d="m.12,157.21l-.12-2.29,11.82-.6.12,2.27-7.88,5.46,8.13-.41.12,2.28-11.82.6-.12-2.33,7.86-5.4-8.12.41Z" />
          <path d="m11.97,145.62l-11.81-.85.56-7.84,2,.14-.4,5.55,2.63.19.36-5.06,2,.14-.36,5.06,3.19.23.4-5.59,2,.14-.56,7.87Z" />
          <path d="m14.13,126.76l-9.67-1.84-.64,3.36-1.96-.37,1.71-8.97,1.96.37-.64,3.36,9.67,1.84-.43,2.25Z" />
          <path d="m12.67,112.17l-7.63-2.05.64-2.18,7.54,2.37-.54,1.86Zm4.51,1.45l-2.56-.75.62-2.12,2.56.75-.62,2.12Z" />
        </svg>
      </div>
    </>
  );
};

export default SnakkMedClara;
