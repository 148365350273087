const MatchaLatte: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M228.834 26.69L232.015 12.91L227.244 11.8L227.894 9L240.686 12L240.046 14.8L235.245 13.65L232.065 27.43L228.834 26.69Z" />
      <path d="M250.809 33.05L250.859 28.21L245.078 26.27L242.177 30.14L238.887 29L249.888 14.74L254.009 16.13L254.119 34.13L250.809 33.05ZM246.868 23.86L250.869 25.22L250.939 18.38L246.868 23.86Z" />
      <path d="M270.432 41.6701L278.773 26.8301L288.624 32.3701L287.254 34.8801L280.253 31.0001L278.393 34.3101L284.754 37.8901L283.344 40.3901L276.983 36.8201L274.732 40.8201L281.733 44.7601L280.323 47.2701L270.432 41.6701Z" />
      <path d="M302.346 41.59L305.036 43.48L295.255 57.4L292.584 55.52L293.375 41.76L286.644 51.34L283.953 49.45L293.745 35.53L296.485 37.46L295.635 51.14L302.346 41.59Z" />
      <path d="M331.02 67.58L334.261 71L321.809 82.65L319.569 80.24L328.47 71.92L316.468 76.92L315.168 75.53L321.009 63.87L312.067 72.22L309.827 69.82L322.259 58.21L325.48 61.65L320.059 72.26L331.02 67.58Z" />
      <path d="M331.261 95.28L334.081 91.35L330.471 86.44L325.86 87.94L323.8 85.14L340.993 79.78L343.573 83.28L333.321 98.08L331.261 95.28ZM333.311 85.49L335.832 88.92L339.832 83.35L333.311 85.49Z" />
      <path d="M336.972 104.11L349.134 96.8799L346.623 92.6499L349.094 91.1799L355.805 102.47L353.334 103.94L350.824 99.7099L338.652 106.94L336.972 104.11Z" />
      <path d="M350.344 110C355.055 107.8 359.666 109.2 361.666 113.47C362.441 114.99 362.616 116.746 362.156 118.389C361.697 120.032 360.637 121.442 359.186 122.34L357.775 119.34C358.565 118.871 359.149 118.121 359.41 117.241C359.671 116.36 359.591 115.414 359.186 114.59C358.065 112.15 355.125 111.49 351.874 113.01C348.414 114.63 346.874 117.37 347.984 119.75C348.4 120.641 349.137 121.342 350.047 121.713C350.958 122.083 351.975 122.097 352.895 121.75L354.305 124.75C352.651 125.334 350.838 125.276 349.224 124.587C347.611 123.898 346.315 122.628 345.593 121.03C343.593 116.77 345.533 112.28 350.344 110Z" />
      <path d="M350.364 131L366.477 125.49L367.547 128.6L361.316 130.73L363.516 137.15L369.737 135.02L370.807 138.14L354.695 143.64L353.635 140.53L360.796 138.08L358.596 131.66L351.434 134.11L350.364 131Z" />
      <path d="M358.425 159.06L362.586 156.58L361.266 150.64L356.445 150.18L355.675 146.79L373.578 148.79L374.578 153.03L359.265 162.49L358.425 159.06ZM364.226 150.91L365.166 155.06L371.047 151.56L364.226 150.91Z" />
      <path d="M361.266 180.31L378.268 179.65L378.388 182.94L364.256 183.48L364.546 191L361.676 191.11L361.266 180.31Z" />
      <path d="M361.036 206.08L365.717 204.86L366.067 198.77L361.566 197L361.766 193.53L378.419 200.37L378.169 204.71L360.836 209.56L361.036 206.08ZM368.867 199.85L368.617 204.1L375.238 202.37L368.867 199.85Z" />
      <path d="M359.766 216.52L373.768 218.73L374.528 213.86L377.368 214.31L375.368 227.31L372.528 226.86L373.268 222L359.266 219.79L359.766 216.52Z" />
      <path d="M356.855 231.58L370.577 235L371.777 230.23L374.568 230.93L371.377 243.67L368.587 242.97L369.777 238.2L356.055 234.76L356.855 231.58Z" />
      <path d="M353.844 242.8L369.917 248.42L366.186 259.08L363.466 258.08L366.106 250.53L362.526 249.27L360.115 256.15L357.405 255.2L359.815 248.32L355.475 246.8L352.814 254.4L350.094 253.45L353.844 242.8Z" />
      <path d="M350.484 290.47L348.074 294.47L333.441 285.79L335.122 282.96L345.603 289.18L337.432 279.07L338.432 277.42L351.264 279.82L340.743 273.58L342.423 270.75L357.065 279.43L354.655 283.49L342.953 281.25L350.484 290.47Z" />
      <path d="M330.781 289.48L344.363 299.74L337.552 308.74L335.262 307L340.092 300.61L337.092 298.32L332.701 304.14L330.401 302.4L334.802 296.59L331.131 293.82L326.26 300.26L323.96 298.52L330.781 289.48Z" />
      <path d="M321.259 301.83L333.751 313.38L329.961 317.48C326.54 321.18 321.83 321.33 318.069 317.85C314.308 314.37 314.068 309.63 317.469 305.93L321.259 301.83ZM321.139 306.19L319.579 307.88C317.529 310.09 317.859 313.09 320.359 315.4C322.86 317.71 325.81 317.74 327.85 315.53L329.411 313.84L321.139 306.19Z" />
      <path d="M290.384 335.89C288.584 333.44 289.214 330.78 292.104 328.64L297.765 324.46L307.887 338.16L302.556 342.09C299.995 343.98 297.605 343.88 296.095 341.83C295.592 341.268 295.272 340.566 295.177 339.817C295.083 339.069 295.218 338.31 295.565 337.64C294.633 338 293.604 338.023 292.657 337.703C291.711 337.383 290.906 336.742 290.384 335.89V335.89ZM294.125 330.72C292.734 331.72 292.454 333.07 293.384 334.33C294.315 335.59 295.755 335.67 297.215 334.59L299.765 332.71L296.825 328.71L294.125 330.72ZM303.526 337.8L301.226 334.69L298.805 336.48C298.556 336.619 298.342 336.811 298.176 337.043C298.011 337.275 297.899 337.541 297.849 337.822C297.8 338.102 297.813 338.39 297.888 338.665C297.964 338.94 298.099 339.194 298.285 339.41C298.965 340.34 300.005 340.41 301.156 339.55L303.526 337.8Z" />
      <path d="M284.173 334.17L292.985 348.74L290.174 350.44L282.853 338.34L276.412 342.23L274.922 339.77L284.173 334.17Z" />
      <path d="M261.5 346.48L264.84 349.98L270.331 347.33L269.711 342.53L272.841 341.02L274.932 358.9L271.011 360.79L258.369 348L261.5 346.48ZM270.741 350.3L266.91 352.15L271.641 357.09L270.741 350.3Z" />
      <path d="M251.098 368.76L247.998 369.86L242.297 353.86L245.377 352.77L256.149 361.37L252.228 350.37L255.329 349.27L261.02 365.27L257.859 366.4L247.188 357.79L251.098 368.76Z" />
      <path d="M228.165 357.44L234.746 363.66L236.036 361.13L234.766 355.85L237.966 355.08L241.967 371.64L238.767 372.41L237.116 365.54L233.026 373.79L229.195 374.7L233.256 366.7L224.194 358.42L228.165 357.44Z" />
      <path d="M158.874 357.88L155.954 371.72L160.774 372.72L160.174 375.53L147.322 372.82L147.922 370L152.733 371L155.654 357.16L158.874 357.88Z" />
      <path d="M136.79 351.93V356.77L142.601 358.61L145.431 354.68L148.752 355.68L138.03 370.15L133.88 368.84L133.439 350.84L136.79 351.93ZM140.901 361.05L136.9 359.77L136.96 366.61L140.901 361.05Z" />
      <path d="M117.017 343.7L108.966 358.7L99.0146 353.36L100.375 350.82L107.376 354.61L109.166 351.27L102.745 347.82L104.105 345.28L110.526 348.73L112.707 344.68L105.616 340.87L106.976 338.34L117.017 343.7Z" />
      <path d="M85.0722 344.38L82.3418 342.54L91.8733 328.44L94.5737 330.27L94.0536 344L100.605 334.29L103.325 336.13L93.8036 350.24L91.0232 348.4L91.6132 334.7L85.0722 344.38Z" />
      <path d="M55.938 319L52.6475 315.62L64.8494 303.76L67.1397 306.12L58.4084 314.61L70.3002 309.36L71.6304 310.73L66.0196 322.48L74.7909 314L77.0813 316.36L64.8794 328.22L61.5889 324.84L66.7997 314.13L55.938 319Z" />
      <path d="M55.1581 291.29L52.4076 295.29L56.1182 300.13L60.6889 298.54L62.7993 301.29L45.7266 307L43.0762 303.55L53.0777 288.55L55.1581 291.29ZM53.2878 301.12L50.7074 297.74L46.8268 303.38L53.2878 301.12Z" />
      <path d="M49.2863 282.54L37.2144 290L39.8148 294.18L37.3644 295.7L30.4434 284.54L32.9338 283L35.5242 287.18L47.526 279.73L49.2863 282.54Z" />
      <path d="M35.8043 276.88C31.1336 279.17 26.5029 277.88 24.4226 273.63C23.6176 272.125 23.4083 270.372 23.8363 268.719C24.2644 267.067 25.2983 265.635 26.7329 264.71L28.2032 267.71C27.4222 268.192 26.8523 268.952 26.6075 269.836C26.3627 270.721 26.4611 271.665 26.8829 272.48C28.0431 274.91 31.0036 275.48 34.2241 273.93C37.6446 272.25 39.1349 269.47 37.9747 267.12C37.5412 266.24 36.7906 265.557 35.874 265.209C34.9574 264.86 33.9426 264.871 33.0339 265.24L31.5637 262.24C33.2085 261.622 35.0256 261.645 36.6542 262.305C38.2828 262.964 39.6038 264.212 40.3551 265.8C42.4254 270 40.5751 274.54 35.8043 276.88Z" />
      <path d="M35.3747 255.88L19.3722 261.69L18.2121 258.6L24.393 256.35L22.1127 250L15.9317 252.25L14.8115 249.16L30.814 243.34L31.9442 246.43L24.8231 249L27.1334 255.37L34.2146 252.79L35.3747 255.88Z" />
      <path d="M26.7728 228L22.6722 230.56L24.1324 236.47L28.9632 236.84L29.7933 240.22L11.8605 238.61L10.8604 234.38L25.9827 224.63L26.7728 228ZM21.142 236.27L20.1418 232.13L14.3209 235.75L21.142 236.27Z" />
      <path d="M23.5423 206.84L6.53964 207.84L6.34961 204.56L20.4818 203.74L20.0417 196.23L22.9122 196L23.5423 206.84Z" />
      <path d="M23.2727 181L18.622 182.32L18.3819 188.41L22.9226 190.11L22.7826 193.58L6 187.09L6.21003 182.75L23.4527 177.57L23.2727 181ZM15.5615 187.38L15.7315 183.13L9.14049 185L15.5615 187.38Z" />
      <path d="M24.343 170.59L10.3409 168.66L9.67076 173.54L6.82031 173.14L8.61059 160.14L11.461 160.53L10.7909 165.4L24.7931 167.33L24.343 170.59Z" />
      <path d="M26.9735 155.46L13.2114 152.29L12.1012 157.08L9.30078 156.44L12.2112 143.64L15.0117 144.29L13.9115 149.08L27.6937 152.26L26.9735 155.46Z" />
      <path d="M29.7633 144.23L13.5908 138.92L17.1114 128.19L19.8418 129.09L17.3414 136.69L20.952 137.88L23.2123 131L25.9528 131.89L23.6724 138.82L28.0431 140.26L30.5535 132.61L33.2939 133.51L29.7633 144.23Z" />
      <path d="M32.214 96.4899L34.5344 92.3799L49.3467 100.78L47.7165 103.64L37.1148 97.6399L45.4861 107.64L44.546 109.3L31.654 107.15L42.2956 113.15L40.6754 116L25.873 107.61L28.2134 103.55L39.9553 105.55L32.214 96.4899Z" />
      <path d="M51.9277 97.09L38.1455 87.09L44.7865 78L47.1169 79.69L42.4062 86.17L45.4767 88.4L49.7673 82.5L52.0977 84.19L47.807 90L51.5276 92.7L56.2183 86.23L58.5487 87.92L51.9277 97.09Z" />
      <path d="M61.2191 84.57L48.4971 73.25L52.2177 69.08C55.5682 65.31 60.2789 65.08 64.1095 68.48C67.9401 71.88 68.2902 76.63 64.9396 80.4L61.2191 84.57ZM61.2191 80.2L62.7493 78.48C64.7496 76.23 64.3696 73.24 61.8192 70.98C59.2688 68.72 56.3283 68.74 54.328 70.98L52.7977 72.7L61.2191 80.2Z" />
      <path d="M91.4234 49.9199C93.2737 52.3299 92.6936 55.0099 89.8431 57.1999L84.2723 61.4899L73.8906 47.9999L79.1414 43.9999C81.6618 42.0599 84.0522 42.1099 85.6025 44.1399C86.1097 44.6858 86.4404 45.3722 86.551 46.1091C86.6616 46.846 86.5472 47.5991 86.2226 48.2699C87.1458 47.8951 88.1711 47.8549 89.1209 48.1562C90.0707 48.4575 90.8852 49.0814 91.4234 49.9199ZM78.2213 48.2699L80.5817 51.3299L82.972 49.4899C83.218 49.3462 83.4289 49.1495 83.5895 48.9141C83.75 48.6787 83.8561 48.4106 83.9002 48.1291C83.9442 47.8477 83.9251 47.5599 83.8441 47.2868C83.7632 47.0137 83.6224 46.7619 83.4321 46.5499C82.732 45.6399 81.6918 45.5999 80.5617 46.4699L78.2213 48.2699ZM87.7528 55.1599C89.123 54.0999 89.383 52.7799 88.4229 51.5399C87.4627 50.2999 86.0325 50.2399 84.5923 51.3499L82.0819 53.2799L85.0824 57.1999L87.7528 55.1599Z" />
      <path d="M97.6744 51.5201L88.583 37.1301L91.3634 35.3701L98.9146 47.3701L105.276 43.3701L106.806 45.8001L97.6744 51.5201Z" />
      <path d="M120.098 38.7801L116.688 35.3501L111.227 38.1001L111.947 42.8901L108.846 44.4501L106.416 26.6201L110.287 24.6201L123.229 37.2101L120.098 38.7801ZM110.787 35.1401L114.587 33.2201L109.757 28.3701L110.787 35.1401Z" />
      <path d="M130.059 16.3199L133.14 15.1599L139.14 31.0799L136.08 32.2299L125.148 23.8399L129.279 34.8399L126.228 35.9999L120.228 19.9999L123.368 18.8199L134.23 27.2499L130.059 16.3199Z" />
      <path d="M153.233 27.1899L146.532 21.0899L145.291 23.6399L146.662 28.8999L143.481 29.7299L139.23 13.2499L142.411 12.4299L144.191 19.2599L148.132 10.9399L151.942 9.93994L147.982 17.9999L157.233 26.1499L153.233 27.1899Z" />
    </svg>
  );
};

export default MatchaLatte;
