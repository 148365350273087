const BlankLogo: React.FC<{
  color?: string;
  className?: string;
}> = ({ className = '', color = 'var(--dark)' }) => {
  return (
    <svg
      width="145"
      height="44"
      viewBox="0 0 145 44"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1109_18)">
        <path
          d="M16.2776 15.0378C12.5504 15.0378 9.58437 16.9451 7.75959 20.0527V0C4.05351 0.698503 2.74142 0.95082 0 1.42552V1.97719C1.42226 2.51034 1.63553 2.61297 2.84593 3.15182V43.1974H7.75959V39.7762C11.412 45.6208 20.1758 44.7983 24.5428 39.9473C32.4521 32.2751 28.0639 14.8382 16.2776 15.0378ZM19.9413 39.0021C16.8341 42.9109 10.2962 43.1019 7.75817 38.9551C7.75817 34.449 7.75817 25.3827 7.75817 20.8795C10.2793 16.057 17.3016 16.5759 20.1461 20.8867C23.5994 24.9081 23.6417 35.3143 19.9413 39.0021Z"
          fill={color}
        />
        <path
          d="M42.7591 0C39.2281 0.634355 38.4499 0.774056 34.9981 1.42552V1.97719C35.9668 2.31841 36.9172 2.71048 37.8454 3.15182V42.077C36.7833 42.077 34.6478 42.6472 33.5857 42.6472V43.1974H47.0174V42.6472C45.1629 42.1483 44.6516 42.0299 42.7591 41.4726V0Z"
          fill={color}
        />
        <path
          d="M73.4422 23.139C73.0694 2.02854 36.5439 28.3436 59.5557 24.9252C62.1079 24.4191 64.0584 25.5994 63.6686 27.1746C63.5132 27.8019 63.2788 28.355 61.6461 28.8639C39.2147 34.8867 62.711 53.5752 68.5257 37.8517V43.1974H77.644V42.6472C75.7147 42.144 75.1794 41.9572 73.3843 41.4726C73.3942 36.6058 73.4323 28.0029 73.4422 23.139ZM68.5738 25.8019C68.5469 28.2024 68.5003 33.0278 68.4735 35.4284C68.1388 45.752 54.813 41.9601 58.6645 33.0834C59.5001 31.7301 60.6924 30.6382 62.1079 29.9302C65.6191 27.9615 65.6586 24.7056 62.9384 23.7277C60.5486 22.8439 57.4301 23.2374 57.5798 21.2602C59.3792 11.8233 69.1288 17.3329 68.5738 25.8019Z"
          fill={color}
        />
        <path
          d="M105.568 17.2901C101.034 12.8439 92.3353 15.1376 91.0204 21.7092V15.2032L81.847 18.6244V18.8454C82.9092 19.4156 85.0447 19.8703 86.1068 20.4462V42.077C85.0447 42.077 82.9077 42.6472 81.847 42.6472V43.1975H95.2787V42.6472C93.5203 42.1454 92.7096 41.9259 91.0204 41.4726C91.0204 37.2531 91.0204 29.9473 91.0204 25.7292C91.067 20.814 92.4921 17.3942 97.2448 17.2973C101.746 17.4498 103.017 20.8268 103.086 25.7292C103.086 29.943 103.086 37.8632 103.086 42.077C102.026 42.077 99.8887 42.6472 98.828 42.6472V43.1975H112.26V42.6472C110.604 42.1782 110.099 42.077 108.001 41.4726C107.417 35.2502 109.795 21.4869 105.568 17.2901Z"
          fill={color}
        />
        <path
          d="M138.252 35.1803L130.281 27.1618H124.165L132.137 19.7776C140.098 19.5723 137.95 21.2145 143.876 15.5339H135.905L124.11 26.4448V0C120.694 0.570207 119.918 0.712758 116.351 1.42552V1.97719C117.492 2.40485 118.033 2.61867 119.197 3.15182V43.1974H124.11V27.933L139.235 43.1974C140.625 43.1974 143.074 43.2174 144.981 43.1974C144.992 43.1974 144.999 41.9658 144.999 41.9658L138.252 35.1803Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1109_18">
          <rect width="145" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlankLogo;
