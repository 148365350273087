import DarksideContainer from '../containers/DarksideContainer';
import { DarksideProvider } from '../Context/DarksideContext';
import '../styles/globals.css';
import { ValProvider } from '@valbuild/next';
import { config } from '../val.config';
import { useVal } from '../val/val.client';
import darkside2Val from '../content/darkside.val';

function MyApp({ Component, pageProps }) {
  const { pictures } = useVal(darkside2Val)[0];
  return (
    <ValProvider config={config} disableRefresh>
      <DarksideProvider>
        <DarksideContainer data={{ pictures }}>
          <Component {...pageProps} />
        </DarksideContainer>
      </DarksideProvider>
    </ValProvider>
  );
}

export default MyApp;
