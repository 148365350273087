const Template: React.FC<{
  color?: string;
  className?: string;
}> = ({ className = '', color = 'var(--dark)' }) => {
  return (
    <svg
      width="50"
      height="12.5"
      viewBox="0 0 50 12.5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      id="blank-rectangle"
    >
      <rect width="50" height="12.5" fill={color} className="transition-50ms" />
    </svg>
  );
};

export default Template;
