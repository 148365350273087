import { useCallback, useEffect, useState } from 'react';
import NextImage from 'next/image';
import { useDarkSideContext } from '../../Context/DarksideContext';
import BlankImage from '../BlankImage/BlankImage';
import DarksideToggle from '../DarksideToggle/DarksideToggle';
import GlitchBlankImage from '../GlitchBlankImage/GlitchBlankImage';
import SvgIllustration from '../Illustration/SvgIllustration';
import { BlankLogo, Money } from '../SVGs';
import { inColumns } from './utils';
import { DarkSide } from '../../content/darkside.val';
import { Image } from '@valbuild/next';

type DarksideProps = DarkSide;

const Darkside = ({ pictures }: DarksideProps) => {
  const { setDarksideMode } = useDarkSideContext();
  const [imageGrid, setImageGrid] = useState<JSX.Element[][]>([]);
  const [activeImage, setActiveImage] = useState<Image>(null);

  const handleImageClick = useCallback((image: any) => {
    setActiveImage(image);
    document.body.style.overflow = 'hidden';
  }, []);

  const handleImageClose = () => {
    setActiveImage(null);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    let positions = ['start', 'center', 'end'];
    let pictureArray = inColumns(
      pictures.map((picture, idx) => {
        let width = Math.random() * 200 + 350;
        let sign = Math.round(Math.random()) * 2 - 1;
        return (
          <div
            key={idx}
            onClick={() => handleImageClick(picture)}
            className="cursor-pointer scale-[0.7] laptop:scale-100"
            style={{
              width: width,
              height: 'auto',
              zIndex: Math.floor(Math.random() * (100 - 50 + 1) + 50),
              marginBottom:
                sign * Math.floor(Math.random() * (100 - 0 + 1) + 0),
              padding: Math.floor(Math.random() * (50 - 0 + 1) + 0),
              alignSelf:
                positions[Math.floor(Math.random() * positions.length)],
            }}
          >
            <GlitchBlankImage
              src={picture}
              alt="tempRandomImage"
              width={width}
              removeEffect
            />
          </div>
        );
      }),
      4
    );
    pictures && setImageGrid(pictureArray);
  }, [pictures, handleImageClick]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [imageGrid]);

  var fixedLogoCounter = 1;
  return (
    <div id="darkside" className="flex darkside-root">
      <button
        className="fixed z-20 block top-10 right-32"
        aria-label="Skru av dark mode"
        onClick={() => setDarksideMode(false)}
      >
        <DarksideToggle forceLight />
      </button>
      <div className="relative w-full min-h-full px-32 pt-[25%] flex flex-col items-center overflow-x-hidden rotate-x-180 z-10">
        <div className="relative grid grid-cols-2 laptop:grid-cols-4 max-w-[1400px] gap-24 laptop:gap-[150px]">
          <div className="absolute bottom-[10%] right-[15%] rotate-x-180">
            <NextImage
              src="/gifs/stairway_anim.gif"
              alt="Animation"
              width="153"
              height="298"
              objectFit="contain"
            />
          </div>
          <div className="absolute bottom-[50%] left-[10%] rotate-x-180">
            <SvgIllustration>
              <Money className="w-[153px] h-[298px]" />
            </SvgIllustration>
          </div>
          <div className="absolute top-0 right-[30%] rotate-x-180">
            <NextImage
              src="/gifs/tech_anim.gif"
              alt="Animation"
              width={247}
              height={289}
              className="object-contain"
            />
          </div>
          <div className="hidden laptop:flex flex-col pt-[256px]">
            {imageGrid[1]}
          </div>
          <div className="flex-col hidden pt-32 laptop:flex">
            {imageGrid[0]}
          </div>
          <div className="hidden laptop:flex flex-col pt-[560px]">
            {imageGrid[2]}
          </div>
          <div className="hidden laptop:flex flex-col pt-[128px]">
            {imageGrid[3]}
          </div>
          <div className="flex flex-col pt-32 laptop:hidden">
            {imageGrid[0]}
            {imageGrid[1]}
          </div>
          <div className="flex laptop:hidden flex-col pt-[128px]">
            {imageGrid[2]}
            {imageGrid[3]}
          </div>
          <div
            className="absolute w-full top-[50%] translate-y-[-50%] whitespace-nowrap"
            style={{ zIndex: 200 }}
            aria-hidden="true"
          >
            <div className="absolute animate-floatText1 hover:pause-animation flex space-x-[1vw]">
              {[...Array(4)].map((_, idx) => (
                <span
                  key={idx}
                  className="font-solina text-[#FFFCB6] text-6xl leading-blankTight tracking-blankTighter"
                >
                  BLANK ER ET FREMOVERLENT KONSULENTHUS I PREMIUMSEGMENTET{' '}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`absolute bg-[#0000FF] top-0 flex items-center justify-between w-full h-28 px-[20px] laptop:px-32 z-50`}
        >
          <SvgIllustration>
            <BlankLogo
              color="#FFFCB6"
              className="w-[92px] h-[28px] tablet:w-[144px] tablet:h-[44px] laptop:w-[144px] laptop:h-[44px]"
            />
          </SvgIllustration>
        </div>
      </div>
      <div className="fixed bottom-0 w-full h-[380px] darkside-bottom-logos on-bottom-safari">
        {[...Array(30)].map((_, idx) => {
          if (fixedLogoCounter === 4) {
            fixedLogoCounter = 1;
          } else {
            fixedLogoCounter++;
          }
          return (
            <div key={idx} className="relative w-[120px] h-[380px]">
              <NextImage
                src={`/images/stretchedLogo${fixedLogoCounter}.png`}
                alt="fixedLogo"
                layout="fill"
              />
            </div>
          );
        })}
      </div>
      {activeImage && (
        <div
          className="fixed top-0 z-50 w-screen h-screen bg-black bg-opacity-70"
          onClick={() => handleImageClose()}
        >
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[95vw] flex justify-center">
            <div className="bg-[#FFFCB6] p-8 box-border">
              <BlankImage
                src={activeImage}
                alt="activeImage"
                width={750}
                removeEffect
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Darkside;
