const WaterKefir: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M257.399 34.59L262.94 21.59L258.4 19.65L259.52 17L271.602 22.15L270.481 24.8L265.951 22.87L260.4 35.87L257.399 34.59Z" />
      <path d="M277.872 44.7101L278.762 39.9601L273.402 37.0301L269.861 40.3301L266.811 38.6601L280.133 26.5601L283.943 28.6401L280.893 46.3701L277.872 44.7101ZM275.602 34.9701L279.332 36.9701L280.603 30.2501L275.602 34.9701Z" />
      <path d="M295.675 56.64L306.497 43.5L315.218 50.68L313.408 52.9L307.227 47.81L304.817 50.74L310.408 55.37L308.577 57.59L302.946 52.96L300.026 56.51L306.237 61.63L304.407 63.85L295.675 56.64Z" />
      <path d="M327.11 62.1599L329.411 64.4999L317.329 76.4999L315.028 74.1799L318.229 60.7799L309.918 69.0199L307.597 66.6899L319.679 54.6899L322.049 57.0699L318.809 70.3899L327.11 62.1599Z" />
      <path d="M331.821 94.9099L342.932 80.9099L344.843 83.7799L335.841 94.8699L349.524 90.7599L351.414 93.6299L334.221 98.5299L331.821 94.9099Z" />
      <path d="M344.193 115.94L347.563 112.47L344.723 107.08L339.942 107.88L338.322 104.81L356.115 102.06L358.115 105.91L345.783 119.01L344.193 115.94ZM347.673 106.56L349.674 110.33L354.414 105.42L347.673 106.56Z" />
      <path d="M366.827 125.51L368.037 128.57L352.224 134.85L351.014 131.85L359.215 120.78L348.334 125.1L347.124 122.04L362.936 115.76L364.176 118.89L355.965 129.89L366.827 125.51Z" />
      <path d="M372.878 144.73L373.758 147.9L357.355 152.44L356.485 149.3L365.826 139.17L354.545 142.25L353.665 139.09L370.057 134.54L370.957 137.78L361.616 147.78L372.878 144.73Z" />
      <path d="M359.955 166.8L366.636 160.68L364.206 159.25L358.845 160.14L358.305 156.89L375.098 154.1L375.638 157.35L368.637 158.51L376.568 163.18L377.208 167.06L369.497 162.44L360.596 170.89L359.955 166.8Z" />
      <path d="M360.905 174.1L377.908 172.96L378.658 184.23L375.798 184.42L375.258 176.42L371.467 176.68L371.957 183.95L369.087 184.15L368.596 176.87L364.006 177.18L364.546 185.18L361.685 185.38L360.905 174.1Z" />
      <path d="M361.736 189.71L378.738 190.12L378.468 201.22L375.598 201.15L375.778 193.34L371.917 193.24L371.747 200.35L368.917 200.25L369.077 193.15L361.656 192.97L361.736 189.71Z" />
      <path d="M361.106 204.62L378.048 206.18L377.748 209.46L360.806 207.9L361.106 204.62Z" />
      <path d="M360.415 212.51L377.208 215.3L376.108 221.9C375.538 225.3 373.257 226.96 370.157 226.44C369.259 226.357 368.407 226.008 367.708 225.438C367.01 224.868 366.497 224.103 366.236 223.24C364.236 226 360.095 224.24 358.395 225.34H358.285L358.825 222.12C361.336 220.63 365.466 223.37 366.196 218.99L366.546 216.88L359.875 215.77L360.415 212.51ZM369.417 217.33L368.867 220.63C368.8 220.92 368.796 221.221 368.856 221.513C368.917 221.804 369.039 222.079 369.216 222.319C369.393 222.558 369.62 222.756 369.88 222.9C370.141 223.043 370.43 223.129 370.727 223.15C371.024 223.222 371.332 223.229 371.631 223.17C371.931 223.111 372.213 222.988 372.461 222.809C372.708 222.631 372.913 222.4 373.063 222.135C373.213 221.869 373.303 221.574 373.327 221.27L373.858 218.07L369.417 217.33Z" />
      <path d="M365.136 260.72L363.526 265.15L347.523 259.34L348.644 256.25L360.095 260.41L350.194 251.99L350.844 250.2L363.896 250.12L352.414 245.94L353.534 242.85L369.537 248.66L367.917 253.09H356.015L365.136 260.72Z" />
      <path d="M345.413 263.37L360.556 271.13L355.415 281.18L352.854 279.87L356.505 272.75L353.134 271.02L349.804 277.5L347.244 276.19L350.574 269.7L346.483 267.61L342.803 274.77L340.252 273.46L345.413 263.37Z" />
      <path d="M338.112 277.25L352.414 286.45L349.414 291.15C346.673 295.38 342.052 296.34 337.742 293.55C333.431 290.76 332.371 286.14 335.111 281.9L338.112 277.25ZM338.742 281.57L337.492 283.51C335.861 286.04 336.692 288.94 339.552 290.78C342.413 292.62 345.323 292.16 346.963 289.63L348.213 287.69L338.742 281.57Z" />
      <path d="M313.538 316.04C311.348 313.94 311.538 311.2 313.998 308.61L318.869 303.53L331.161 315.3L326.58 320.08C324.38 322.38 322.009 322.69 320.169 320.92C319.585 320.454 319.156 319.824 318.936 319.11C318.717 318.396 318.718 317.633 318.939 316.92C318.078 317.429 317.066 317.619 316.079 317.458C315.092 317.298 314.193 316.796 313.538 316.04V316.04ZM316.349 310.31C315.148 311.56 315.098 312.91 316.229 313.99C317.359 315.07 318.789 314.92 320.039 313.6L322.239 311.31L318.669 307.89L316.349 310.31ZM326.81 315.69L324.02 313.01L321.929 315.19C321.706 315.371 321.526 315.6 321.402 315.859C321.278 316.119 321.214 316.402 321.214 316.69C321.214 316.978 321.278 317.261 321.402 317.521C321.526 317.781 321.706 318.009 321.929 318.19C322.76 318.98 323.8 318.86 324.78 317.83L326.81 315.69Z" />
      <path d="M307.137 315.4L318.289 328.25L315.798 330.4L306.527 319.71L300.846 324.64L298.966 322.47L307.137 315.4Z" />
      <path d="M286.874 331.35L290.765 334.23L295.765 330.7L294.345 326.07L297.176 324.07L302.256 341.34L298.716 343.86L284.083 333.38L286.874 331.35ZM296.635 333.55L293.165 336.02L298.666 340.09L296.635 333.55Z" />
      <path d="M280.403 355.06L277.542 356.67L269.201 341.82L272.052 340.22L284.124 346.88L278.403 336.67L281.273 335.06L289.604 349.9L286.674 351.54L274.672 344.87L280.403 355.06Z" />
      <path d="M255.879 347.78L263.42 352.78L264.26 350.07L262.12 345.07L265.12 343.77L271.841 359.4L268.841 360.7L266.051 354.25L263.4 363.06L259.79 364.62L262.43 356.02L252.088 349.4L255.879 347.78Z" />
      <path d="M130.49 348.42L125.199 361.54L129.769 363.38L128.689 366.04L116.507 361.13L117.587 358.47L122.148 360.31L127.439 347.19L130.49 348.42Z" />
      <path d="M109.766 338.71L108.956 343.47L114.377 346.25L117.847 342.88L120.928 344.49L107.846 356.86L103.995 354.86L106.696 337.07L109.766 338.71ZM112.226 348.4L108.456 346.4L107.316 353.15L112.226 348.4Z" />
      <path d="M91.7536 327.16L81.212 340.52L72.3506 333.52L74.1309 331.25L80.3718 336.25L82.7222 333.25L77.0013 328.73L78.7816 326.47L84.5025 330.99L87.3729 327.38L81.0519 322.38L82.8322 320.13L91.7536 327.16Z" />
      <path d="M60.1782 322.25L57.8179 319.97L69.6497 307.73L72.0001 310L69.0796 323.47L77.2209 315.06L79.5913 317.35L67.7494 329.59L65.369 327.25L68.3695 313.87L60.1782 322.25Z" />
      <path d="M54.8277 289.63L44.006 303.86L42.0057 301.03L50.807 289.75L37.2049 294.14L35.2046 291.31L52.3673 286.06L54.8277 289.63Z" />
      <path d="M42.0258 268.85L38.7253 272.38L41.7258 277.71L46.4865 276.82L48.1668 279.82L30.364 282.96L28.2637 279.16L40.3656 265.81L42.0258 268.85ZM38.7353 278.25L36.675 274.53L32.0043 279.53L38.7353 278.25Z" />
      <path d="M19.1921 259.73L17.9219 256.73L33.6143 250.12L34.8845 253.12L26.8833 264.36L37.685 259.82L38.9552 262.82L23.2627 269.42L21.9625 266.33L29.9638 255.18L19.1921 259.73Z" />
      <path d="M12.7712 240.64L11.8311 237.49L28.1336 232.62L29.0737 235.75L19.9423 246.06L31.1541 242.71L32.0942 245.86L15.7917 250.73L14.7915 247.52L23.9229 237.3L12.7712 240.64Z" />
      <path d="M25.2329 218.3L18.6819 224.56L21.1423 225.97L26.4831 224.97L27.0932 228.2L10.3606 231.34L9.75049 228.11L16.6916 226.81L8.69033 222.3L7.97021 218.43L15.7714 222.89L24.4728 214.25L25.2329 218.3Z" />
      <path d="M24.143 211.04L7.14034 212.52L6.2002 201.25L9.07064 201L9.77075 209L13.5513 208.67L12.9112 201.4L15.7817 201.15L16.4118 208.42L20.9925 208.02L20.2924 200.02L23.1528 199.77L24.143 211.04Z" />
      <path d="M23.0027 195.44L6 195.39V184.28H8.89045V192.1H12.7611V184.99H15.6115V192.1H23.0427L23.0027 195.44Z" />
      <path d="M23.3625 180.52L6.35986 179.31L6.5999 176.03L23.6026 177.24L23.3625 180.52Z" />
      <path d="M23.9029 172.62L7.05029 170.17L8.05045 163.54C8.55053 160.13 10.7909 158.43 13.9114 158.89C14.8111 158.955 15.6713 159.285 16.3835 159.838C17.0956 160.392 17.6275 161.144 17.912 162C19.9123 159.2 24.0229 160.85 25.7032 159.74H25.8132L25.3432 162.97C22.8628 164.52 18.6721 161.86 18.032 166.25L17.722 168.37L24.413 169.37L23.9029 172.62ZM14.8315 167.97L15.3216 164.67C15.3832 164.375 15.3792 164.07 15.31 163.776C15.2408 163.483 15.1081 163.208 14.9211 162.972C14.7342 162.735 14.4976 162.543 14.2281 162.408C13.9586 162.273 13.6627 162.198 13.3613 162.19C13.0637 162.124 12.7555 162.124 12.4578 162.189C12.1602 162.254 11.88 162.382 11.6368 162.566C11.3935 162.749 11.1928 162.983 11.0485 163.251C10.9042 163.52 10.8197 163.816 10.8009 164.12L10.3608 167.33L14.8315 167.97Z" />
      <path d="M18.3618 124.25L19.952 119.81L35.9545 125.55L34.8443 128.65L23.3625 124.53L33.3641 132.91L32.714 134.71L19.712 134.84L31.2238 138.97L30.1236 142.07L14.1211 136.32L15.7113 131.88H27.6232L18.3618 124.25Z" />
      <path d="M37.8449 121.47L22.5425 114.01L27.4933 103.86L30.0837 105.12L26.5731 112.31L29.9836 113.98L33.1741 107.42L35.7645 108.68L32.564 115.25L36.6947 117.25L40.2252 110.02L42.8156 111.25L37.8449 121.47Z" />
      <path d="M44.846 107.49L30.3638 98.5499L33.3042 93.7899C35.9446 89.5099 40.5454 88.4499 44.916 91.1399C49.2867 93.8299 50.4369 98.4399 47.7965 102.73L44.846 107.49ZM44.1259 103.18L45.3661 101.25C46.9464 98.6899 46.0462 95.8099 43.1458 94.0199C40.2453 92.2299 37.3649 92.7399 35.7346 95.2999L34.5344 97.2999L44.1259 103.18Z" />
      <path d="M68.6098 68.1799C70.8502 70.1799 70.7402 72.9699 68.3098 75.6099L63.5491 80.7899L51.0171 69.2499L55.4978 64.3699C57.6481 62.0399 60.0085 61.6799 61.8888 63.3699C62.4867 63.8255 62.9322 64.4519 63.1661 65.1662C63.4001 65.8805 63.4116 66.649 63.199 67.3699C64.052 66.8464 65.0623 66.6407 66.0521 66.7888C67.0419 66.937 67.9476 67.4296 68.6098 68.1799V68.1799ZM55.3678 68.7999L58.2182 71.4199L60.2185 69.1999C60.4437 69.0201 60.6254 68.7918 60.7504 68.5322C60.8753 68.2725 60.9401 67.9881 60.9401 67.6999C60.9401 67.4118 60.8753 67.1273 60.7504 66.8677C60.6254 66.608 60.4437 66.3798 60.2185 66.1999C59.3684 65.4499 58.3682 65.5899 57.3681 66.6399L55.3678 68.7999ZM65.9394 73.9599C67.1096 72.6899 67.1296 71.3299 65.9394 70.2799C64.7492 69.2299 63.359 69.4099 62.1288 70.7499L59.9885 73.0799L63.6291 76.4299L65.9394 73.9599Z" />
      <path d="M75.0407 68.6801L63.6289 56.0401L66.0793 53.8401L75.5608 64.3401L81.1416 59.3401L83.0719 61.4701L75.0407 68.6801Z" />
      <path d="M94.9941 52.3101L90.9935 49.51L86.1027 53.14L87.6229 57.7401L84.8325 59.8101L79.4517 42.64L82.9422 40.05L97.7845 50.25L94.9941 52.3101ZM85.1926 50.3101L88.6031 47.7701L83.0322 43.7701L85.1926 50.3101Z" />
      <path d="M100.975 28.4801L103.815 26.8201L112.437 41.4901L109.626 43.1501L97.4244 36.7401L103.375 46.8301L100.535 48.5001L91.8936 33.8201L94.794 32.1201L106.896 38.5601L100.975 28.4801Z" />
      <path d="M125.629 35.25L117.988 30.38L117.208 33.11L119.448 38.06L116.447 39.42L109.446 23.92L112.447 22.56L115.377 29.01L117.848 20.13L121.378 18.51L118.908 27.15L129.379 33.57L125.629 35.25Z" />
    </svg>
  );
};

export default WaterKefir;
