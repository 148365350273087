const ArrowDark: React.FC<{
  color?: string;
  className?: string;
}> = ({ className = '', color = 'var(--dark)' }) => {
  return (
    <svg
      width="41"
      height="15"
      viewBox="0 0 41 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.9"
        d="M0 14.4707C3.7365 12.8629 8.67112 11.456 14.8039 10.2501C20.9366 9.04424 25.6478 8.44129 28.9376 8.44129C28.9376 8.68247 28.9376 8.92365 28.9376 9.16482C28.9376 11.0942 28.4299 12.8629 27.4146 14.4707C31.5978 10.9736 36.1263 8.56188 41 7.23541C38.8474 6.79326 36.5527 5.92904 34.1159 4.64277C31.679 3.35649 29.4453 1.80894 27.4146 0.000120163C28.4299 1.72855 28.9376 3.73836 28.9376 6.02953C25.1605 6.02953 20.4898 5.48688 14.9257 4.40159C9.36156 3.35649 4.38633 1.88933 0 0.000120163L3.89896 7.23541L0 14.4707Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowDark;
