const SnakkMedJBO: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <>
      <div className="">
        <svg
          width="313"
          height="313"
          viewBox="0 0 313 313"
          fill={color}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M161.613 17.2485C164.029 17.3304 165.66 16.0189 165.731 13.9163C165.826 11.1233 163.532 10.6529 161.824 10.0767C160.768 9.72669 159.917 9.33657 159.939 8.20628C159.952 7.37413 160.63 6.84727 161.665 6.88235C162.685 6.91689 163.387 7.5376 163.395 8.68464L165.623 8.76011C165.632 6.6397 164.11 5.20574 161.772 5.12655C159.34 5.04417 157.698 6.21385 157.648 8.16007C157.559 10.7648 159.39 11.4551 161.005 11.9811C162.311 12.4024 163.493 12.7409 163.449 14.0432C163.419 14.9219 162.739 15.5273 161.625 15.4895C160.338 15.4459 159.421 14.6923 159.435 13.3575L157.129 13.2793C157.05 15.5859 158.82 17.1539 161.613 17.2485Z"
            fill={color}
          />
          <path
            d="M179.056 15.0085L175.315 6.42884L173.045 6.10982L171.428 17.6148L173.652 17.9272L174.764 10.0137L178.565 18.6177L180.772 18.928L182.389 7.42302L180.166 7.11056L179.056 15.0085Z"
            fill={color}
          />
          <path
            d="M196.631 22.6486L195.66 10.4037L192.786 9.66526L186.017 19.9214L188.313 20.5114L190.098 17.7281L194.128 18.7634L194.335 22.0586L196.631 22.6486ZM193.711 12.0588L194.004 16.7216L191.191 15.9988L193.711 12.0588Z"
            fill={color}
          />
          <path
            d="M210.791 27.7464L208.854 19.5954L214.224 16.6238L211.713 15.6744L206.234 18.7559L207.938 14.2475L205.838 13.4535L201.73 24.3208L203.83 25.1147L205.14 21.649L206.837 20.7127L208.162 26.7526L210.791 27.7464Z"
            fill={color}
          />
          <path
            d="M224.286 34.3275L223.239 26.0151L228.898 23.6398L226.504 22.4252L220.725 24.898L222.906 20.5996L220.903 19.5839L215.647 29.9448L217.649 30.9605L219.326 27.6563L221.113 26.9084L221.78 33.056L224.286 34.3275Z"
            fill={color}
          />
          <path
            d="M249.341 46.9907L252.181 39.3731L249.727 37.2906L242.21 46.1487L243.922 47.6014L249.326 41.2331L246.28 49.6026L247.273 50.4458L255.016 46.1028L249.631 52.4471L251.343 53.8998L258.861 45.0417L256.407 42.9592L249.341 46.9907Z"
            fill={color}
          />
          <path
            d="M261.042 63.9127L262.474 62.5703L258.715 58.5619L261.005 56.4141L264.41 60.0444L265.841 58.702L262.437 55.0716L264.327 53.2996L268.064 57.285L269.495 55.9426L264.222 50.3195L255.748 58.2667L261.042 63.9127Z"
            fill={color}
          />
          <path
            d="M267.276 71.5371C269.355 74.2755 272.56 74.6804 275.361 72.5536C278.15 70.4364 278.608 67.2496 276.529 64.5113L274.221 61.4729L264.969 68.4987L267.276 71.5371ZM274.016 64.4477L274.966 65.6981C276.209 67.3361 275.813 69.3324 273.987 70.7186C272.137 72.1237 270.083 71.9884 268.839 70.3503L267.889 69.1L274.016 64.4477Z"
            fill={color}
          />
          <path
            d="M282.979 97.8407C283.979 100.008 285.95 100.568 288.33 99.4696L295.273 96.2661L294.339 94.2418L287.482 97.4058C286.056 98.0636 285.082 97.9769 284.661 97.0645C284.254 96.1807 284.769 95.4241 286.095 94.8124L285.161 92.7881C282.823 93.8669 281.993 95.7024 282.979 97.8407Z"
            fill={color}
          />
          <path
            d="M288.5 111.741C289.519 114.744 292.584 116.009 296.019 114.844C299.379 113.704 300.997 110.85 299.978 107.847C298.939 104.784 295.908 103.475 292.548 104.615C289.114 105.78 287.461 108.678 288.5 111.741ZM290.255 111.146C289.654 109.377 290.891 107.597 293.359 106.76C295.664 105.978 297.623 106.673 298.224 108.442C298.804 110.152 297.666 111.881 295.362 112.663C292.894 113.5 290.835 112.856 290.255 111.146Z"
            fill={color}
          />
          <path
            d="M297.057 128.664L303.824 122.198L303.342 119.957L291.984 122.4L292.456 124.595L300.269 122.914L293.499 129.445L293.968 131.625L305.326 129.182L304.854 126.987L297.057 128.664Z"
            fill={color}
          />
          <path
            d="M295.822 159.017C295.767 162.188 298.225 164.414 301.851 164.477C305.399 164.539 307.886 162.4 307.941 159.229C307.997 155.995 305.587 153.739 302.04 153.677C298.413 153.614 295.879 155.783 295.822 159.017ZM297.675 159.049C297.707 157.181 299.472 155.925 302.078 155.97C304.511 156.013 306.121 157.328 306.089 159.196C306.057 161.002 304.402 162.245 301.969 162.202C299.363 162.157 297.643 160.855 297.675 159.049Z"
            fill={color}
          />
          <path
            d="M298.655 176.674L306.612 175.003L307.06 171.816L295.555 170.197L295.243 172.42L303.513 173.584L294.812 175.483L294.63 176.773L302.439 180.996L294.199 179.836L293.886 182.059L305.391 183.678L305.839 180.491L298.655 176.674Z"
            fill={color}
          />
          <path
            d="M286.723 206.325C285.864 208.552 286.834 210.356 289.28 211.3L296.413 214.053L297.216 211.973L290.17 209.254C288.705 208.689 288.09 207.93 288.451 206.992C288.802 206.084 289.704 205.927 291.066 206.453L291.869 204.373C289.466 203.446 287.571 204.128 286.723 206.325Z"
            fill={color}
          />
          <path
            d="M280.597 219.945C279.152 222.768 280.379 225.848 283.607 227.5C286.766 229.117 289.94 228.291 291.386 225.468C292.859 222.589 291.688 219.503 288.53 217.886C285.302 216.233 282.071 217.066 280.597 219.945ZM282.246 220.789C283.097 219.126 285.235 218.774 287.555 219.962C289.721 221.071 290.588 222.961 289.737 224.624C288.914 226.231 286.881 226.619 284.715 225.51C282.395 224.322 281.423 222.396 282.246 220.789Z"
            fill={color}
          />
          <path
            d="M277.041 238.818C276.879 239.803 277.336 240.707 278.358 241.382C279.812 242.342 281.365 241.843 282.559 240.035L285.05 236.262L275.355 229.86L272.708 233.869C271.358 235.913 271.58 237.771 273.309 238.913C274.632 239.787 275.96 239.742 277.041 238.818ZM281.068 238.731C280.532 239.543 279.849 239.751 279.194 239.318C278.46 238.834 278.322 238.103 278.841 237.317L279.974 235.601L282.175 237.054L281.068 238.731ZM275.756 232.815L278.573 234.675L277.379 236.483C276.695 237.518 275.756 237.801 274.865 237.212C273.974 236.624 273.844 235.71 274.493 234.728L275.756 232.815Z"
            fill={color}
          />
          <path
            d="M267.83 250.889C267.568 251.852 267.929 252.798 268.875 253.575C270.222 254.681 271.818 254.345 273.193 252.671L276.062 249.176L267.081 241.805L264.033 245.518C262.479 247.411 262.507 249.282 264.109 250.597C265.335 251.603 266.66 251.696 267.83 250.889ZM271.845 251.219C271.228 251.972 270.527 252.108 269.92 251.61C269.241 251.052 269.179 250.31 269.777 249.582L271.081 247.993L273.12 249.666L271.845 251.219ZM267.174 244.785L269.783 246.927L268.408 248.601C267.621 249.56 266.658 249.744 265.833 249.067C265.007 248.389 264.972 247.467 265.72 246.557L267.174 244.785Z"
            fill={color}
          />
          <path
            d="M242.297 266.297C240.396 267.791 239.994 269.845 241.294 271.499C243.021 273.696 245.078 272.579 246.756 271.919C247.789 271.506 248.691 271.256 249.402 272.135C249.929 272.779 249.75 273.619 248.935 274.259C248.133 274.89 247.197 274.867 246.451 273.995L244.699 275.373C246.058 277.001 248.146 277.117 249.985 275.671C251.898 274.167 252.4 272.215 251.185 270.694C249.574 268.645 247.73 269.296 246.155 269.935C244.885 270.454 243.763 270.956 242.958 269.932C242.414 269.241 242.544 268.339 243.421 267.651C244.433 266.855 245.62 266.841 246.469 267.87L248.284 266.444C246.857 264.63 244.494 264.57 242.297 266.297Z"
            fill={color}
          />
          <path
            d="M230.085 274.853C227.394 276.531 226.863 279.804 228.781 282.881C230.658 285.893 233.806 286.816 236.497 285.139C239.242 283.428 239.825 280.178 237.948 277.167C236.029 274.089 232.83 273.142 230.085 274.853ZM231.065 276.425C232.651 275.437 234.665 276.235 236.044 278.446C237.331 280.511 237.103 282.578 235.517 283.567C233.985 284.522 232.042 283.809 230.755 281.744C229.376 279.532 229.533 277.381 231.065 276.425Z"
            fill={color}
          />
          <path
            d="M216.778 286.832L222.499 292.608L225.419 291.256L220.537 280.713L218.5 281.657L222.01 289.236L215.693 282.957L214.511 283.504L215.201 292.355L211.704 284.804L209.667 285.748L214.549 296.29L217.47 294.937L216.778 286.832Z"
            fill={color}
          />
          <path
            d="M184.62 293.262C181.248 293.932 179.48 296.636 180.166 300.086C180.848 303.52 183.513 305.327 186.885 304.657L190.627 303.913L188.362 292.518L184.62 293.262ZM188.042 302.426L186.502 302.732C184.485 303.133 182.864 301.903 182.417 299.654C181.964 297.375 182.986 295.588 185.003 295.187L186.543 294.881L188.042 302.426Z"
            fill={color}
          />
          <path
            d="M165.49 295.87L165.669 297.824L171.141 297.321L171.429 300.448L166.473 300.903L166.652 302.858L171.608 302.402L171.845 304.982L166.405 305.482L166.584 307.436L174.261 306.731L173.198 295.162L165.49 295.87Z"
            fill={color}
          />
          <path
            d="M155.206 295.834C152.789 295.795 151.181 297.135 151.147 299.238C151.102 302.032 153.403 302.462 155.121 303.008C156.184 303.34 157.041 303.715 157.038 304.845C157.041 305.677 156.372 306.216 155.336 306.199C154.316 306.183 153.603 305.574 153.575 304.428L151.346 304.392C151.374 306.512 152.922 307.919 155.261 307.957C157.694 307.996 159.315 306.798 159.331 304.851C159.373 302.245 157.531 301.587 155.906 301.09C154.593 300.691 153.405 300.374 153.426 299.071C153.441 298.192 154.11 297.575 155.225 297.593C156.512 297.614 157.442 298.351 157.452 299.686L159.759 299.723C159.797 297.416 158 295.879 155.206 295.834Z"
            fill={color}
          />
          <path
            d="M143.035 295.458L142.02 307.032L144.257 307.228L145.271 295.654L143.035 295.458Z"
            fill={color}
          />
          <path
            d="M131.896 293.678C130.195 293.377 128.926 294.061 128.218 295.083L128.528 293.337L126.982 293.063L125.93 298.999L130.042 299.728L130.355 297.965L128.422 297.623C128.809 296.161 130.057 295.234 131.588 295.505C133.35 295.818 134.283 297.753 133.828 300.319C133.401 302.731 131.879 304.119 130.073 303.783C128.823 303.546 127.915 302.556 127.935 301.363L125.693 300.966C125.629 303.219 127.338 305.18 129.719 305.602C132.888 306.163 135.479 304.135 136.099 300.642C136.732 297.071 135.034 294.234 131.896 293.678Z"
            fill={color}
          />
          <path
            d="M113.227 293.101L115.648 302.143L117.845 302.796L121.158 291.661L119.006 291.02L116.727 298.68L114.251 289.606L112.114 288.97L108.801 300.105L110.953 300.746L113.227 293.101Z"
            fill={color}
          />
          <path
            d="M99.1291 283.862L98.3733 285.673L103.445 287.789L102.235 290.687L97.6423 288.77L96.8865 290.581L101.48 292.498L100.482 294.889L95.4397 292.785L94.684 294.596L101.798 297.564L106.272 286.842L99.1291 283.862Z"
            fill={color}
          />
          <path
            d="M91.6794 280.174L89.4783 284.232L88.1949 283.535C85.5315 282.09 88.2208 280.031 87.8154 278.078L85.8558 277.015L85.8183 277.084C86.1321 278.415 84.1036 280.673 85.43 282.607C84.2094 282.552 83.1771 283.171 82.4958 284.427C81.4702 286.317 82.0269 288.155 84.0969 289.278L88.1127 291.457L93.6528 281.245L91.6794 280.174ZM87.0752 288.661L85.1294 287.606C84.2875 287.149 84.0476 286.34 84.4968 285.512C84.946 284.684 85.7273 284.429 86.5415 284.871L88.5425 285.957L87.0752 288.661Z"
            fill={color}
          />
          <path
            d="M66.2782 263.028L58.8367 271.95L60.5608 273.388L68.0023 264.466L66.2782 263.028Z"
            fill={color}
          />
          <path
            d="M41.2762 246.102C40.387 245.648 39.3872 245.809 38.4335 246.577C37.0763 247.67 37.0792 249.301 38.4383 250.988L41.2746 254.509L50.3226 247.221L47.309 243.48C45.7727 241.573 43.935 241.218 42.321 242.518C41.0861 243.513 40.725 244.792 41.2762 246.102ZM40.1341 249.965C39.5235 249.207 39.5334 248.493 40.1448 248.001C40.8295 247.449 41.5678 247.54 42.1587 248.273L43.4488 249.875L41.3947 251.53L40.1341 249.965ZM47.3859 246.704L44.7571 248.821L43.3981 247.134C42.62 246.168 42.6364 245.187 43.4678 244.518C44.2993 243.848 45.2095 244.002 45.9481 244.919L47.3859 246.704Z"
            fill={color}
          />
          <path
            d="M37.439 229.513L35.7968 230.588L38.608 234.884L30.5286 240.171L31.7579 242.049L41.4794 235.688L37.439 229.513Z"
            fill={color}
          />
          <path
            d="M29.9325 215.708L21.4469 224.589L22.8106 227.224L34.9689 225.44L33.8793 223.335L30.6144 223.858L28.7023 220.163L31.022 217.813L29.9325 215.708ZM23.9796 224.94L27.2619 221.615L28.5967 224.195L23.9796 224.94Z"
            fill={color}
          />
          <path
            d="M21.8035 205.386L16.1066 212.812L16.9262 214.953L27.7761 210.799L26.9734 208.702L19.5104 211.559L25.1995 204.069L24.4024 201.987L13.5524 206.141L14.3551 208.238L21.8035 205.386Z"
            fill={color}
          />
          <path
            d="M20.0861 186.724L14.5562 193.018L9.02653 190.355L9.70417 192.953L15.3864 195.641L10.7226 196.857L11.2893 199.03L22.5311 196.097L21.9644 193.925L18.3792 194.86L16.6342 194.017L20.7954 189.443L20.0861 186.724Z"
            fill={color}
          />
          <path
            d="M14.2187 180.346L6.54971 181.514L6.92994 183.711L14.5427 182.218L14.2187 180.346ZM18.7737 179.414L16.1902 179.861L16.5597 181.996L19.1432 181.549L18.7737 179.414Z"
            fill={color}
          />
        </svg>
      </div>
    </>
  );
};

export default SnakkMedJBO;
