const MangoLassi: React.FC<{
  color?: string;
  className?: string;
  width?: number;
  height?: number;
}> = ({
  color = 'currentColor',
  className = '',
  width = 384,
  height = 384,
  ...delegated
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path d="M242.367 28.73L246.667 15.24L241.977 13.74L242.847 11L255.359 15L254.489 17.74L249.798 16.24L245.497 29.71L242.367 28.73Z" />
      <path d="M263.74 36.8701L264.19 32.0601L258.589 29.6501L255.379 33.2401L252.188 31.8701L264.3 18.5901L268.301 20.3101L266.931 38.2401L263.74 36.8701ZM260.57 27.3901L264.47 29.0701L265.11 22.2601L260.57 27.3901Z" />
      <path d="M282.593 47.08L292.124 32.98L301.486 39.3L299.875 41.68L293.244 37.24L291.114 40.38L297.115 44.47L295.505 46.85L289.504 42.77L286.923 46.58L293.594 51.09L291.994 53.47L282.593 47.08Z" />
      <path d="M314.408 49.6101L316.928 51.7201L306.036 64.7901L303.526 62.7001L305.446 49.0501L297.945 58.0501L295.425 55.9501L306.307 42.8701L308.887 45.0201L306.887 58.5801L314.408 49.6101Z" />
      <path d="M340.852 77.87L343.783 81.57L330.431 92.13L328.431 89.55L337.992 81.99L325.62 85.99L324.43 84.49L331.221 73.35L321.619 80.94L319.619 78.3601L332.971 67.8L335.892 71.5L329.621 81.62L340.852 77.87Z" />
      <path d="M338.842 105.5L341.983 101.81L338.782 96.62L334.062 97.7401L332.241 94.7401L349.824 90.8L352.104 94.5001L340.673 108.41L338.842 105.5ZM341.693 95.91L343.923 99.5301L348.364 94.3201L341.693 95.91Z" />
      <path d="M362.076 113.52L363.486 116.52L348.134 123.85L346.723 120.85L354.155 109.25L343.593 114.25L342.183 111.25L357.535 103.92L358.985 106.92L351.534 118.42L362.076 113.52Z" />
      <path d="M357.815 124.67C362.716 122.95 367.166 124.81 368.717 129.26C369.314 130.795 369.34 132.495 368.789 134.048C368.239 135.601 367.148 136.904 365.716 137.72L364.616 134.58C365.365 134.142 365.933 133.451 366.219 132.632C366.505 131.813 366.49 130.919 366.176 130.11C365.306 127.56 362.446 126.62 359.055 127.8C355.455 129.06 353.654 131.64 354.514 134.11C354.85 135.102 355.563 135.924 356.498 136.397C357.433 136.87 358.516 136.957 359.515 136.64L358.565 133.92L361.035 133.06L363.036 138.83L354.704 141.74L353.944 139.57L356.395 138.72C355.373 138.616 354.405 138.209 353.616 137.551C352.827 136.894 352.251 136.016 351.964 135.03C350.394 130.62 352.794 126.42 357.815 124.67Z" />
      <path d="M364.126 143.46C369.196 142.31 373.407 144.68 374.457 149.29C375.507 153.9 372.697 157.73 367.626 158.89C362.555 160.05 358.175 157.75 357.144 153.22C356.114 148.69 358.945 144.63 364.126 143.46ZM367.006 155.61C370.477 154.82 372.397 152.47 371.817 149.89C371.237 147.31 368.456 145.89 364.986 146.71C361.255 147.55 359.185 149.96 359.795 152.62C360.405 155.28 363.315 156.45 367.006 155.61Z" />
      <path d="M361.396 179.38L378.398 178.77L378.518 182.05L364.386 182.56L364.646 190.07L361.776 190.18L361.396 179.38Z" />
      <path d="M361.085 205.24L365.766 204.03L366.136 197.94L361.635 196.15L361.855 192.68L378.488 199.58L378.228 203.92L360.865 208.71L361.085 205.24ZM368.937 199.04L368.676 203.28L375.298 201.57L368.937 199.04Z" />
      <path d="M365.626 212.24L365.076 215.57C364.659 215.497 364.232 215.514 363.822 215.618C363.413 215.722 363.029 215.911 362.698 216.174C362.366 216.436 362.094 216.766 361.899 217.141C361.704 217.516 361.59 217.928 361.565 218.35C361.305 219.97 362.056 221.07 363.326 221.28C365.206 221.59 365.926 219.93 366.786 218.12C367.846 215.87 369.197 213.34 372.967 213.96C375.788 214.39 377.178 216.96 376.598 220.52C376.018 224.08 373.677 225.84 370.597 225.43L371.127 222.21C371.466 222.282 371.816 222.282 372.154 222.208C372.492 222.135 372.811 221.99 373.089 221.784C373.367 221.577 373.599 221.314 373.767 221.012C373.936 220.71 374.038 220.375 374.067 220.03C374.317 218.54 373.677 217.45 372.467 217.28C370.827 217.03 370.107 218.19 369.397 219.66C368.236 222.04 367.116 225.28 363.076 224.66C360.075 224.16 358.435 221.54 359.015 218.05C359.675 213.96 362.316 211.68 365.626 212.24Z" />
      <path d="M362.735 228.57L361.885 231.84C361.477 231.73 361.05 231.707 360.632 231.773C360.214 231.839 359.815 231.993 359.461 232.224C359.106 232.456 358.805 232.759 358.577 233.115C358.348 233.471 358.198 233.872 358.135 234.29C357.725 235.87 358.375 237.04 359.615 237.36C361.465 237.84 362.325 236.26 363.345 234.53C364.616 232.39 366.196 229.99 369.886 230.95C372.657 231.65 373.797 234.37 372.887 237.82C371.977 241.27 369.486 242.82 366.456 242.16L367.276 239C367.606 239.103 367.954 239.135 368.297 239.094C368.641 239.053 368.971 238.939 369.267 238.76C369.563 238.582 369.818 238.342 370.014 238.058C370.211 237.773 370.344 237.45 370.407 237.11C370.787 235.64 370.257 234.5 369.066 234.21C367.456 233.82 366.626 234.91 365.786 236.31C364.416 238.57 363.005 241.69 359.045 240.66C356.044 239.89 354.714 237.14 355.604 233.71C356.654 229.75 359.465 227.72 362.735 228.57Z" />
      <path d="M353.174 243.31L369.316 248.77L368.256 251.89L352.134 246.42L353.174 243.31Z" />
      <path d="M353.685 283.75L351.424 287.88L336.502 279.69L338.082 276.81L348.764 282.67L340.313 272.84L341.233 271.16L354.135 273.08L343.363 267.24L344.943 264.36L359.866 272.55L357.595 276.68L345.823 274.84L353.685 283.75Z" />
      <path d="M333.981 283.38L347.893 293.18L341.392 302.41L339.042 300.75L343.653 294.21L340.552 292.03L336.351 298.03L334.001 296.37L338.202 290.37L334.441 287.73L329.81 294.3L327.46 292.65L333.981 283.38Z" />
      <path d="M324.86 296.05L337.732 307.24L334.072 311.46C330.781 315.27 326.07 315.58 322.19 312.23C318.309 308.88 317.899 304.14 321.19 300.33L324.86 296.05ZM324.86 300.42L323.35 302.16C321.35 304.44 321.81 307.42 324.35 309.65C326.891 311.88 329.871 311.81 331.841 309.53L333.342 307.79L324.86 300.42Z" />
      <path d="M295.116 331.09C293.235 328.71 293.775 326.02 296.596 323.8L302.127 319.44L312.668 332.8L307.468 336.9C304.977 338.9 302.587 338.85 301.007 336.85C300.488 336.303 300.147 335.612 300.03 334.867C299.912 334.123 300.022 333.36 300.346 332.68C299.426 333.068 298.399 333.122 297.443 332.832C296.488 332.541 295.664 331.925 295.116 331.09V331.09ZM298.686 325.81C297.336 326.88 297.096 328.21 298.066 329.44C299.036 330.67 300.466 330.71 301.897 329.58L304.387 327.58L301.307 323.73L298.686 325.81ZM308.308 332.58L305.907 329.58L303.547 331.44C303.303 331.587 303.095 331.787 302.937 332.024C302.779 332.262 302.676 332.531 302.635 332.813C302.594 333.095 302.616 333.383 302.699 333.656C302.782 333.928 302.925 334.179 303.117 334.39C303.827 335.29 304.877 335.32 305.997 334.44L308.308 332.58Z" />
      <path d="M288.835 329.59L298.116 343.87L295.356 345.66L287.644 333.8L281.303 337.89L279.743 335.48L288.835 329.59Z" />
      <path d="M266.58 342.61L270.031 346L275.442 343.18L274.662 338.4L277.742 336.79L280.413 354.6L276.552 356.6L263.5 344.24L266.58 342.61ZM275.942 346.14L272.171 348.14L277.062 352.93L275.942 346.14Z" />
      <path d="M256.899 365.24L253.839 366.44L247.628 350.6L250.628 349.41L261.63 357.65L257.349 346.76L260.42 345.56L266.631 361.4L263.5 362.62L252.559 354.37L256.899 365.24Z" />
      <path d="M233.616 354.64L240.397 360.64L241.597 358.07L240.167 352.83L243.337 351.96L247.848 368.38L244.678 369.25L242.807 362.44L238.977 370.81L235.176 371.81L238.977 363.66L229.655 355.66L233.616 354.64Z" />
      <path d="M150.032 354.3L146.472 367.99L151.283 369.24L150.563 372.02L137.851 368.71L138.571 365.93L143.341 367.17L146.892 353.48L150.032 354.3Z" />
      <path d="M128.339 347.24L128.119 352.07L133.82 354.22L136.82 350.45L140.071 351.67L128.589 365.53L124.519 364L125.049 346L128.339 347.24ZM131.95 356.56L127.949 355.06L127.639 361.9L131.95 356.56Z" />
      <path d="M109.026 337.91L100.185 352.44L90.5332 346.58L92.0334 344.12L98.8645 348.24L100.865 345L94.6138 341.24L96.1041 338.78L102.335 342.57L104.725 338.64L97.8443 334.46L99.3446 332L109.026 337.91Z" />
      <path d="M77.0913 336.86L74.4609 334.86L84.7325 321.29L87.3329 323.29L86.0728 337L93.1339 327.66L95.7543 329.66L85.4827 343.24L82.8122 341.24L84.1324 327.6L77.0913 336.86Z" />
      <path d="M49.3571 309.91L46.2666 306.36L59.0986 295.17L61.2689 297.65L52.0775 305.65L64.2694 301.07L65.5196 302.5L59.2686 313.96L68.4901 305.96L70.6504 308.44L57.7984 319.58L54.6979 316.03L60.4788 305.62L49.3571 309.91Z" />
      <path d="M50.0665 282.24L47.066 286.07L50.5065 291.07L55.1673 289.72L57.1676 292.59L39.8149 297.35L37.3545 293.76L48.1162 279.34L50.0665 282.24ZM47.6761 291.95L45.2657 288.42L41.0851 293.84L47.6761 291.95Z" />
      <path d="M26.4733 275.3L24.9131 272.4L39.9154 264.34L41.4657 267.24L34.5846 279.19L44.9062 273.65L46.4565 276.55L31.4541 284.55L29.8639 281.55L36.7749 269.7L26.4733 275.3Z" />
      <path d="M30.2036 263.95C25.3828 265.95 20.8421 264.25 19.0818 259.88C18.4042 258.365 18.2972 256.656 18.7806 255.068C19.264 253.48 20.3052 252.121 21.7122 251.24L22.9624 254.34C22.2358 254.814 21.7011 255.531 21.4538 256.362C21.2065 257.194 21.2627 258.086 21.6122 258.88C22.6124 261.38 25.5128 262.19 28.8334 260.88C32.3839 259.45 34.0642 256.79 33.084 254.36C32.7013 253.388 31.9534 252.604 31.0002 252.176C30.047 251.748 28.9641 251.71 27.9832 252.07L29.0634 254.74L26.633 255.74L24.3427 250.06L32.5339 246.76L33.3941 248.89L30.9837 249.89C32.011 249.947 32.9981 250.308 33.8192 250.928C34.6403 251.548 35.2583 252.398 35.5944 253.37C37.3347 257.65 35.1343 261.97 30.2036 263.95Z" />
      <path d="M23.0125 245.49C18.0117 246.88 13.6811 244.71 12.4109 240.14C11.1407 235.57 13.7711 231.63 18.7819 230.24C23.7926 228.85 28.2833 230.92 29.5235 235.4C30.7637 239.88 28.1333 244.07 23.0125 245.49ZM19.562 233.49C16.1314 234.49 14.3212 236.88 15.0313 239.43C15.7414 241.98 18.5718 243.24 22.0324 242.29C25.7129 241.29 27.6733 238.77 26.9431 236.13C26.213 233.49 23.2626 232.46 19.562 233.48V233.49Z" />
      <path d="M24.0425 209.47L7.03981 210.89L6.75977 207.61L20.862 206.43L20.2619 198.94L23.1323 198.7L24.0425 209.47Z" />
      <path d="M23.1127 183.69L18.492 185.13L18.4119 191.24L22.9927 192.82L22.9426 196.24L6 190.18L6.06001 185.83L23.1627 180.24L23.1127 183.69ZM15.5715 190.24L15.6315 185.99L9.09048 187.99L15.5715 190.24Z" />
      <path d="M18.2614 176.88L18.6515 173.52C19.0704 173.572 19.4956 173.535 19.8993 173.412C20.303 173.288 20.6762 173.081 20.9943 172.804C21.3124 172.526 21.5684 172.185 21.7455 171.801C21.9226 171.418 22.0168 171.002 22.022 170.58C22.212 168.96 21.4119 167.89 20.1317 167.74C18.2314 167.53 17.6013 169.21 16.8312 171.07C15.831 173.36 14.6409 175.95 10.8303 175.51C7.98982 175.21 6.47959 172.67 6.88965 169.13C7.29972 165.59 9.56007 163.68 12.6506 163.94L12.2605 167.24C11.9187 167.181 11.5682 167.196 11.2327 167.284C10.8972 167.372 10.5844 167.531 10.3154 167.75C10.0463 167.969 9.82719 168.242 9.67276 168.553C9.51833 168.863 9.43217 169.203 9.42005 169.55C9.25002 171.06 9.94013 172.11 11.1503 172.22C12.8006 172.39 13.4707 171.22 14.1508 169.69C15.1509 167.27 16.1511 163.98 20.2117 164.45C23.2822 164.8 25.0025 167.34 24.5924 170.86C24.1023 174.87 21.602 177.24 18.2614 176.88Z" />
      <path d="M20.3319 160.41L21.042 157.11C21.4555 157.203 21.884 157.207 22.2992 157.122C22.7143 157.037 23.1068 156.865 23.4508 156.617C23.7947 156.37 24.0823 156.052 24.2946 155.686C24.5069 155.319 24.6392 154.911 24.6826 154.49C25.0226 152.9 24.3225 151.76 23.0623 151.49C21.192 151.09 20.4019 152.71 19.4618 154.49C18.2816 156.68 16.8214 159.15 13.0808 158.35C10.2903 157.78 9.02014 155.11 9.76026 151.62C10.5004 148.13 12.9408 146.44 16.0012 147L15.3111 150.24C14.9818 150.158 14.639 150.146 14.3046 150.204C13.9703 150.262 13.6518 150.39 13.3694 150.578C13.0871 150.766 12.8471 151.011 12.6648 151.297C12.4825 151.583 12.3619 151.905 12.3107 152.24C11.9906 153.72 12.5807 154.83 13.7809 155.07C15.4011 155.39 16.1813 154.26 16.9614 152.83C18.2316 150.51 19.5018 147.32 23.5024 148.18C26.5029 148.82 27.9931 151.51 27.253 154.97C26.3629 158.97 23.6424 161.12 20.3319 160.41Z" />
      <path d="M29.2632 145.24L12.9307 140.44L13.8608 137.28L30.1934 142.09L29.2632 145.24Z" />
      <path d="M26.7937 104.88L28.864 100.64L44.1564 108.11L42.7162 111.11L31.7145 105.76L40.7159 115.18L39.8757 116.9L26.8737 115.59L37.8754 120.97L36.4352 123.97L21.1328 116.5L23.2631 112.17L35.105 113.45L26.7937 104.88Z" />
      <path d="M46.496 104.24L32.1338 95.1501L38.1847 85.6201L40.6151 87.1601L36.3244 93.9201L39.5249 95.9201L43.4356 89.7701L45.8659 91.3401L41.9553 97.5001L45.8359 99.9601L50.1566 93.1701L52.577 94.7101L46.496 104.24Z" />
      <path d="M55.0181 91.2401L41.626 80.6801L45.0765 76.2901C48.197 72.2901 52.8777 71.8001 56.9184 74.9601C60.959 78.1201 61.5791 82.8401 58.4686 86.8001L55.0181 91.2401ZM54.788 86.8701L56.2083 85.0701C58.0685 82.7001 57.4985 79.7301 54.818 77.6301C52.1376 75.5301 49.2072 75.7301 47.3469 78.1001L45.9166 79.9101L54.788 86.8701Z" />
      <path d="M83.0424 54.7901C85.0427 57.0801 84.6227 59.7901 81.9123 62.1401L76.6014 66.7601L65.4297 53.9201L70.4305 49.5701C72.8208 47.4901 75.2112 47.4001 76.8915 49.3201C77.4342 49.844 77.8058 50.5197 77.9575 51.2585C78.1092 51.9973 78.034 52.7648 77.7416 53.4601C78.6409 53.0253 79.6645 52.9203 80.6334 53.1634C81.6023 53.4065 82.455 53.9823 83.0424 54.7901ZM69.8004 53.9301L72.3408 56.8501L74.6111 54.8501C74.855 54.6931 75.0602 54.4829 75.2113 54.2353C75.3623 53.9877 75.4553 53.709 75.4832 53.4204C75.5111 53.1317 75.4732 52.8404 75.3724 52.5684C75.2716 52.2965 75.1105 52.0509 74.9012 51.8501C74.141 50.9801 73.1009 51.0001 72.0207 51.9401L69.8004 53.9301ZM79.7319 60.2401C81.0321 59.1101 81.2122 57.7701 80.182 56.5901C79.1518 55.4101 77.7216 55.4401 76.3514 56.5901L73.951 58.6701L77.2015 62.4101L79.7319 60.2401Z" />
      <path d="M89.383 56L79.4414 42.24L82.1118 40.24L90.3731 51.75L96.4841 47.36L98.1543 49.69L89.383 56Z" />
      <path d="M110.996 41.9499L107.396 38.7299L102.125 41.7999L103.125 46.5299L100.125 48.2799L96.624 30.6299L100.375 28.4399L113.997 40.2399L110.996 41.9499ZM101.485 38.8699L105.165 36.7299L100.055 32.1699L101.485 38.8699Z" />
      <path d="M119.597 18.9201L122.598 17.5701L129.599 33.1101L126.598 34.4401L115.187 26.7301L119.967 37.4101L116.967 38.7601L109.966 23.2201L113.026 21.8501L124.348 29.5801L119.597 18.9201Z" />
      <path d="M143.352 28.37L136.28 22.69L135.21 25.32L136.891 30.48L133.77 31.48L128.489 15.32L131.62 14.32L133.81 21.03L137.281 12.46L140.971 11.24L137.571 19.56L147.272 27.09L143.352 28.37Z" />
    </svg>
  );
};

export default MangoLassi;
