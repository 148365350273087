import { s, c, t } from '../val.config';

export const schema = s.array(s.object({ pictures: s.array(s.image()) }));

export type DarkSide = t.inferSchema<typeof schema>[0];

export default c.define('/content/darkside.val.ts', schema, [
  {
    pictures: [
      c.file(
        '/public/images/d16b762bb1d4d4169f0bc73c5ba20bed7ddcfd48-769x483.gif',
        {
          width: 769,
          height: 483,
          sha256:
            '022ed7b0ee836f0521b3e99a344e20f2b1aa36a390e6e7c687d692310cb844e8',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/b92675aa94d0be5a1b6a4462a161f964b0fc994a-767x475.jpg',
        {
          width: 767,
          height: 475,
          sha256:
            '45dab9f00ca74629c64e5c174b2e84aa2ce58f2c2731ec31ecfa8ddf729c4186',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          sha256:
            '2331c00e00acb718c49985d02f61595f67bd126896b1b6affbfc71086ca3109c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/f224b5c822d70774292244ed960190c86930351d-800x533.jpg',
        {
          width: 800,
          height: 533,
          sha256:
            '2f2ccbfbb0949f6eb5a802dab6b906de17602e901f279f20293f697edbc43930',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            '3e50bb4d66c25c3f651165387220c01dfdfed726c17032a81db5ff376b5809c7',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            'db8dd40b18d2022b4c4fc50f162911ce6b479cfd39eb6e1d254a7afbd1ad7585',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6a50fbedb88dc54741bb4d7b9ac0a2cee831a2c6-672x732.gif',
        {
          width: 672,
          height: 732,
          sha256:
            'd285241d2cb9c32d2bb677598a0484055c3b51431a4e8fb2cfbd4ec1e9d5fa5a',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          sha256:
            '3097669393db5df08dcce7861a45bf1d022d14f9b8cf3c6c1fa71d3a0fd5c2b2',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/5f7792d782afe55df48f8567803ff198e5929486-900x600.jpg',
        {
          width: 900,
          height: 600,
          sha256:
            '420af304f09e5e9ba514293126f04de5332dc42e8f4f6737c411d888c1d88c53',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          sha256:
            'ac35c8bac2c11a0d572f7e02bae0a9417cc9832a52960cbecd0455a5f4fa9347',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1c1e024ce34b24a871c40380012ea2e2462a7979-900x678.gif',
        {
          width: 900,
          height: 678,
          sha256:
            '040681a1383275347b044136dc7de931812f835dcecd696daefe6045ca635091',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/fd663f7884ea244c7f3e40f4c4061602cec67f76-766x476.jpg',
        {
          width: 766,
          height: 476,
          sha256:
            '70902a1f01c4f62f0e1191a2de6a53ac19c4e7c1460adde6629b8533501cab8c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/765f6f138f9097d8342fc184630a9b6b4091f5b2-770x481.jpg',
        {
          width: 770,
          height: 481,
          sha256:
            'a6b7dbfa6e107d7b4add88ee593dc26629e178ac7b60ae26c57b984bf0eceba0',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/67a72edd805f3fd4a384ee54120facd1b1523da2-770x484.jpg',
        {
          width: 770,
          height: 484,
          sha256:
            'cf372cd0d2469f559f2d48fffe765dd4a0a7bc46ad475282666443fe7c939b06',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b5df69687309ea021eddf9b2598fc19f986be124-772x480.jpg',
        {
          width: 772,
          height: 480,
          sha256:
            'f4d81309bffad9215a7f9c6e5f857e543313216b2975f6945b0f3d3beda99ae1',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          sha256:
            'ac35c8bac2c11a0d572f7e02bae0a9417cc9832a52960cbecd0455a5f4fa9347',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          sha256:
            '3097669393db5df08dcce7861a45bf1d022d14f9b8cf3c6c1fa71d3a0fd5c2b2',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/4a38aea4e3d2e6fe4434b042f3d4a113ccac7d1e-768x1024.jpg',
        {
          width: 768,
          height: 1024,
          sha256:
            '04bb0fd7895f094353636dbbd50a8ab59b525bfe80cbf024088e4a722f4ee166',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/e96be64a8a265e1a3477f47958dd8ad774bc8ba7-851x579.webp',
        {
          width: 851,
          height: 579,
          sha256:
            '7ffa9447b4972ba3899b98373e93d6d9569e6e1a46387b4e705f4966778f99d4',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            'db8dd40b18d2022b4c4fc50f162911ce6b479cfd39eb6e1d254a7afbd1ad7585',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          sha256:
            '2331c00e00acb718c49985d02f61595f67bd126896b1b6affbfc71086ca3109c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/dd1a00d09105a48318f07f6fe59b62c2fda55686-3601x3006.jpg',
        {
          width: 3601,
          height: 3006,
          sha256:
            '2a4d97cfc5200aa37651cd7be2e6647cea9f5320970c697a83aafff8694ac300',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/7bcbb7d374d7568df1266fe5ac3c4bec805733f0-600x684.jpg',
        {
          width: 600,
          height: 684,
          sha256:
            'c6e01020572119edcedd42c8b70510b352a3c117cab102601ff5babc8855fdca',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            '3e50bb4d66c25c3f651165387220c01dfdfed726c17032a81db5ff376b5809c7',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/4f1002b09919822ecdbd67be8c3349189bb08400-768x477.jpg',
        {
          width: 768,
          height: 477,
          sha256:
            '86ff5d185a0b757bcfbda5fe74b6329b2d39a00ebdf632b3e79cd36e6fb50689',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/c317b63ccc992fe212ab4d178e917bfb9a22f87c-722x1089.jpg',
        {
          width: 722,
          height: 1089,
          sha256:
            'ce2b9880f456ee8a3c3e23fd9ba09df49af0fad248f07beb96a12692020cb111',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6019d57c37c62ffaf81ed8210f39a69a6ac9bad3-768x476.jpg',
        {
          width: 768,
          height: 476,
          sha256:
            'fb99155608d0f2d5357499ff43b55b3045e780721c8b8206bbd96a3b0fc1222d',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/a9f9e285efd8ba6a8146e663023574d08075bde9-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          sha256:
            'bccf6d0ee4922092e5b4991a71d53d8aa98b0b6391f091e2a97200e4c94e0f24',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6d059cc4be5eb0457721c4cf72653422c3fa9e54-3024x4032.jpg',
        {
          width: 3024,
          height: 4032,
          sha256:
            'cf5875a2c5addea240ec3981ca469f6dfb2ebed102722f2ef9926e66d7a5ba58',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/f414b152cb49a65509b019df0c4ebf1f5db1ce85-2048x1638.jpg',
        {
          width: 2048,
          height: 1638,
          sha256:
            'a57fd903e96e396c18ba0eb08c1c9e93f2f9b548fac22a2d9542f9a245a549ac',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/5cddbe34565f434a6e1cb21f8a012b79395a1bd7-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          sha256:
            '717072edfdd4d585d13c01d6cdd2e12a71abb47701f3d58fea44e056d43e2b58',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/c2ca1d180f4df9e7a1877c728976aff2f3e0d5f2-4032x3024.jpg',
        {
          width: 4032,
          height: 3024,
          sha256:
            '4edb3e00e32763844023eca94d036acf5c25901cfd3c441fae9a7d4abe76e3e8',
          mimeType: 'image/jpeg',
        }
      ),
    ],
  },
  {
    pictures: [
      c.file(
        '/public/images/d16b762bb1d4d4169f0bc73c5ba20bed7ddcfd48-769x483.gif',
        {
          width: 769,
          height: 483,
          sha256:
            '022ed7b0ee836f0521b3e99a344e20f2b1aa36a390e6e7c687d692310cb844e8',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/b92675aa94d0be5a1b6a4462a161f964b0fc994a-767x475.jpg',
        {
          width: 767,
          height: 475,
          sha256:
            '45dab9f00ca74629c64e5c174b2e84aa2ce58f2c2731ec31ecfa8ddf729c4186',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          sha256:
            '2331c00e00acb718c49985d02f61595f67bd126896b1b6affbfc71086ca3109c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/f224b5c822d70774292244ed960190c86930351d-800x533.jpg',
        {
          width: 800,
          height: 533,
          sha256:
            '2f2ccbfbb0949f6eb5a802dab6b906de17602e901f279f20293f697edbc43930',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            '3e50bb4d66c25c3f651165387220c01dfdfed726c17032a81db5ff376b5809c7',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            'db8dd40b18d2022b4c4fc50f162911ce6b479cfd39eb6e1d254a7afbd1ad7585',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6a50fbedb88dc54741bb4d7b9ac0a2cee831a2c6-672x732.gif',
        {
          width: 672,
          height: 732,
          sha256:
            'd285241d2cb9c32d2bb677598a0484055c3b51431a4e8fb2cfbd4ec1e9d5fa5a',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          sha256:
            '3097669393db5df08dcce7861a45bf1d022d14f9b8cf3c6c1fa71d3a0fd5c2b2',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/5f7792d782afe55df48f8567803ff198e5929486-900x600.jpg',
        {
          width: 900,
          height: 600,
          sha256:
            '420af304f09e5e9ba514293126f04de5332dc42e8f4f6737c411d888c1d88c53',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          sha256:
            'ac35c8bac2c11a0d572f7e02bae0a9417cc9832a52960cbecd0455a5f4fa9347',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1c1e024ce34b24a871c40380012ea2e2462a7979-900x678.gif',
        {
          width: 900,
          height: 678,
          sha256:
            '040681a1383275347b044136dc7de931812f835dcecd696daefe6045ca635091',
          mimeType: 'image/gif',
        }
      ),
      c.file(
        '/public/images/fd663f7884ea244c7f3e40f4c4061602cec67f76-766x476.jpg',
        {
          width: 766,
          height: 476,
          sha256:
            '70902a1f01c4f62f0e1191a2de6a53ac19c4e7c1460adde6629b8533501cab8c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/765f6f138f9097d8342fc184630a9b6b4091f5b2-770x481.jpg',
        {
          width: 770,
          height: 481,
          sha256:
            'a6b7dbfa6e107d7b4add88ee593dc26629e178ac7b60ae26c57b984bf0eceba0',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/67a72edd805f3fd4a384ee54120facd1b1523da2-770x484.jpg',
        {
          width: 770,
          height: 484,
          sha256:
            'cf372cd0d2469f559f2d48fffe765dd4a0a7bc46ad475282666443fe7c939b06',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b5df69687309ea021eddf9b2598fc19f986be124-772x480.jpg',
        {
          width: 772,
          height: 480,
          sha256:
            'f4d81309bffad9215a7f9c6e5f857e543313216b2975f6945b0f3d3beda99ae1',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          sha256:
            'ac35c8bac2c11a0d572f7e02bae0a9417cc9832a52960cbecd0455a5f4fa9347',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          sha256:
            '3097669393db5df08dcce7861a45bf1d022d14f9b8cf3c6c1fa71d3a0fd5c2b2',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/4a38aea4e3d2e6fe4434b042f3d4a113ccac7d1e-768x1024.jpg',
        {
          width: 768,
          height: 1024,
          sha256:
            '04bb0fd7895f094353636dbbd50a8ab59b525bfe80cbf024088e4a722f4ee166',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/e96be64a8a265e1a3477f47958dd8ad774bc8ba7-851x579.webp',
        {
          width: 851,
          height: 579,
          sha256:
            '7ffa9447b4972ba3899b98373e93d6d9569e6e1a46387b4e705f4966778f99d4',
          mimeType: 'image/webp',
        }
      ),
      c.file(
        '/public/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            'db8dd40b18d2022b4c4fc50f162911ce6b479cfd39eb6e1d254a7afbd1ad7585',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          sha256:
            '2331c00e00acb718c49985d02f61595f67bd126896b1b6affbfc71086ca3109c',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/dd1a00d09105a48318f07f6fe59b62c2fda55686-3601x3006.jpg',
        {
          width: 3601,
          height: 3006,
          sha256:
            '2a4d97cfc5200aa37651cd7be2e6647cea9f5320970c697a83aafff8694ac300',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/7bcbb7d374d7568df1266fe5ac3c4bec805733f0-600x684.jpg',
        {
          width: 600,
          height: 684,
          sha256:
            'c6e01020572119edcedd42c8b70510b352a3c117cab102601ff5babc8855fdca',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          sha256:
            '3e50bb4d66c25c3f651165387220c01dfdfed726c17032a81db5ff376b5809c7',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/4f1002b09919822ecdbd67be8c3349189bb08400-768x477.jpg',
        {
          width: 768,
          height: 477,
          sha256:
            '86ff5d185a0b757bcfbda5fe74b6329b2d39a00ebdf632b3e79cd36e6fb50689',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/c317b63ccc992fe212ab4d178e917bfb9a22f87c-722x1089.jpg',
        {
          width: 722,
          height: 1089,
          sha256:
            'ce2b9880f456ee8a3c3e23fd9ba09df49af0fad248f07beb96a12692020cb111',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6019d57c37c62ffaf81ed8210f39a69a6ac9bad3-768x476.jpg',
        {
          width: 768,
          height: 476,
          sha256:
            'fb99155608d0f2d5357499ff43b55b3045e780721c8b8206bbd96a3b0fc1222d',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/a9f9e285efd8ba6a8146e663023574d08075bde9-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          sha256:
            'bccf6d0ee4922092e5b4991a71d53d8aa98b0b6391f091e2a97200e4c94e0f24',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/6d059cc4be5eb0457721c4cf72653422c3fa9e54-3024x4032.jpg',
        {
          width: 3024,
          height: 4032,
          sha256:
            'cf5875a2c5addea240ec3981ca469f6dfb2ebed102722f2ef9926e66d7a5ba58',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/f414b152cb49a65509b019df0c4ebf1f5db1ce85-2048x1638.jpg',
        {
          width: 2048,
          height: 1638,
          sha256:
            'a57fd903e96e396c18ba0eb08c1c9e93f2f9b548fac22a2d9542f9a245a549ac',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/5cddbe34565f434a6e1cb21f8a012b79395a1bd7-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          sha256:
            '717072edfdd4d585d13c01d6cdd2e12a71abb47701f3d58fea44e056d43e2b58',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/c2ca1d180f4df9e7a1877c728976aff2f3e0d5f2-4032x3024.jpg',
        {
          width: 4032,
          height: 3024,
          sha256:
            '4edb3e00e32763844023eca94d036acf5c25901cfd3c441fae9a7d4abe76e3e8',
          mimeType: 'image/jpeg',
        }
      ),
      c.file(
        '/public/images/ac61b04793b089a3d19393f29f5d4399019e394e-600x1100.gif',
        {
          width: 600,
          height: 1100,
          sha256:
            '7f46a3a56aeebc32de2dc0acf973531723403e46731162ff73a9a7cc5bdf4287',
          mimeType: 'image/gif',
        }
      ),
    ],
  },
]);
