const GoodSalary: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      id="Layer_11"
      data-name="Layer 11"
      xmlns="http://www.w3.org/2000/svg"
      width="383.94"
      height="372.72"
      viewBox="0 0 383.94 372.72"
      fill={color}
      className={className}
    >
      <g>
        <path d="M206.18,12.74c-.13,3-2.3,4.72-5.88,4.56l-7-.3L194,0l6.62.28c3.17.14,5,1.71,4.86,4.26a3.73,3.73,0,0,1-2.22,3.59A4.26,4.26,0,0,1,206.18,12.74ZM200,14.41c1.72.08,2.77-.78,2.84-2.34s-1-2.53-2.81-2.6l-3.17-.14-.21,4.94ZM197.16,3,197,6.87,200,7a1.93,1.93,0,0,0,2.25-1.95c.05-1.15-.73-1.85-2.15-1.91Z" />
        <path d="M209.91,17.94,212.3,1.08l6.63.93c3.41.49,5.12,2.73,4.68,5.85a4.56,4.56,0,0,1-3.1,4c2.81,2,1.18,6.11,2.29,7.78v.11l-3.24-.45c-1.56-2.48,1.08-6.68-3.31-7.3l-2.12-.3-1,6.7Zm4.61-9.09,3.3.47a2.06,2.06,0,0,0,2.48-1.93,2.1,2.1,0,0,0-1.94-2.55l-3.21-.45Z" />
        <path d="M237.29,23.17,237,18.35,231,16.88,228.45,21l-3.37-.84L234.87,5l4.22,1L240.66,24Zm-4.68-8.84,4.13,1-.49-6.83Z" />
        <path d="M257.51,30.17l7.05-15.49,3,1.36L261.7,28.91,268.54,32l-1.19,2.61Z" />
        <path d="M292.48,29.29l-2.85,1.13a9.57,9.57,0,0,1-1.39,6.14c-2.67,4.59-7.28,6.14-11.29,3.8a7.73,7.73,0,0,1-3.29-3.62l-1.31.51-2-1.16,2.8-1.11a9.84,9.84,0,0,1,1.41-6.39c2.62-4.49,7.23-5.95,11.32-3.57a7.61,7.61,0,0,1,3.27,3.66l1.35-.54Zm-16.17,4.44,10.06-4a3.88,3.88,0,0,0-1.85-2.4c-2.36-1.38-5.19-.27-7,2.81A8.85,8.85,0,0,0,276.31,33.73Zm10.18-2.07-10,4A4,4,0,0,0,278.32,38c2.28,1.34,5.18.07,7.1-3.23A7.74,7.74,0,0,0,286.49,31.66Z" />
        <path d="M305.86,38.88l2.64,2L298.35,54.5l-2.62-1.94,1.15-13.74-7,9.4-2.64-2L297.41,32.6l2.69,2-1.21,13.66Z" />
        <path d="M321.1,52l2.42,2.23L312,66.75l-2.39-2.22L312.17,51l-7.94,8.6-2.42-2.23,11.55-12.5,2.47,2.28L313.17,60.6Z" />
        <path d="M313,72c.88.82,1.72-.07,2.78-1l-.89-1,2.81-2.62,2.17,2.32L317,72.27c-2.44,2.27-3.89,1.83-5,.52Z" />
        <path d="M329.09,86.94l14.07-9.59,6.26,9.18L347,88.15l-4.4-6.46-3.2,2.17,4,5.88-2.36,1.61-4-5.88L331,89.65Z" />
        <path d="M344.66,94.7c4.57-2.48,9.27-1.34,11.53,2.82s.59,8.61-4,11.09-9.41,1.47-11.63-2.62S340,97.23,344.66,94.7Zm6.06,10.92c3.14-1.7,4.35-4.48,3.09-6.8s-4.31-2.92-7.44-1.22c-3.36,1.82-4.7,4.69-3.4,7.1S347.37,107.44,350.72,105.62Z" />
        <path d="M346.3,116.42,362,109.94l2.55,6.19c1.31,3.19.25,5.79-2.67,7a4.52,4.52,0,0,1-5-.64c-.29,3.42-4.68,4.11-5.55,5.92l-.11,0-1.24-3c1.34-2.6,6.3-2.45,4.61-6.55l-.81-2-6.26,2.57Zm10.17-.63,1.27,3.09a2.06,2.06,0,0,0,2.91,1.16,2.1,2.1,0,0,0,1.22-3l-1.23-3Z" />
        <path d="M356.53,145.88l16.68-3.39,1.11,5.48c1,4.93-1.63,8.85-6.66,9.87s-9-1.55-10-6.48Zm3.47,2.65.46,2.25c.6,3,3.22,4.44,6.56,3.76s5.08-3,4.48-6l-.46-2.25Z" />
        <path d="M359.83,163.57l16.94-1.72,1.14,11.24-2.86.29-.81-8-3.78.38.74,7.26-2.86.29-.74-7.26-4.58.46.82,8-2.87.29Z" />
        <path d="M361.22,182.9l14.15-.23-.08-4.92,2.87,0,.21,13.13-2.87,0-.08-4.92-14.14.23Z" />
        <path d="M365.08,209.46l-.56,3.33a3,3,0,0,0-3.52,2.76c-.28,1.61.47,2.72,1.74,2.93,1.88.32,2.6-1.33,3.47-3.14,1.08-2.25,2.44-4.77,6.2-4.13,2.82.45,4.19,3.06,3.6,6.58s-2.95,5.3-6,4.88l.55-3.22a2.44,2.44,0,0,0,3-2.16c.25-1.5-.38-2.58-1.58-2.76-1.64-.26-2.37.9-3.09,2.37-1.17,2.36-2.3,5.6-6.33,4.92-3-.51-4.63-3.14-4-6.63C359.13,211.16,361.75,208.89,365.08,209.46Z" />
        <path d="M354.31,234.64l8.63-2.76-1.6-2.35-5.23-1.43.86-3.17,16.42,4.49-.87,3.17-6.81-1.86L371,238.3l-1,3.79-5.07-7.42-11.62,4Z" />
        <path d="M348,252.2l4.83.28,2.22-5.68-3.73-3.09,1.26-3.23,13.72,11.66-1.58,4.05-18-.76Zm9.37-3.5-1.55,4,6.84.4Z" />
        <path d="M345.29,258.4l15.18,7.7L359,269l-12.61-6.4-3.41,6.7L340.4,268Z" />
        <path d="M330.64,283.62l13.61,10.2-6.66,8.89-2.3-1.72,4.69-6.26-3.09-2.32-4.26,5.69-2.29-1.71,4.27-5.69-5.95-4.45Z" />
        <path d="M328.35,300.84c3.83,3.52,4.27,8.34,1.07,11.83s-7.95,3.34-11.78-.17-4.44-8.43-1.3-11.85S324.44,297.25,328.35,300.84ZM320,310.12c2.63,2.41,5.65,2.66,7.44.71s1.37-5-1.26-7.43c-2.81-2.59-6-2.93-7.82-.91S317.17,307.54,320,310.12Z" />
        <path d="M308.3,309.43l11.33,12.7-2.46,2.19-9.41-10.56-5.61,5-1.91-2.15Z" />
        <path d="M289.26,324.94l8.48,3.18.2-2.83-3.21-4.37,2.65-1.95,10.08,13.71-2.65,1.95-4.18-5.69-.58,9.19-3.17,2.33.63-9L286,327.38Z" />
        <path d="M270.53,336.48l7.29,15.38-3,1.41L272,347.32l-6.13,2.91,2.82,5.95-3,1.4-7.3-15.37,3-1.41,3.25,6.83,6.13-2.9-3.25-6.84Z" />
        <path d="M243.6,347.66l2.93,3.85,5.75-2-.08-4.84,3.28-1.15.07,18-4.1,1.43L240.32,348.8Zm8.75,4.83-4,1.41,4.14,5.44Z" />
        <path d="M180.84,360.19c.08-3,2.22-4.75,5.8-4.66l7,.18-.43,17-6.62-.17c-3.18-.08-5-1.62-4.94-4.17a3.74,3.74,0,0,1,2.17-3.63A4.26,4.26,0,0,1,180.84,360.19Zm6.12-1.78c-1.72,0-2.76.83-2.8,2.39s1,2.51,2.86,2.56l3.17.08.13-4.94ZM190,369.76l.1-3.86-3-.08a1.92,1.92,0,0,0-2.21,2c0,1.15.76,1.83,2.18,1.87Z" />
        <path d="M177,355.06,174.91,372l-6.64-.82c-3.43-.43-5.17-2.64-4.78-5.76a4.54,4.54,0,0,1,3-4.06c-2.84-1.92-1.28-6.09-2.42-7.73v-.12l3.24.4c1.6,2.45-1,6.7,3.44,7.24l2.12.26.83-6.71Zm-4.45,9.16-3.31-.41a2.05,2.05,0,0,0-2.44,2,2.1,2.1,0,0,0,2,2.51l3.22.4Z" />
        <path d="M149.55,350.3l.43,4.82,5.94,1.36,2.51-4.14,3.39.77-9.53,15.28-4.23-1-1.89-17.9Zm4.84,8.75-4.15-.95.61,6.82Z" />
        <path d="M129.19,343.64l-6.78,15.61-3-1.32L125,345l-6.9-3,1.15-2.64Z" />
        <path d="M94.26,345.14,97.09,344a9.54,9.54,0,0,1,1.28-6.16c2.6-4.63,7.17-6.26,11.22-4a7.67,7.67,0,0,1,3.36,3.56l1.3-.54,2,1.13-2.79,1.15a9.8,9.8,0,0,1-1.29,6.42c-2.54,4.53-7.12,6.08-11.26,3.77a7.61,7.61,0,0,1-3.33-3.61l-1.34.57Zm5.94-2.48,10-4.14a4.08,4.08,0,0,0-1.9-2.36c-2.31-1.29-5.18,0-7,3.36A7.77,7.77,0,0,0,100.2,342.66Zm10.15-2.25-10,4.13a3.92,3.92,0,0,0,1.89,2.38c2.39,1.33,5.2.17,6.94-2.94A8.91,8.91,0,0,0,110.35,340.41Z" />
        <path d="M80.71,335.77,78,333.85,88,320l2.65,1.9-.91,13.75,6.82-9.51,2.67,1.91L89.28,341.9,86.55,340l1-13.68Z" />
        <path d="M65.24,322.88l-2.46-2.2L74.12,308l2.44,2.18-2.38,13.57L82,315l2.45,2.19L73.09,329.89l-2.5-2.23L73,314.16Z" />
        <path d="M73,302.82c-.89-.8-1.72.1-2.77,1.1l.91.95-2.77,2.66-2.2-2.29L69,302.58c2.4-2.31,3.86-1.89,4.94-.61Z" />
        <path d="M56.65,288.11l-13.9,9.82-6.41-9.07,2.35-1.66,4.51,6.38,3.16-2.23-4.1-5.8,2.33-1.65,4.1,5.81,6.07-4.29Z" />
        <path d="M41,280.62c-4.52,2.55-9.24,1.5-11.57-2.63s-.73-8.59,3.8-11.15,9.38-1.62,11.66,2.42S45.59,278,41,280.62ZM34.73,269.8c-3.11,1.76-4.27,4.55-3,6.86s4.35,2.84,7.45,1.09c3.33-1.87,4.62-4.77,3.28-7.15S38.05,267.93,34.73,269.8Z" />
        <path d="M39,259,23.33,265.7l-2.65-6.14c-1.37-3.17-.35-5.79,2.54-7a4.54,4.54,0,0,1,5,.56c.22-3.43,4.61-4.2,5.45-6l.1,0,1.3,3c-1.3,2.61-6.26,2.55-4.5,6.63l.85,2,6.2-2.68Zm-10.15.8-1.33-3.06a2.06,2.06,0,0,0-2.93-1.11,2.11,2.11,0,0,0-1.17,3l1.29,3Z" />
        <path d="M28.21,229.64l-16.62,3.68-1.21-5.46c-1.09-4.91,1.47-8.87,6.48-10s9.05,1.38,10.14,6.3Zm-3.52-2.59-.5-2.24c-.65-2.95-3.3-4.39-6.62-3.65s-5,3.14-4.38,6.08l.5,2.25Z" />
        <path d="M24.61,212,7.7,214,6.37,202.82l2.85-.34.95,8,3.77-.45-.86-7.24,2.85-.34.86,7.24,4.57-.54-.95-8,2.86-.34Z" />
        <path d="M22.87,192.71l-14.14.38L8.87,198,6,198.09,5.63,185l2.88-.08.13,4.92,14.14-.38Z" />
        <path d="M18.58,166.23l.51-3.35a3,3,0,0,0,3.47-2.82c.25-1.61-.52-2.7-1.79-2.9-1.89-.28-2.58,1.38-3.42,3.21-1,2.26-2.36,4.8-6.13,4.23-2.82-.41-4.24-3-3.71-6.52s2.86-5.36,5.94-5L13,156.33a2.43,2.43,0,0,0-2.91,2.21c-.23,1.5.42,2.57,1.63,2.73,1.64.23,2.35-.94,3-2.42,1.13-2.39,2.2-5.64,6.25-5,3.05.46,4.68,3.06,4.15,6.56C24.5,164.43,21.92,166.73,18.58,166.23Z" />
        <path d="M28.91,140.86l-8.58,2.92L22,146.09l5.26,1.35-.81,3.19-16.5-4.22.81-3.18L17.58,145,12.2,137.5l1-3.82,5.2,7.34,11.56-4.15Z" />
        <path d="M34.88,123.21,30,123l-2.12,5.71,3.78,3L30.49,135,16.58,123.58l1.52-4.08,18,.45Zm-9.31,3.65,1.48-4-6.84-.29Z" />
        <path d="M37.52,117,22.2,109.53l1.44-3,12.73,6.18L39.66,106l2.58,1.25Z" />
        <path d="M51.73,91.5l-13.8-10,6.51-9,2.33,1.68-4.58,6.34,3.13,2.26,4.16-5.76,2.31,1.67-4.16,5.77,6,4.35Z" />
        <path d="M53.72,74.23c-3.88-3.45-4.41-8.26-1.26-11.8s7.89-3.48,11.77,0,4.59,8.35,1.5,11.82S57.7,77.76,53.72,74.23Zm8.21-9.41c-2.66-2.37-5.69-2.56-7.45-.59s-1.28,5,1.39,7.42c2.85,2.53,6,2.82,7.83.77S64.79,67.35,61.93,64.82Z" />
        <path d="M73.62,65.31,62.08,52.8l2.42-2.23L74.09,61l5.52-5.1,2,2.11Z" />
        <path d="M92.41,49.45l-8.53-3-.16,2.84L87,53.57l-2.62,2L74.08,42l2.61-2L81,45.64l.42-9.2,3.13-2.38-.48,9L95.69,47Z" />
        <path d="M110.92,37.61l-7.56-15.24,2.94-1.46,2.93,5.89,6.07-3-2.92-5.89,2.94-1.46,7.57,15.24-3,1.46-3.36-6.77-6.08,3,3.37,6.78Z" />
        <path d="M137.65,26l-3-3.8-5.72,2.11.17,4.85-3.26,1.2-.39-18,4.08-1.5,11.37,13.94Zm-8.83-4.68,4-1.47-4.24-5.38Z" />
      </g>
    </svg>
  );
};

export default GoodSalary;
