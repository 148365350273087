const SnakkMedMKD: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <>
      <svg
        width="313"
        height="313"
        viewBox="0 0 313 313"
        className={className}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M161.613 17.2485C164.029 17.3304 165.66 16.0189 165.731 13.9163C165.826 11.1233 163.532 10.6529 161.824 10.0767C160.768 9.72669 159.917 9.33657 159.939 8.20628C159.952 7.37413 160.63 6.84727 161.665 6.88235C162.685 6.91689 163.387 7.5376 163.395 8.68464L165.623 8.76011C165.632 6.6397 164.11 5.20574 161.772 5.12655C159.34 5.04417 157.698 6.21385 157.648 8.16007C157.559 10.7648 159.39 11.4551 161.005 11.9811C162.311 12.4024 163.493 12.7409 163.449 14.0432C163.419 14.9219 162.739 15.5273 161.625 15.4895C160.338 15.4459 159.421 14.6923 159.435 13.3575L157.129 13.2793C157.05 15.5859 158.82 17.1539 161.613 17.2485Z"
          fill={color}
        />
        <path
          d="M177.837 14.8235L174.021 6.27671L171.749 5.97752L170.232 17.4961L172.458 17.7892L173.501 9.86624L177.377 18.4367L179.587 18.7277L181.103 7.20913L178.878 6.91608L177.837 14.8235Z"
          fill={color}
        />
        <path
          d="M194.293 21.971L193.106 9.74517L190.22 9.0575L183.633 19.4314L185.939 19.9808L187.675 17.1664L191.722 18.1306L191.987 21.4216L194.293 21.971ZM191.187 11.4344L191.562 16.0913L188.737 15.4182L191.187 11.4344Z"
          fill={color}
        />
        <path
          d="M207.394 26.3623L205.241 18.2655L210.531 15.1525L207.995 14.2701L202.6 17.4959L204.184 12.9438L202.064 12.2058L198.245 23.1782L200.366 23.9162L201.583 20.4169L203.255 19.436L204.74 25.4386L207.394 26.3623Z"
          fill={color}
        />
        <path
          d="M219.988 32.037L218.652 23.7662L224.225 21.1949L221.789 20.0645L216.1 22.7375L218.13 18.3657L216.093 17.4204L211.202 27.9584L213.238 28.9037L214.798 25.5429L216.559 24.7331L217.439 30.8537L219.988 32.037Z"
          fill={color}
        />
        <path
          d="M242.52 41.5531L244.896 33.7781L242.321 31.8474L235.352 41.143L237.148 42.4898L242.158 35.807L239.623 44.3451L240.665 45.1268L248.131 40.3244L243.14 46.9821L244.936 48.3288L251.906 39.0332L249.33 37.1026L242.52 41.5531Z"
          fill={color}
        />
        <path
          d="M254.371 56.8912L255.707 55.4533L251.681 51.7135L253.818 49.4129L257.464 52.8001L258.8 51.3623L255.154 47.975L256.917 46.0771L260.92 49.7956L262.255 48.3577L256.608 43.1112L248.7 51.6233L254.371 56.8912Z"
          fill={color}
        />
        <path
          d="M260.289 63.148C262.575 65.7163 265.802 65.8707 268.429 63.5326C271.044 61.205 271.254 57.9922 268.968 55.4239L266.431 52.574L257.753 60.2981L260.289 63.148ZM266.458 55.5559L267.502 56.7286C268.869 58.2649 268.629 60.286 266.917 61.81C265.181 63.3548 263.122 63.3796 261.755 61.8432L260.711 60.6705L266.458 55.5559Z"
          fill={color}
        />
        <path
          d="M281.33 85.5407L286.606 79.356L285.014 76.5592L274.918 82.3084L276.029 84.2593L283.287 80.1261L277.559 86.947L278.204 88.0794L286.966 86.6493L279.735 90.767L280.846 92.718L290.942 86.9688L289.349 84.172L281.33 85.5407Z"
          fill={color}
        />
        <path
          d="M286.971 106.452L296.07 98.2003L294.898 95.4742L282.643 96.3841L283.579 98.5621L286.873 98.274L288.516 102.096L286.034 104.274L286.971 106.452ZM293.568 97.669L290.057 100.751L288.91 98.0822L293.568 97.669Z"
          fill={color}
        />
        <path
          d="M289.628 115.236C290.14 116.885 291.33 117.699 292.564 117.859L290.869 118.385L291.335 119.884L297.093 118.096L295.853 114.107L294.144 114.638L294.727 116.513C293.25 116.84 291.854 116.156 291.393 114.671C290.862 112.962 292.153 111.246 294.642 110.472C296.981 109.746 298.913 110.461 299.443 112.22C299.805 113.439 299.342 114.7 298.274 115.23L298.949 117.404C300.98 116.427 301.939 114.009 301.221 111.7C300.266 108.626 297.275 107.254 293.887 108.307C290.423 109.383 288.682 112.192 289.628 115.236Z"
          fill={color}
        />
        <path
          d="M297.472 130.882L304.341 124.524L303.895 122.276L292.499 124.538L292.936 126.74L300.774 125.184L293.902 131.607L294.337 133.793L305.732 131.531L305.295 129.329L297.472 130.882Z"
          fill={color}
        />
        <path
          d="M295.798 146.436L297.751 146.241L297.206 140.773L300.33 140.462L300.824 145.414L302.777 145.22L302.283 140.267L304.861 140.01L305.403 145.447L307.355 145.252L306.591 137.582L295.03 138.734L295.798 146.436Z"
          fill={color}
        />
        <path
          d="M295.183 170.126C294.878 173.283 297.152 175.695 300.762 176.045C304.293 176.387 306.942 174.451 307.247 171.294C307.559 168.075 305.334 165.636 301.803 165.294C298.193 164.944 295.495 166.907 295.183 170.126ZM297.027 170.305C297.207 168.445 299.066 167.332 301.66 167.583C304.082 167.817 305.583 169.256 305.403 171.116C305.229 172.913 303.481 174.021 301.059 173.787C298.465 173.535 296.853 172.102 297.027 170.305Z"
          fill={color}
        />
        <path
          d="M296.88 186.736L304.935 185.637L305.61 182.49L294.25 180.055L293.779 182.25L301.946 184.001L293.131 185.274L292.858 186.548L300.345 191.317L292.209 189.572L291.739 191.768L303.099 194.203L303.773 191.056L296.88 186.736Z"
          fill={color}
        />
        <path
          d="M284.348 212.117C283.39 214.302 284.277 216.149 286.678 217.202L293.679 220.274L294.575 218.233L287.66 215.199C286.222 214.568 285.641 213.782 286.045 212.861C286.436 211.97 287.344 211.854 288.681 212.441L289.577 210.399C287.219 209.365 285.295 209.961 284.348 212.117Z"
          fill={color}
        />
        <path
          d="M278.188 224.421C276.641 227.189 277.755 230.313 280.92 232.082C284.017 233.814 287.22 233.104 288.768 230.336C290.346 227.513 289.288 224.386 286.191 222.654C283.026 220.884 279.767 221.598 278.188 224.421ZM279.805 225.325C280.717 223.695 282.867 223.422 285.141 224.693C287.265 225.881 288.062 227.801 287.151 229.432C286.269 231.008 284.224 231.321 282.1 230.134C279.825 228.862 278.924 226.901 279.805 225.325Z"
          fill={color}
        />
        <path
          d="M274.702 242.139C274.514 243.119 274.945 244.035 275.948 244.738C277.375 245.739 278.941 245.283 280.184 243.509L282.78 239.806L273.266 233.138L270.509 237.071C269.103 239.077 269.273 240.941 270.969 242.13C272.268 243.041 273.597 243.032 274.702 242.139ZM278.73 242.164C278.172 242.961 277.483 243.15 276.841 242.699C276.121 242.194 276.003 241.46 276.544 240.688L277.724 239.004L279.884 240.518L278.73 242.164ZM273.584 236.102L276.349 238.04L275.105 239.814C274.393 240.83 273.446 241.086 272.572 240.474C271.698 239.861 271.593 238.944 272.269 237.979L273.584 236.102Z"
          fill={color}
        />
        <path
          d="M266.006 253.005C265.726 253.963 266.069 254.916 267 255.71C268.326 256.841 269.928 256.536 271.334 254.888L274.269 251.448L265.43 243.908L262.312 247.562C260.722 249.426 260.715 251.297 262.291 252.642C263.498 253.671 264.821 253.789 266.006 253.005ZM270.014 253.411C269.382 254.152 268.679 254.274 268.082 253.765C267.413 253.194 267.366 252.452 267.977 251.735L269.312 250.171L271.318 251.883L270.014 253.411ZM265.466 246.889L268.034 249.08L266.628 250.729C265.823 251.672 264.856 251.838 264.044 251.145C263.231 250.452 263.214 249.529 263.978 248.633L265.466 246.889Z"
          fill={color}
        />
        <path
          d="M243.067 265.698C241.176 267.206 240.788 269.262 242.1 270.907C243.842 273.092 245.891 271.96 247.565 271.288C248.595 270.868 249.495 270.612 250.213 271.486C250.744 272.127 250.571 272.968 249.76 273.614C248.963 274.25 248.026 274.234 247.275 273.367L245.532 274.757C246.902 276.375 248.991 276.476 250.82 275.018C252.723 273.501 253.211 271.545 251.985 270.032C250.36 267.995 248.52 268.659 246.95 269.309C245.684 269.836 244.565 270.347 243.753 269.328C243.204 268.641 243.328 267.739 244.2 267.044C245.207 266.241 246.393 266.218 247.25 267.242L249.054 265.803C247.615 263.999 245.252 263.956 243.067 265.698Z"
          fill={color}
        />
        <path
          d="M231.919 273.678C229.254 275.398 228.775 278.679 230.741 281.726C232.666 284.707 235.827 285.582 238.492 283.862C241.209 282.108 241.741 278.85 239.817 275.869C237.85 272.821 234.636 271.925 231.919 273.678ZM232.924 275.235C234.493 274.222 236.52 274.988 237.933 277.178C239.253 279.222 239.057 281.292 237.487 282.305C235.97 283.284 234.016 282.602 232.697 280.558C231.283 278.368 231.407 276.214 232.924 275.235Z"
          fill={color}
        />
        <path
          d="M219.922 285.327L225.782 290.962L228.669 289.539L223.53 279.119L221.517 280.112L225.211 287.603L218.743 281.48L217.574 282.056L218.481 290.887L214.8 283.424L212.787 284.417L217.925 294.837L220.812 293.413L219.922 285.327Z"
          fill={color}
        />
        <path
          d="M190.486 291.931L192.905 301.278L189.653 302.12L190.144 304.02L198.823 301.774L198.332 299.874L195.079 300.716L192.66 291.368L190.486 291.931Z"
          fill={color}
        />
        <path
          d="M175.478 294.874L175.797 296.81L181.219 295.917L181.73 299.015L176.819 299.824L177.138 301.761L182.049 300.951L182.47 303.507L177.08 304.396L177.399 306.332L185.005 305.078L183.115 293.615L175.478 294.874Z"
          fill={color}
        />
        <path
          d="M160.999 296.116L166.16 302.716L162.52 307.657L165.198 307.472L168.892 302.386L169.224 307.194L171.463 307.039L170.663 295.449L168.423 295.604L168.678 299.3L167.527 300.859L163.803 295.923L160.999 296.116Z"
          fill={color}
        />
        <path
          d="M149.592 299.591L154.245 307.712L156.536 307.782L156.891 296.17L154.647 296.101L154.403 304.089L149.688 295.95L147.459 295.881L147.105 307.494L149.349 307.563L149.592 299.591Z"
          fill={color}
        />
        <path
          d="M137.463 294.55C134.321 294.121 131.822 296.3 131.332 299.894C130.853 303.409 132.684 306.131 135.827 306.559C139.031 306.996 141.555 304.868 142.034 301.352C142.524 297.759 140.668 294.986 137.463 294.55ZM137.213 296.385C139.064 296.637 140.105 298.538 139.753 301.12C139.424 303.532 137.928 304.976 136.077 304.723C134.288 304.48 133.248 302.69 133.577 300.279C133.929 297.696 135.424 296.141 137.213 296.385Z"
          fill={color}
        />
        <path
          d="M120.366 291.432L119.915 293.342L124.911 294.524L122.688 303.92L124.872 304.437L127.547 293.131L120.366 291.432Z"
          fill={color}
        />
        <path
          d="M111.321 288.351C108.32 287.324 105.447 288.979 104.273 292.411C103.124 295.768 104.396 298.792 107.397 299.819C110.457 300.866 113.344 299.265 114.493 295.908C115.667 292.477 114.381 289.398 111.321 288.351ZM110.721 290.104C112.489 290.709 113.143 292.774 112.299 295.24C111.511 297.543 109.764 298.671 107.997 298.066C106.288 297.481 105.614 295.525 106.402 293.222C107.245 290.756 109.013 289.519 110.721 290.104Z"
          fill={color}
        />
        <path
          d="M97.4364 282.743C95.872 282.012 94.469 282.344 93.5208 283.148L94.2723 281.541L92.8501 280.876L90.2965 286.337L94.0795 288.106L94.8376 286.485L93.0599 285.654C93.8121 284.342 95.258 283.77 96.6659 284.428C98.2873 285.186 98.6869 287.297 97.583 289.658C96.5456 291.876 94.7162 292.824 93.0589 292.031C91.9135 291.478 91.2922 290.287 91.6203 289.14L89.5581 288.176C88.9125 290.335 90.0555 292.672 92.2457 293.696C95.1613 295.059 98.1897 293.772 99.6926 290.558C101.229 287.272 100.324 284.093 97.4364 282.743Z"
          fill={color}
        />
        <path
          d="M75.0521 269.887L68.3419 279.371L70.1747 280.668L76.8849 271.184L75.0521 269.887Z"
          fill={color}
        />
        <path
          d="M51.381 257.762C50.4493 257.405 49.4717 257.669 48.6039 258.533C47.3688 259.762 47.5427 261.384 49.0713 262.919L52.2613 266.124L60.4951 257.927L57.1057 254.522C55.3778 252.786 53.5131 252.627 52.0443 254.089C50.9205 255.208 50.6955 256.517 51.381 257.762ZM50.6504 261.724C49.9637 261.034 49.8987 260.323 50.455 259.769C51.0781 259.149 51.8219 259.162 52.4865 259.829L53.9375 261.287L52.0682 263.148L50.6504 261.724ZM57.5203 257.72L55.128 260.102L53.5995 258.566C52.7245 257.687 52.6379 256.71 53.3945 255.957C54.1511 255.204 55.0724 255.261 55.9031 256.096L57.5203 257.72Z"
          fill={color}
        />
        <path
          d="M46.5697 242.634L45.0609 243.889L48.3437 247.836L40.9201 254.01L42.3557 255.736L51.2881 248.307L46.5697 242.634Z"
          fill={color}
        />
        <path
          d="M38.1715 230.797L30.8418 240.653L32.5191 243.101L44.3659 239.837L43.0259 237.881L39.8499 238.801L37.4982 235.369L39.5116 232.752L38.1715 230.797ZM33.3985 240.691L36.2472 236.988L37.889 239.384L33.3985 240.691Z"
          fill={color}
        />
        <path
          d="M29.4144 222.682L24.7497 230.797L25.8452 232.81L36.0504 227.258L34.9774 225.286L27.9579 229.105L32.6064 220.928L31.5409 218.969L21.3356 224.522L22.4086 226.494L29.4144 222.682Z"
          fill={color}
        />
        <path
          d="M25.6518 205.528L21.0652 212.539L15.2152 210.683L16.2525 213.159L22.2571 215.018L17.8115 216.88L18.679 218.951L29.3947 214.462L28.5272 212.391L25.1098 213.823L23.2633 213.235L26.7377 208.12L25.6518 205.528Z"
          fill={color}
        />
        <path
          d="M19.3426 201.254L11.9354 203.558L12.6406 205.673L19.9435 203.056L19.3426 201.254ZM23.7065 199.65L21.2192 200.479L21.9046 202.535L24.3918 201.705L23.7065 199.65Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default SnakkMedMKD;
