const Chaggaccino: React.FC<{ color?: string; className?: string }> = ({
  color = 'currentColor',
  className = '',
}) => {
  return (
    <svg
      width="384"
      height="384"
      viewBox="0 0 384 384"
      fill={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M243.964 30.7L248.455 17.29L243.794 15.72L244.694 13L257.156 17.17L256.246 19.87L251.575 18.3L247.084 31.71L243.964 30.7Z" />
      <path d="M265.218 39.1501L265.738 34.3401L260.177 31.8701L256.907 35.4501L253.746 34.0301L266.058 20.8701L270.059 22.6401L268.388 40.5701L265.218 39.1501ZM262.217 29.6201L266.098 31.3601L266.828 24.5601L262.217 29.6201Z" />
      <path d="M283.921 49.6299L293.662 35.6299L302.924 42.0899L301.284 44.4399L294.703 39.8699L292.532 42.9799L298.533 47.1499L296.893 49.5099L290.892 45.3399L288.252 49.1099L294.863 53.7199L293.212 56.0799L283.921 49.6299Z" />
      <path d="M315.705 52.62L318.205 54.77L307.104 67.68L304.623 65.55L306.704 51.93L299.072 60.81L296.582 58.67L307.704 45.76L310.254 47.95L308.084 61.49L315.705 52.62Z" />
      <path d="M326.838 75.8701C330.928 72.6601 335.739 72.9801 338.649 76.6901C339.748 77.9971 340.316 79.6692 340.24 81.375C340.164 83.0808 339.45 84.6958 338.239 85.9001L336.179 83.2801C336.845 82.6457 337.247 81.7835 337.304 80.8658C337.362 79.9481 337.071 79.0425 336.489 78.3301C334.849 76.2001 331.828 76.2301 328.998 78.4401C325.997 80.8001 325.127 83.8201 326.738 85.8901C327.349 86.656 328.226 87.1644 329.195 87.3144C330.164 87.4644 331.154 87.2452 331.968 86.7001L334.029 89.3201C332.547 90.2584 330.767 90.6065 329.041 90.2954C327.315 89.9843 325.768 89.0367 324.707 87.6401C321.777 83.9301 322.707 79.1201 326.838 75.8701Z" />
      <path d="M331.618 96.28L346.06 87.28L347.801 90.07L342.22 93.56L345.82 99.32L351.401 95.83L353.142 98.62L338.699 107.62L336.959 104.87L343.38 100.87L339.779 95.11L333.358 99.11L331.618 96.28Z" />
      <path d="M345.84 121.74L349.32 118.38L346.65 112.87L341.849 113.52L340.329 110.4L358.202 108.22L360.102 112.13L347.36 124.87L345.84 121.74ZM349.621 112.48L351.481 116.3L356.412 111.55L349.621 112.48Z" />
      <path d="M356.522 124.54C361.423 122.79 365.884 124.61 367.474 129.04C368.083 130.581 368.114 132.289 367.563 133.851C367.012 135.413 365.914 136.723 364.474 137.54L363.353 134.4C364.098 133.955 364.659 133.261 364.938 132.44C365.216 131.619 365.194 130.726 364.874 129.92C363.983 127.37 361.113 126.45 357.732 127.66C354.142 128.95 352.362 131.54 353.242 134.01C353.586 134.995 354.301 135.807 355.236 136.272C356.17 136.738 357.249 136.819 358.243 136.5L357.242 133.79L359.713 132.91L361.773 138.67L353.462 141.67L352.682 139.5L355.132 138.62C354.108 138.526 353.135 138.129 352.339 137.478C351.542 136.828 350.958 135.955 350.661 134.97C349.151 130.55 351.522 126.33 356.522 124.54Z" />
      <path d="M357.342 156.71L361.462 154.18L360.042 148.25L355.211 147.85L354.401 144.47L372.324 146.2L373.324 150.43L358.132 160.09L357.342 156.71ZM363.023 148.48L364.023 152.62L369.864 149.04L363.023 148.48Z" />
      <path d="M367.154 161.74C372.315 161.08 376.285 163.81 376.886 168.49C377.16 170.175 376.791 171.902 375.851 173.328C374.912 174.755 373.472 175.776 371.815 176.19L371.395 172.88C372.289 172.674 373.072 172.138 373.589 171.38C374.107 170.623 374.319 169.698 374.185 168.79C373.865 166.12 371.255 164.6 367.694 165.05C363.913 165.54 361.613 167.69 361.943 170.29C362.07 171.262 362.561 172.15 363.316 172.775C364.071 173.4 365.035 173.716 366.014 173.66L366.444 176.97C364.689 177.024 362.977 176.417 361.649 175.268C360.321 174.12 359.473 172.514 359.273 170.77C358.713 166.09 361.883 162.41 367.154 161.74Z" />
      <path d="M369.384 181.1C374.585 181.01 378.225 184.17 378.305 188.88C378.393 190.584 377.838 192.259 376.748 193.572C375.659 194.886 374.116 195.741 372.424 195.97L372.364 192.64C373.275 192.533 374.113 192.086 374.71 191.389C375.306 190.693 375.619 189.797 375.585 188.88C375.585 186.19 373.144 184.39 369.584 184.45C365.773 184.52 363.243 186.4 363.293 189.02C363.312 190 363.701 190.936 364.382 191.641C365.062 192.346 365.985 192.767 366.964 192.82L367.024 196.16C365.265 196.019 363.625 195.22 362.431 193.922C361.237 192.624 360.577 190.923 360.583 189.16C360.473 184.49 364.073 181.19 369.384 181.1Z" />
      <path d="M360.712 200.37L377.715 201.43L377.505 204.71L360.502 203.65L360.712 200.37Z" />
      <path d="M375.624 221.1L375.164 224.35L358.302 221.97L358.762 218.74L371.374 213.18L359.782 211.54L360.242 208.28L377.105 210.66L376.635 213.99L364.053 219.46L375.624 221.1Z" />
      <path d="M365.913 228.03C370.914 229.32 373.625 233.35 372.444 237.94C371.264 242.53 367.023 244.64 361.983 243.35C356.942 242.06 354.091 238.03 355.252 233.53C356.412 229.03 360.773 226.71 365.913 228.03ZM362.913 240.15C366.373 241.04 369.154 239.83 369.804 237.26C370.454 234.69 368.634 232.19 365.183 231.31C361.483 230.36 358.542 231.55 357.862 234.2C357.182 236.85 359.232 239.2 362.923 240.15H362.913Z" />
      <path d="M356.872 277.87L354.792 282.1L339.52 274.58L340.97 271.58L351.911 276.96L342.98 267.51L343.82 265.8L356.822 267.14L345.821 261.73L347.271 258.73L362.543 266.25L360.463 270.48L348.621 269.17L356.872 277.87Z" />
      <path d="M337.159 278.35L351.491 287.52L345.41 297.04L342.98 295.48L347.301 288.74L344.1 286.74L340.17 292.87L337.709 291.29L341.64 285.15L337.769 282.67L333.418 289.45L331.038 287.87L337.159 278.35Z" />
      <path d="M328.597 291.4L341.939 301.97L338.469 306.35C335.338 310.29 330.647 310.81 326.627 307.62C322.606 304.43 321.996 299.73 325.127 295.78L328.597 291.4ZM328.807 295.76L327.377 297.57C325.507 299.93 326.067 302.89 328.737 305.01C331.408 307.13 334.348 306.92 336.218 304.56L337.638 302.76L328.807 295.76Z" />
      <path d="M300.433 327.7C298.433 325.4 298.873 322.7 301.594 320.35L306.924 315.75L318.046 328.64L313.045 332.97C310.645 335.04 308.255 335.13 306.584 333.19C306.041 332.666 305.671 331.989 305.523 331.25C305.374 330.51 305.455 329.743 305.754 329.05C304.85 329.486 303.821 329.59 302.848 329.343C301.875 329.096 301.02 328.514 300.433 327.7ZM303.774 322.27C302.464 323.39 302.284 324.74 303.304 325.92C304.324 327.1 305.764 327.08 307.144 325.92L309.545 323.85L306.314 320.1L303.774 322.27ZM313.675 328.61L311.155 325.68L308.875 327.68C308.63 327.836 308.424 328.046 308.272 328.293C308.12 328.54 308.026 328.819 307.997 329.107C307.968 329.396 308.005 329.688 308.105 329.96C308.205 330.232 308.366 330.479 308.575 330.68C309.325 331.55 310.365 331.53 311.445 330.6L313.675 328.61Z" />
      <path d="M294.102 326.47L303.994 340.32L301.313 342.23L293.092 330.72L286.981 335.09L285.311 332.75L294.102 326.47Z" />
      <path d="M272.429 340.45L276.029 343.68L281.3 340.63L280.3 335.88L283.301 334.14L286.751 351.81L282.981 353.99L269.398 342.18L272.429 340.45ZM281.94 343.55L278.25 345.69L283.341 350.26L281.94 343.55Z" />
      <path d="M263.747 363.45L260.747 364.78L253.846 349.22L256.846 347.89L268.238 355.65L263.497 344.95L266.498 343.61L273.409 359.17L270.338 360.54L259.037 352.76L263.747 363.45Z" />
      <path d="M240.034 353.87L247.035 359.57L248.125 356.95L246.455 351.78L249.585 350.78L254.816 366.97L251.685 367.97L249.515 361.25L246.105 369.87L242.364 371.08L245.795 362.77L236.123 355.22L240.034 353.87Z" />
      <path d="M142.308 353.58L138.068 367.07L142.758 368.55L141.898 371.29L129.366 367.35L130.236 364.61L134.927 366.08L139.178 352.59L142.308 353.58Z" />
      <path d="M120.905 345.53L120.475 350.34L126.086 352.73L129.286 349.09L132.477 350.45L120.415 363.87L116.414 362.16L117.674 344.16L120.905 345.53ZM124.125 355L120.205 353.33L119.605 360.15L124.125 355Z" />
      <path d="M102.022 335.42L92.5604 349.56L83.1689 343.29L84.7692 340.87L91.4202 345.32L93.5306 342.16L87.4696 338.11L89.0699 335.72L95.1308 339.77L97.6712 335.98L90.9802 331.5L92.5704 329.11L102.022 335.42Z" />
      <path d="M70.1674 333.01L67.667 330.87L78.4687 317.79L80.9891 319.87L79.1388 333.53L86.5899 324.53L89.1303 326.63L78.2887 339.72L75.6682 337.58L77.5785 324L70.1674 333.01Z" />
      <path d="M58.6651 310.03C54.6644 313.31 49.8137 313.09 46.8332 309.44C45.7079 308.154 45.107 306.493 45.1496 304.785C45.1922 303.077 45.8752 301.448 47.0633 300.22L49.1736 302.81C48.5243 303.458 48.1424 304.327 48.1035 305.244C48.0646 306.161 48.3716 307.059 48.9636 307.76C50.6538 309.86 53.6643 309.76 56.4447 307.5C59.4452 305.09 60.2253 302.04 58.5651 300.01C57.9418 299.253 57.0556 298.759 56.0839 298.628C55.1121 298.496 54.1265 298.736 53.3242 299.3L51.2139 296.72C52.6754 295.744 54.4505 295.354 56.1866 295.626C57.9227 295.898 59.4929 296.814 60.5854 298.19C63.5158 301.87 62.7257 306.67 58.6651 310.03Z" />
      <path d="M53.4445 289.67L39.1822 298.95L37.382 296.2L42.8928 292.61L39.1922 286.93L33.6614 290.51L31.8711 287.76L46.1333 278.47L47.9236 281.23L41.5826 285.36L45.2832 291.04L51.6642 286.87L53.4445 289.67Z" />
      <path d="M38.7424 264.49L35.3218 267.87L38.0923 273.3L42.883 272.56L44.4632 275.65L26.6605 278.21L24.6602 274.33L37.1621 261.39L38.7424 264.49ZM35.1318 273.87L33.2015 270.08L28.3607 274.87L35.1318 273.87Z" />
      <path d="M28.0103 261.87C23.1495 263.72 18.6488 261.99 17.0085 257.58C16.371 256.056 16.3031 254.354 16.8173 252.784C17.3315 251.214 18.3934 249.882 19.809 249.03L20.9992 252.15C20.2622 252.608 19.7125 253.313 19.4486 254.14C19.1846 254.966 19.2238 255.86 19.5589 256.66C20.4891 259.18 23.3795 260.05 26.7401 258.78C30.3106 257.42 32.0409 254.78 31.1108 252.34C30.7472 251.373 30.0235 250.584 29.0914 250.139C28.1593 249.693 27.0909 249.626 26.11 249.95L27.1101 252.64L24.6597 253.57L22.4294 247.87L30.6607 244.73L31.4808 246.88L29.0504 247.81C30.0881 247.87 31.0835 248.242 31.9062 248.877C32.7289 249.513 33.3406 250.381 33.6612 251.37C35.2714 255.74 32.981 260.01 28.0103 261.87Z" />
      <path d="M26.5905 229.73L22.5099 232.34L24.0401 238.23L28.8808 238.55L29.751 241.91L11.8082 240.52L10.708 236.31L25.7104 226.37L26.5905 229.73ZM21.0496 238.06L19.9795 233.95L14.2186 237.63L21.0496 238.06Z" />
      <path d="M16.6584 224.87C11.5176 225.63 7.49701 222.97 6.8069 218.3C6.50198 216.621 6.83843 214.889 7.74967 213.447C8.66091 212.004 10.0806 210.956 11.7277 210.51L12.2077 213.81C11.3185 214.034 10.546 214.584 10.0438 215.351C9.54162 216.118 9.34653 217.046 9.49732 217.95C9.86738 220.62 12.4978 222.09 16.0483 221.56C19.8289 221.01 22.0493 218.81 21.6992 216.22C21.5532 215.251 21.0465 214.372 20.2807 213.761C19.5149 213.149 18.5463 212.849 17.5686 212.92L17.0785 209.63C18.8325 209.542 20.5557 210.116 21.9062 211.238C23.2568 212.361 24.136 213.95 24.3696 215.69C25.0798 220.39 21.9293 224.13 16.6584 224.87Z" />
      <path d="M14.0783 205.58C8.88751 205.77 5.18693 202.68 5.0169 197.97C4.89665 196.267 5.42133 194.581 6.48674 193.248C7.55214 191.914 9.08033 191.029 10.7678 190.77L10.8878 194.1C9.97776 194.224 9.14764 194.686 8.56219 195.393C7.97673 196.101 7.67874 197.003 7.72732 197.92C7.80734 200.6 10.2577 202.36 13.8483 202.23C17.6589 202.09 20.1393 200.16 20.0492 197.54C20.012 196.561 19.6056 195.632 18.9118 194.94C18.2179 194.248 17.288 193.845 16.3087 193.81L16.1886 190.48C17.9409 190.587 19.5889 191.349 20.806 192.614C22.023 193.879 22.7203 195.555 22.7597 197.31C22.9297 202.02 19.3891 205.39 14.0783 205.58Z" />
      <path d="M22.3796 186.13L5.37695 185.39L5.51698 182.11L22.5196 182.84L22.3796 186.13Z" />
      <path d="M7.06704 165.74L7.4671 162.47L24.3597 164.53L23.9697 167.77L11.4677 173.57L23.0895 174.98L22.6595 178.25L5.75684 176.25L6.20691 172.87L18.6589 167.15L7.06704 165.74Z" />
      <path d="M16.6589 158.58C11.6581 157.38 8.85768 153.4 9.94785 148.79C11.038 144.18 15.2487 141.98 20.3095 143.18C25.3703 144.38 28.3107 148.36 27.2305 152.88C26.1504 157.4 21.8197 159.8 16.6589 158.58ZM19.4293 146.4C15.9588 145.58 13.1984 146.84 12.5883 149.4C11.9782 151.96 13.8585 154.45 17.329 155.27C21.0396 156.15 23.96 154.9 24.5901 152.27C25.2202 149.64 23.1299 147.28 19.4193 146.4H19.4293Z" />
      <path d="M24.7697 108.65L26.77 104.38L42.1724 111.61L40.7722 114.61L29.7705 109.43L38.8819 118.71L38.0718 120.43L25.0698 119.34L36.1415 124.54L34.7513 127.54L19.3389 120.31L21.3392 116.05L33.191 117.14L24.7697 108.65Z" />
      <path d="M44.4632 107.74L29.9609 98.87L35.8719 89.25L38.3222 90.76L34.1316 97.58L37.3721 99.58L41.1827 93.37L43.6631 94.81L39.8525 101.03L43.7631 103.43L47.9838 96.58L50.4341 98.08L44.4632 107.74Z" />
      <path d="M52.7748 94.52L39.2227 84.21L42.6632 79.77C45.6637 75.77 50.3944 75.15 54.475 78.26C58.5557 81.37 59.2658 86.06 56.2153 90.07L52.7748 94.52ZM52.4847 90.16L53.8749 88.33C55.6952 85.93 55.0851 82.98 52.3747 80.92C49.6643 78.86 46.7238 79.11 44.9035 81.51L43.5033 83.34L52.4847 90.16Z" />
      <path d="M80.2488 57.69C82.2491 59.95 81.899 62.69 79.2486 65.06L74.0078 69.76L62.666 57.08L67.5968 52.66C69.9672 50.54 72.3475 50.41 74.0578 52.31C74.6098 52.8254 74.9923 53.4962 75.1548 54.2336C75.3174 54.971 75.2522 55.7404 74.9679 56.44C75.8559 56.0003 76.8696 55.8846 77.8338 56.1128C78.7981 56.341 79.6523 56.8989 80.2488 57.69V57.69ZM66.9967 57.03L69.5771 59.87L71.8174 57.87C72.0571 57.7085 72.2574 57.4953 72.4035 57.2461C72.5497 56.9968 72.6381 56.7179 72.6621 56.43C72.6861 56.1421 72.6451 55.8524 72.5422 55.5824C72.4393 55.3124 72.2771 55.069 72.0675 54.87C71.2974 54.01 70.2472 54.05 69.187 55L66.9967 57.03ZM76.9983 63.18C78.2785 62.03 78.4385 60.69 77.3983 59.52C76.3582 58.35 74.9179 58.41 73.5577 59.62L71.1973 61.74L74.4979 65.43L76.9983 63.18Z" />
      <path d="M86.5901 58.8699L76.4385 45.1499L79.0789 43.1499L87.5102 54.5099L93.5111 50.0199L95.2714 52.3699L86.5901 58.8699Z" />
      <path d="M108.003 44.4301L104.342 41.2701L99.1213 44.4101L100.201 49.1401L97.201 50.9301L93.4404 33.3301L97.161 31.0801L110.973 42.6401L108.003 44.4301ZM98.4412 41.5001L102.082 39.3001L96.911 34.8701L98.4412 41.5001Z" />
      <path d="M116.234 21.2799L119.235 19.8899L126.436 35.3099L123.435 36.6899L111.894 29.1499L116.894 39.7599L113.894 41.1499L106.673 25.7299L109.673 24.3099L121.175 31.8699L116.234 21.2799Z" />
      <path d="M140.148 30.36L133.007 24.79L132.007 27.44L133.777 32.57L130.677 33.64L125.136 17.54L128.246 16.47L130.547 23.15L133.837 14.55L137.558 13.27L134.287 21.65L144.099 29.02L140.148 30.36Z" />
    </svg>
  );
};

export default Chaggaccino;
