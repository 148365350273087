import { FC } from 'react';
import Darkside from '../components/Darkside/Darkside';
import { useDarkSideContext } from '../Context/DarksideContext';
import { DarkSide } from '../content/darkside.val';

const DarksideContainer: FC<{
  data: DarkSide;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ children, data }) => {
  const { darksideMode } = useDarkSideContext();
  return (
    <>
      {darksideMode && <Darkside {...data} />}
      {children}
    </>
  );
};

export default DarksideContainer;
