export const inColumns = (
  arr: JSX.Element[],
  count: number
): JSX.Element[][] => {
  return [
    ...arr.reduce(
      (r, v, i) => ((r[i % count] = r[i % count] || []).push(v), r),
      []
    ),
  ];
};
